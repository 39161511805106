const ProgressBarNegative = (props) => {
  const { bgcolor, rawCount, cutoff = 50 } = props;
  const count = Math.abs(rawCount);
  const negative = rawCount < 0;

  function bytesToSize(bytes) {
    var sizes = ["", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes < 1000) {
        return bytes + sizes[i];
      }
      else if (i === 0 && bytes < 10000)
        return bytes;
      else {
        bytes = parseFloat(bytes / 1000).toFixed(2);
      }
    }
    return 0;
  }

  const containerStyles = {
    position: 'relative',
    height: "23px",
    width: "100%",
  };

  const fillerStyles = {
    height: "100%",
    width: `${count}%`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: bgcolor
  };

  const absoluteStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  }

  const labelStyles = {
    padding: '0 6px',
    fontWeight: '500',
    fontSize: '0.75rem',
    transition: "opacity 1s ease-in-out",
    color: (count >= cutoff) ? "white" : bgcolor,
  };

  return (
    <div style={containerStyles} className="bg-gray-100">
      <div style={absoluteStyles} className="flex flex-row justify-start overflow-hidden">
        <div class="w-full flex flex-row-reverse">
          {rawCount <= 0 &&
            <>
              <div style={fillerStyles}>
                {(count) >= cutoff &&
                  <div className="flex justify-start items-center h-full">
                    <div style={labelStyles} className="text-white">{`${bytesToSize(rawCount).toLocaleString('vi-VN') || 0}%`}</div>
                  </div>
                }
              </div>
              {((count) < cutoff) &&
                <div className="flex justify-end items-center h-full">
                  <div style={labelStyles}>{`${bytesToSize(rawCount).toLocaleString('vi-VN') || 0}%`}</div>
                </div>
              }
            </>
          }
        </div>
        <div class="w-1.5 h-full bg-black"></div>
        <div class="w-full flex flex-row">
          {rawCount > 0 &&
            <>
              <div style={fillerStyles}>
                {(count) >= cutoff &&
                  <div className="flex justify-end items-center h-full">
                    <div style={labelStyles} className="text-white">{`${bytesToSize(rawCount).toLocaleString('vi-VN') || 0}%`}</div>
                  </div>
                }
              </div>
              {((count) < cutoff) &&
                <div className="flex justify-end items-center h-full">
                  <div style={labelStyles}>{`${bytesToSize(rawCount).toLocaleString('vi-VN') || 0}%`}</div>
                </div>
              }
            </>
          }
        </div>
      </div>

    </div>
  );
};

export default ProgressBarNegative;
