import { atom } from "recoil";

const listProvincesState = atom({
  key: "listProvincesState",
  default: []
});

const selectedProvinceState = atom({
  key: "selectedProvinceState",
  default: []
});

export { listProvincesState, selectedProvinceState };