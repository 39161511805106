/* This example requires Tailwind CSS v2.0+ */
import { React, Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SmallSelect({
  options = [],
  title = "",
  value,
  active = false,
  disabled = false,
  onChange = () => {},
}) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    const sltd = options.find((i) => i.value === value);
    if (!!sltd) {
      setSelected(sltd);
    } else {
      setSelected(options[0]);
    }
  }, [value]);

  useEffect(() => {}, [selected]);

  const handleChange = (newSelected) => {
    onChange(newSelected.value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          {title && (
            <Listbox.Label className="text-sm font-medium text-gray-700">
              {title}
            </Listbox.Label>
          )}
          <div className="flex h-full items-cente relative">
            <Listbox.Button className={"w-full h-full px-2 py-1 text-left rounded "
            + (active ? "bg-gray-500 text-white" : "bg-gray-200")}>
              <span className="flex flex-row w-full">
                <span className="text-xs font-medium w-full">
                  {selected?.label}
                </span>
                <span className="ml-2 flex text-xs items-center pointer-events-none justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-2 w-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </span>
            </Listbox.Button>

            <Transition
              show={!disabled && open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-50 top-full mt-1 bg-white shadow-lg max-h-56 rounded-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
