import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import countrySelected from '../../recoil/atom-country';
import { quarantineProvinceState } from '../../recoil/atom-quarantine';
import SmallSelect from '../commons/SelectInputSmall';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const ChartGeneralMobility = ({popup = false}) => {
  const selectedProvince = useRecoilValue(countrySelected);
  const provinceQuarantine = useRecoilValue(quarantineProvinceState({ province: selectedProvince.id }))
  const [selectedDate, setSelectedDate] = useState(60);

  const objectKeys = {
    park: 'parks_percent_change_from_baseline',
    grocery: 'grocery_and_pharmacy_percent_change_from_baseline',
    residential: 'residential_percent_change_from_baseline',
    retail: 'retail_and_recreation_percent_change_from_baseline',
    transit: 'transit_stations_percent_change_from_baseline',
    workplaces: 'workplaces_percent_change_from_baseline',
  }

  const dateOptions = [
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const chartOptions = {
    aspectRatio: popup ? 2.5 : 1,
    scales: {
      y: {
        ticks: {
          stepSize: 20,
        },
        min: -100,
        max: 100,
        grid: {
          display: true,
          drawBorder: false,
        }
      },
      x: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        }
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return context.dataset.label + ": "+ context.parsed.y + "%";
          }
        }
      },
      legend: {
        display: false,
      },
      datalabels: {
        align: 'left',
        anchor: 'end',
        padding: 2,
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
        textShadowColor: 'rgba(255,255,255,1)',
        textShadowBlur: 4,
        display: function (context) {    
          let index = context.dataIndex;
          let dSet = context.dataset.data;
          return (index === dSet.length - 1);
        },
        formatter: function (value) {
          return (value >= 0 ? "+" : "") + value.toLocaleString("vi-VN") + "%" + '\n';
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
  };

  const getLabelArray = (dataArray) => {
    let result = dataArray.map((date) => {
      let split = date.split("-");
      return parseInt(split[2]).toString() + "/" + parseInt(split[1]).toString();
    })
    return result;
  }

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, "#FEE2E2"); //#ff6a07
    gradient.addColorStop(1, "#F87171"); //#fcf2e9

    const park = Object.values(provinceQuarantine[objectKeys.park]);
    const grocery = Object.values(provinceQuarantine[objectKeys.grocery]);
    const retail = Object.values(provinceQuarantine[objectKeys.retail]);
    const transit = Object.values(provinceQuarantine[objectKeys.transit]);
    const workplaces = Object.values(provinceQuarantine[objectKeys.workplaces]);

    const dataset = park.map((item, index) => {
      let sum = parseInt(item)
        + parseInt(grocery[index])
        + parseInt(retail[index])
        + parseInt(transit[index])
        + parseInt(workplaces[index]);

      return Math.round(sum / 5);
    })

    const slicedDataset = selectedDate === 0 ? dataset :
      dataset.slice(dataset.length - selectedDate, dataset.length);

    const labels = selectedDate === 0 ? Object.keys(provinceQuarantine[objectKeys.park]) :
      Object.keys(provinceQuarantine[objectKeys.park]).slice(dataset.length - selectedDate, dataset.length);

    const newLabels = getLabelArray(labels);

    return {
      labels: newLabels || [],
      datasets: [
        {
          label: "Điểm số",
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: "#EF4444",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          fill: "origin",
          backgroundColor: gradient,
          pointStrokeColor: "#EF4444",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#EF4444",
          data: slicedDataset,
        }
      ]
    }
  }

  return (
    <div className="flex flex-col justify-center h-full">
      
      {provinceQuarantine ?
        <>
          <div className="mb-3 flex flex-row">
            <div className="pr-1">
              <SmallSelect
                title=""
                options={dateOptions}
                value={selectedDate}
                onChange={(value) => {
                  setSelectedDate(value);
                }}
              />
            </div>
          </div>
          <Line width={popup ? 320 : null} height={popup ? 150 : null} data={chartData} plugins={[ChartDataLabels]} options={chartOptions} />
        </>
        :
        <></>
      }

    </div>
  );
};

export default ChartGeneralMobility;