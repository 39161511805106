import React, { useState, useEffect } from "react";
import api from "../../services/api";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import countrySelected from "../../recoil/atom-country";
import { useRecoilState } from "recoil";
import ratioData from "./../../recoil/atom-ratio";

const Vaccine = ({ selectedProvince }) => {
  const history = useHistory();
  const [currentCountry] = useRecoilState(countrySelected);
  const [dataRatio, setRatio] = useRecoilState(ratioData);
  const [vaccine, setvaccine] = useState([]);
  // const [totalVaccine ] =useRecoilState(overViewTotalVaccine);
  const [totalVaccine, setTotalVaccine] = useState([])
  const [data, setData] = useState([]);
  useEffect(() => {
    api.get("/vaccine/national_vaccine").then((success)=>{
      setTotalVaccine(success.data)
    
    })
    api.get("vaccine/provinces").then((response) => {
      const data = response.data;
      setvaccine(data);
      setRatio(response.data);
    });
  }, []);

  // total_first_injected
  // total_secord_injected
  // (parseInt(totalVaccine.total_injected) /
  // parseInt(totalVaccine?.national_population)) *
  // console.log(totalVaccine?.total_injected.toLocaleString())

  useEffect(() => {
    let dataVaccine;
    if (!!currentCountry.id) {
      const currentDataVaccine = dataRatio.find(
        (item) => item.id === currentCountry.id
      );
      if (!!currentDataVaccine) {
        dataVaccine = { ...currentDataVaccine };
      }
    } else {
      dataVaccine = {
        totalInjected:
          _.sumBy(vaccine, "totalOnceInjected") +
            _.sumBy(vaccine, "totalTwiceInjected") || "Đang cập nhật",
        population: _.sumBy(vaccine, "population"),
        totalOnceInjected: _.sumBy(vaccine, "totalOnceInjected"),
        totalTwiceInjected: _.sumBy(vaccine, "totalTwiceInjected"),
      };
    }
    setData(dataVaccine);
  }, [currentCountry, vaccine, dataRatio]);
  // console.log(currentCountry.id,"currentCountry.id")
  return (
    <div
      className="flex flex-col cursor-pointer"
      title="Mở chi tiết số liệu tiêm vaccine"
      onClick={() => history.push("./vaccine")}
    >
      <span className="font-bold">Tổng người đã tiêm</span>
      <span className="text-green-600 text-2xl font-bold mb-1">
        {!currentCountry?.id ? 
        (<>{totalVaccine?.total_injected?.toLocaleString("vi-VN")}</>) :(<>{data?.totalInjected?.toLocaleString("vi-VN") || "đang cập nhật"}</>) }
       
      </span>
      <div className="h-2 relative bg-gray-200 rounded-lg mb-3 flex">
        <div
          className="bg-green-300 rounded-lg  h-2"
          style={{
            width: `${
              (100 / totalVaccine.national_population) * totalVaccine.total_first_injected +
              (100 / totalVaccine.national_population) * totalVaccine.total_secord_injected
            }%`,
          }}
        ></div>
        <div
          className="bg-green-500 rounded-lg  h-2 z-20 absolute"
          style={{
            width: `${
              (100 / totalVaccine.national_population) * totalVaccine.total_secord_injected + 1.2
            }%`,
          }}
        ></div>
      </div>
      {!currentCountry?.id ? (<>{totalVaccine.national_population && (
        <div className="text-xs">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
              <span>Đã tiêm 2 mũi</span>
            </div>
            <span className="text-gray-500">
              {((100 / totalVaccine.national_population) * totalVaccine.total_secord_injected)
                .toFixed(2)
                .replace(/\./g, ",")}
              % dân số {">"}=18 tuổi
            </span>
          </div>
          <div className="flex justify-between mb-3">
            <div className="flex items-center">
              <div className="w-2 h-2 bg-green-300 rounded-full mr-2"></div>
              <span>Đã tiêm 1 mũi</span>
            </div>
            <span className="text-gray-500">
              {((100 / totalVaccine.national_population) * totalVaccine.total_first_injected)
                .toFixed(2)
                .replace(/\./g, ",")}
              % dân số {">"}=18 tuổi
            </span>
          </div>
        </div>
      )}</>) : (
        <> {data.population && (
          <div className="text-xs">
            <div className="flex justify-between">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                <span>Đã tiêm 2 mũi</span>
              </div>
              <span className="text-gray-500">
                {((100 / data.popOverEighteen) * data.totalTwiceInjected)
                  .toFixed(2)
                  .replace(/\./g, ",")}
                % dân số {">"}=18 tuổi
              </span>
            </div>
            <div className="flex justify-between mb-3">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-green-300 rounded-full mr-2"></div>
                <span>Đã tiêm 1 mũi</span>
              </div>
              <span className="text-gray-500">
                {((100 / data.popOverEighteen) * data.totalOnceInjected)
                  .toFixed(2)
                  .replace(/\./g, ",")}
                % dân số {">"}=18 tuổi
              </span>
            </div>
          </div>
        )}</>
      )}
    </div>
  );
};

export default Vaccine;
