import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { listProvincesState } from "../../recoil/atom-provinces";
import {
  quarantineProvinceByDay,
  quarantineProvinceState,
} from "../../recoil/atom-quarantine";

const TopCards = () => {
  const [selectedProvince, setSelectedProvince] = useRecoilState(countrySelected);
  const provinceQuarantineObj = useRecoilValue(
    quarantineProvinceState({ province: selectedProvince.id })
  );
  const provinceQuarantineAllObj = useRecoilValue(
    quarantineProvinceByDay({ day: 0 })
  );
  const listProvinces = useRecoilValue(listProvincesState);

  const [provinceQuarantine, setProvinceQuarantine] = useState();

  useEffect(() => {
    let grocery = Object.values(
      provinceQuarantineObj.grocery_and_pharmacy_percent_change_from_baseline
    );
    let parks = Object.values(
      provinceQuarantineObj.parks_percent_change_from_baseline
    );
    let residential = Object.values(
      provinceQuarantineObj.residential_percent_change_from_baseline
    );
    let retail = Object.values(
      provinceQuarantineObj.retail_and_recreation_percent_change_from_baseline
    );
    let transit = Object.values(
      provinceQuarantineObj.transit_stations_percent_change_from_baseline
    );
    let workplaces = Object.values(
      provinceQuarantineObj.workplaces_percent_change_from_baseline
    );

    let result = {
      grocery_and_pharmacy_percent_change_from_baseline:
        grocery[grocery.length - 1],
      parks_percent_change_from_baseline: parks[parks.length - 1],
      residential_percent_change_from_baseline:
        residential[residential.length - 1],
      retail_and_recreation_percent_change_from_baseline:
        retail[retail.length - 1],
      transit_stations_percent_change_from_baseline:
        transit[transit.length - 1],
      workplaces_percent_change_from_baseline:
        workplaces[workplaces.length - 1],
    };

    setProvinceQuarantine(result);
  }, [provinceQuarantineObj]);

  const CardInfo = [
    {
      label: "Siêu thị & Hiệu thuốc",
      color: "#14B8A6",
      objectKey: "grocery_and_pharmacy_percent_change_from_baseline",
    },
    {
      label: "Công viên",
      color: "#22C55E",
      objectKey: "parks_percent_change_from_baseline",
    },
    {
      label: "Khu dân cư",
      color: "#A855F7",
      objectKey: "residential_percent_change_from_baseline",
    },
    {
      label: "Mua sắm & Giải trí",
      color: "#3B82F6",
      objectKey: "retail_and_recreation_percent_change_from_baseline",
    },
    {
      label: "Bến xe & Nhà ga",
      color: "#EC4899",
      objectKey: "transit_stations_percent_change_from_baseline",
    },
    {
      label: "Nơi làm việc",
      color: "#F97316",
      objectKey: "workplaces_percent_change_from_baseline",
    },
  ];




  const getTopProvince = (objectKey) => {
    const provinceArray = Object.values(provinceQuarantineAllObj).slice(1);
    const provinceKeys = Object.keys(provinceQuarantineAllObj).slice(1);

    const provinceValues = provinceArray.map((item, index) => {
      return parseInt(item[objectKey]);
    });

    let highest = 0;

    for (let i = 0; i < provinceValues.length; i++) {
      if (objectKey === "residential_percent_change_from_baseline") {
        if (provinceValues[i] > highest) highest = provinceValues[i];
      } else {
        if (provinceValues[i] < highest) highest = provinceValues[i];
      }
    }

    let highestIndex = provinceValues.findIndex((item) => item === highest);

    let province = listProvinces.find((item) => {
      return parseInt(item.id) === parseInt(provinceKeys[highestIndex]);
    });

    return {
      name: province?.name,
      id: province?.id,
      value: (highest >= 0 ? "+" : "") + highest.toString() + "%",
    };
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap justify-between w-full mb-2">
      {provinceQuarantine &&
        provinceQuarantineAllObj &&
        listProvinces &&
        CardInfo.map((card) => {
          return (
            <div className="w-1/2 pr-1 mb-3">
              <div
                className="border-t-8 h-full  p-3 justify-center items-start flex flex-col rounded-lg shadow-lg bg-white"
                style={{ borderColor: card.color }}
              >
                <div className="font-medium text-sm pb-1">{card.label}</div>
                <div
                  className="font-bold text-2xl"
                  style={{ color: card.color }}
                >
                  {(provinceQuarantine[card.objectKey] >= 0 ? "+" : "") +
                    provinceQuarantine[card.objectKey] +
                    "%"}
                </div>
                {!selectedProvince.id && (
                  <>
                    <div className="text-xs font-medium py-2">
                      Tỉnh/Thành phố nổi bật:
                    </div>
                    <div
                      className="flex flex-row w-full items-center cursor-pointer"
                      onClick={() => {
                        setSelectedProvince(
                          listProvinces.find(
                            (item) => parseInt(item.id) === parseInt(getTopProvince(card.objectKey).id)
                          )
                        );
                      }}
                    >
                      <div className="w-full text-left text-sm font-medium">
                        {getTopProvince(card.objectKey).name}
                      </div>
                      <div
                        className="text-right text-xs rounded"
                        style={{
                          backgroundColor: card.color,
                          color: "white",
                          padding: "0.125rem 0.25rem",
                        }}
                      >
                        {getTopProvince(card.objectKey).value}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TopCards;
