import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useRecoilState, useRecoilValue } from "recoil";
import { listProvincesState } from "../../recoil/atom-provinces";
import { compareState } from "../../recoil/atom-top-country";
import PopupTime from "../commons/PopupTime";
import ItemCountry from "../commons/ItemCountry";
import SmallSelect from "../commons/SelectInputSmall";
import { sliceChart } from "../../services/chartRange";

const ChartCompare = () => {
  const [filter, setFilter] = useState("case_by_time");
  const dataByDay = useRecoilValue(compareState({ filter: filter }));
  const [listProvinces] = useRecoilState(listProvincesState);
  const [listCountry, setListCountry] = useState(listProvinces);
  const [listCountryCompare, setListCountryCompare] = useState(
    listProvinces.slice(0, 5)
  );
  const [dataLabel, setDataLabel] = useState();
  const [selectedDate, setSelectedDate] = useState({
    active: 60,
    start: 60,
    end: 0,
  });

  const dateOptions = [
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const filterOptions = [
    {
      value: "case_by_time",
      label: "Ca nhiễm cộng dồn",
    },
    {
      value: "death_by_time",
      label: "Tử vong",
    },
  ];

  useEffect(() => {
    const _listCountry = [...listProvinces];
    _listCountry.splice(0, 5);
    setListCountry(_listCountry);
  }, [listProvinces]);

  const colorCompare = [
    "#EA2409",
    "#FFB31F",
    "#15A1FA",
    "#4BCFFA",
    "#34E7E4",
    "#0BE881",
    "#474787",
    "#BDC581",
    "#8854D0",
    "#2F3542",
  ];

  useEffect(() => {
    if (dataByDay) {
      let keysList = dataByDay[1] ? Object.keys(dataByDay[1]) : [];
      selectedDate !== 0
        ? setDataLabel(
            keysList.slice(keysList.length - selectedDate, keysList.length)
          )
        : setDataLabel(keysList);
    }
  }, [selectedDate]);

  const options = {
    locale: "vi-VN",
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        display: false,
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 30,
          boxHeight: 30,
          padding: 15,
        },
        title: {
          padding: 20,
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const dataCompareChart = () => {
    let unsplitted = Object.keys(dataByDay["1"]);
    unsplitted = sliceChart(unsplitted, selectedDate);
    let _label = unsplitted.map((day) => {
      let splitted = day.split("/");
      return parseInt(splitted[0]) + "/" + parseInt(splitted[1]);
    });

    return {
      labels: _label,
      datasets:
        dataByDay &&
        listCountryCompare.map((ct, id) => {
          const curentIndex = parseInt(ct.id);

          let unSlicedData = dataByDay[curentIndex]
            ? Object.values(dataByDay[curentIndex])
            : [];

          let slicedData = sliceChart(unSlicedData, selectedDate);

          return {
            label: ct.name,
            data: slicedData,
            backgroundColor: colorCompare[id],
            borderColor: colorCompare[id],
            borderWidth: 2,
            pointRadius: 0,
            fill: false,
            pointColor: colorCompare[id],
            pointStrokeColor: colorCompare[id],
            pointHighlightFill: "#fff",
            pointHighlightStroke: colorCompare[id],
            yAxisID: "y-axis-1",
          };
        }),
    };
  };

  useEffect(() => {
    setListCountryCompare(listProvinces.slice(0, 5));
  }, [listProvinces]);

  useEffect(() => {
    listCountryCompare.forEach((country) => {});
  }, [listCountryCompare]);

  const handleRemoveItemCompare = (id) => {
    const _listCountryCompare = [...listCountryCompare];
    if (_listCountryCompare.length <= 1) {
      return;
    }

    const _listCountry = [...listCountry];

    _listCountry.push(_listCountryCompare[id]);
    setListCountry(_listCountry);
    _listCountryCompare.splice(id, 1);
    setListCountryCompare(_listCountryCompare);
  };

  const [searchCountry, setSearchCountry] = useState("");

  const filterSearch = (data) => {
    return data.name.toLowerCase().indexOf(searchCountry.toLowerCase()) !== -1;
  };

  const [showList, setShowList] = useState(false);

  const handleSelectCountryCompare = (country) => {
    if (listCountryCompare.length >= 10) {
      return;
    }

    const removeCt = listCountry.findIndex((item) => item.id === country.id);
    const _listCountry = [...listCountry];

    _listCountry.splice(removeCt, 1);
    setListCountry(_listCountry);

    const _listCountryCompare = [...listCountryCompare];

    _listCountryCompare.push(country);
    setListCountryCompare(_listCountryCompare);
    setSearchCountry("");
  };

  return (
    <div className="relative">
      <div className="flex flex-row mb-5 items-center">
        <div className="mr-1">
          <SmallSelect
            title=""
            options={dateOptions}
            value={selectedDate.active}
            active={selectedDate.active !== -1}
            onChange={(value) => {
              setSelectedDate({ active: value, start: value, end: 0 });
            }}
          />
        </div>
        <div className="mr-1">
          <SmallSelect
            title=""
            options={filterOptions}
            value={filter}
            onChange={(value) => {
              setFilter(value);
            }}
          />
        </div>
        <PopupTime
          active={selectedDate.active === -1}
          onSave={(range) => {
            setSelectedDate({ active: -1, ...range });
          }}
        />
      </div>
      {dataByDay && listCountryCompare && (
        <>
          <div className="border w-full p-3 rounded-lg flex items-center justify-between">
            <div className="w-7/8 flex flex-wrap">
              {listCountryCompare.map((item, index) => (
                <ItemCountry
                  data={item}
                  removeItem={() => {
                    handleRemoveItemCompare(index);
                  }}
                />
              ))}
              <input
                onFocus={() => {
                  setShowList(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setShowList(false);
                  }, 200);
                }}
                value={searchCountry}
                onChange={(e) => {
                  setSearchCountry(e.target.value);
                }}
                className="w-auto outline-none"
                placeholder="Tìm kiếm"
              />
            </div>

            <div className="flex items-center w-1/8 justify-end">
              {searchCountry.length > 0 ? (
                <svg
                  onClick={() => {
                    setSearchCountry("");
                  }}
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <div className="flex">{listCountryCompare.length}/10</div>
              )}
            </div>
          </div>
          <div
            className={`${
              listCountryCompare.length < 10 && showList ? "absolute" : "hidden"
            } w-full`}
          >
            <div className="border my-1  bg-white w-full  rounded-lg py-3  overflow-y-auto max-h-80">
              {listCountry.filter(filterSearch).length > 0 ? (
                listCountry.filter(filterSearch).map((ct) => (
                  <div
                    className="font-bold my-1 hover:bg-gray-100 px-3 py-2 cursor-pointer"
                    onClick={() => {
                      handleSelectCountryCompare(ct);
                    }}
                  >
                    {ct.name}
                  </div>
                ))
              ) : (
                <div className="text-center p-3">
                  <span className="font-bold text-gray-500">
                    Không tìm thấy kết quả
                  </span>
                </div>
              )}
            </div>
          </div>

          <Line data={dataCompareChart} options={options} />
        </>
      )}
    </div>
  );
};

export default React.memo(ChartCompare);
