import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { listProvincesState } from "../../recoil/atom-provinces";
import api from "../../services/api";
import { Line } from "react-chartjs-2";
import PopupExpaned from "../commons/PopupExpaned";
import { countryByDayState } from "../../recoil/atom-top-country";
import ChartCompare from "../graph/ChartCompare";
import countrySelected from "../../recoil/atom-country";
import currenProvinceState from "../../recoil/atom-current-province";
import newlogo from "../../assets/logo.svg";
import ncscLogo from "../../assets/ncsc.png";
import MIC from "../../assets/MIC.png";
import MOH from "../../assets/moh.png";
import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import dayjs from "dayjs";
import totalState from "../../recoil/atom-total";

const TopCountry = () => {
  const [listProvinces, setListProvinces] = useRecoilState(listProvincesState);
  const [data, setData] = useState();
  const [total] = useRecoilState(totalState);
  const [isYesterday, setIsYesterday] = useState(false);

  const dataByDay = useRecoilValue(countryByDayState({ filter: "case" }));
  const [viewMore, setViewMore] = useState(false);
  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const currentProvince = useRecoilValue(
    currenProvinceState({ province: currentCountry.id })
  );

  useEffect(() => {
    if (total?.last_updated) {
      let _data = { ...total };

      let split = _data?.last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
      let diff = dayjs().diff(lastUpdated, "day");

      if (diff > 0 || (diff === 0 && _data?.new_case === 0))
        setIsYesterday(true);
    }
  }, [total]);

  useEffect(() => {
    setCurrentCountry({ ...currentCountry, currentProvince });
  }, [currentProvince]);

  useEffect(() => {
    listProvinces[0] &&
      handleSort(isYesterday ? "case_yesterday" : "new_case", 0);
  }, [listProvinces]);

  const options = {
    animation: {
      duration: 0,
    },
    bezierCurve: false, //remove curves from your plot
    scaleShowLabels: false, //remove labels
    tooltipEvents: [], //remove trigger from tooltips so they will'nt be show
    pointDot: false, //remove the points markers
    scaleShowGridLines: true, //set to false to remove the grids background
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 2000000,
        },

        display: false,
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },

        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  useEffect(() => {
    api.get("/covid/provinces").then((res) => {
      const data = res.data;
      let handleData = data.sort((a, b) => b.total_case - a.total_case);
      setListProvinces(handleData);
    });
  }, []);
  const colorCompare = ["#EA2409", "#FFB31F", "#15A1FA"];

  let unsplitted = dataByDay[1] && Object.keys(dataByDay[1]);
  let dataLabel =
    unsplitted?.map((day) => {
      let split = day.split("/");
      return parseInt(split[0]) + "/" + parseInt(split[1]);
    }) || [];
  // dataLabel = dataLabel && dataLabel.splice(dataLabel.length - 8, dataLabel.length - 1);
  const dataCompareChart = {
    labels: dataLabel,
    datasets: listProvinces.slice(0, 3).map((ct, id) => {
      const curentIndex = parseInt(ct.id);
      let _data = dataByDay[curentIndex]
        ? Object.values(dataByDay[curentIndex])
        : [];
      // _data = _data.splice(_data.length - 8, _data.length - 1);
      return {
        label: ct.name,
        data: _data,
        fill: false,
        pointRadius: 0,
        borderWidth: 1,
        backgroundColor: colorCompare[id],
        borderColor: colorCompare[id],
        yAxisID: "y-axis-1",
      };
    }),
  };
  const optionsCompareChart = {
    locale: "vi-VN",
    bezierCurve: false,
    legend: {
      position: "bottom",
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 2000,
        },

        display: false,
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },

        // display: false,
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },

      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const [sortArray, setSort] = useState([0, 0, 0]);

  const handleSort = (itemKey, index) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index);
    else sortByKey(itemKey, 1, index);
  };

  const sortByKey = (objectKey, direction, index = -1) => {
    let sorted = [...listProvinces].sort(function (a, b) {
      if (a[objectKey] > b[objectKey]) return direction * -1;
      else if (a[objectKey] < b[objectKey]) return direction;
      else return 0;
    });

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };

  const getProvincesLength = () => {
    let index = listProvinces.length;
    listProvinces.forEach((item) => {
      if (item.total_case === 0) index--;
    });

    return index;
  };

  return (
    <div className="mb-14 lg:mb-0">
      {!currentCountry.id && (
        <div>
          <div className="flex justify-between items-center mb-5">
            <div>
              <span className="font-bold mb-5">So sánh ca nhiễm</span>
            </div>
            <PopupExpaned
              title="Biểu đồ so sánh theo tỉnh thành"
              textButton="Mở rộng"
            >
              <ChartCompare />
            </PopupExpaned>
          </div>
          <div>
            <Line data={dataCompareChart} options={optionsCompareChart} />
          </div>
          <div className="border-b my-3"></div>
        </div>
      )}

      {!currentCountry.id ? (
        <div>
          <h3 className="font-bold mb-2">
            {getProvincesLength()} tỉnh thành có ca nhiễm
          </h3>
          <table className="w-full text-sm">
            <thead>
              <tr className="text-left mb-2">
                <th className="w-1/4">Khu vực</th>
                <th
                  className="w-1/4"
                  onClick={() =>
                    handleSort(isYesterday ? "case_yesterday" : "new_case", 0)
                  }
                >
                  <div className="flex flex-row cursor-pointer items-center mx-1">
                    Hôm {isYesterday ? "qua" : "nay"}
                    <span>
                      {sortArray[0] !== 0 ? (
                        <img
                          src={sortArray[0] === 1 ? descend : ascend}
                          className="ml-1 w-4 h-4"
                        />
                      ) : (
                        <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                      )}
                    </span>
                  </div>
                </th>
                <th
                  className="w-1/4"
                  onClick={() => handleSort("total_case", 1)}
                >
                  <div className="flex flex-row cursor-pointer items-center mx-1">
                    Tổng ca nhiễm
                    <span>
                      {sortArray[1] !== 0 ? (
                        <img
                          src={sortArray[1] === 1 ? descend : ascend}
                          className="ml-1 w-4 h-4"
                        />
                      ) : (
                        <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                      )}
                    </span>
                  </div>
                </th>
                <th
                  className="w-1/4"
                  onClick={() => handleSort("total_death", 2)}
                >
                  <div className="flex flex-row cursor-pointer items-center mx-1">
                    Tổng tử vong
                    <span>
                      {sortArray[2] !== 0 ? (
                        <img
                          src={sortArray[2] === 1 ? descend : ascend}
                          className="ml-1 w-4 h-4"
                        />
                      ) : (
                        <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                      )}
                    </span>
                  </div>
                </th>
                {/* <th>Ca theo ngày</th> */}
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .slice(0, viewMore ? data.length - 1 : 10)
                  .map((province, index) => {
                    return (
                      <tr
                        onClick={() => {
                          setCurrentCountry(province);
                        }}
                        className={`border-b h-10 ${
                          index % 2 == 0 && "bg-gray-100  rounded-lg"
                        } cursor-pointer`}
                      >
                        <td>{province?.name}</td>
                        <td className="text-red-600 font-bold">
                          +
                          {isYesterday
                            ? province?.case_yesterday?.toLocaleString("vi-VN")
                            : province?.new_case?.toLocaleString("vi-VN")}
                        </td>
                        <td className="font-bold">
                          {province.total_case?.toLocaleString("vi-VN")}
                        </td>
                        <td className="font-bold">
                          {province.total_death?.toLocaleString("vi-VN")}
                        </td>
                        {/* <td className="w-2/5">
                <Line
                  data={(canvas) => {
                    const ctx = canvas.getContext("2d");
                    ctx.height = 3;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 0);
                    gradient.addColorStop(0, "rgb(255,106,7)");
                    gradient.addColorStop(1, "rgb(252,242,233)");
                    return {
                      labels:
                        dataByDay[1] &&
                        Object.keys(dataByDay[`${parseInt(province.id)}`]),
                      datasets: [
                        {
                          label: "Đang điều trị",
                          backgroundColor: gradient, // Put the gradient here as a fill color
                          borderColor: "#ff6c23",
                          borderWidth: 2,
                          pointColor: "#fff",
                          pointRadius: 1,
                          fill: "start",
                          backgroundColor: gradient,
                          pointStrokeColor: "#ff6c23",
                          pointHighlightFill: "#fff",
                          pointHighlightStroke: "#ff6c23",
                          data:
                            dataByDay[1] &&
                            Object.values(
                              dataByDay[`${parseInt(province.id)}`]
                            ),
                        },
                      ],
                    };
                  }}
                  options={options}
                />
              </td> */}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          <div className="flex justify-center my-3 text-sm">
            <div
              onClick={() => {
                setViewMore(!viewMore);
              }}
              className="border px-5 py-1 rounded-full flex items-center hover:bg-gray-100 cursor-pointer"
            >
              <span> {!viewMore ? "Xem thêm" : "Thu gọn"}</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {/* <h3 className="font-bold mb-1 ">
            {currentProvince.districts.length} huyện của {currentCountry.Name}{" "}
            có ca nhiễm
          </h3>
          <table className="w-full">
            <tr className="text-left mb-2">
              <th>Khu vực</th>
              <th>Ca nhiễm mới</th>
              <th>Tổng</th>
              <th>Ca theo ngày</th>
            </tr>

            {currentProvince.districts
              .slice(0, viewMore ? listProvinces.length - 1 : 5)
              .map((province, index) => {
                return (
                  <tr
                    className={`border-b h-10 ${
                      index % 2 == 0 && "bg-gray-100  rounded-lg"
                    } cursor-pointer`}
                  >
                    <td className="text-sm">{province?.name}</td>
                    <td className="text-red-600 font-bold">
                      +{province?.new_case}-
                    </td>
                    <td>{province.total_case}-</td>
                    <td className="w-2/5">
                <Line
                  data={(canvas) => {
                    const ctx = canvas.getContext("2d");
                    ctx.height = 3;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 0);
                    gradient.addColorStop(0, "rgb(255,106,7)");
                    gradient.addColorStop(1, "rgb(252,242,233)");
                    return {
                      labels:
                        dataByDay[1] &&
                        Object.keys(dataByDay[`${parseInt(province.id)}`]),
                      datasets: [
                        {
                          label: "Đang điều trị",
                          backgroundColor: gradient, // Put the gradient here as a fill color
                          borderColor: "#ff6c23",
                          borderWidth: 2,
                          pointColor: "#fff",
                          pointRadius: 1,
                          fill: "start",
                          backgroundColor: gradient,
                          pointStrokeColor: "#ff6c23",
                          pointHighlightFill: "#fff",
                          pointHighlightStroke: "#ff6c23",
                          data:
                            dataByDay[1] &&
                            Object.values(
                              dataByDay[`${parseInt(province.id)}`]
                            ),
                        },
                      ],
                    };
                  }}
                  options={options}
                />
              </td>
                  </tr>
                );
              })}
          </table> */}
        </div>
      )}
      <div class="flex flex-col align text-center">
        <h1 className="font-bold pl-2 mr-5 uppercase">DỮ LIỆU COVID-19</h1>

        <div>
          <h3 className="text-xs uppercase py-3 ">Vận hành bởi</h3>
        </div>
        <div class="logoBrand">
          <img src={newlogo} style={{ width: "70px" }} />
        </div>
        <div>
          {" "}
          <h2 className="font-bold text-xs pl-2 mr-5 py-3 uppercase ">
            Trung tâm công nghệ <br />
            Phòng chống dịch covid-19 quốc gia
          </h2>
        </div>
        <div>
          <h3 className="text-xs uppercase py-3 ">Phát triển bởi</h3>
        </div>
        <div class="logoBrand">
          <img src={ncscLogo} style={{ width: "100px" }} />
        </div>
        <div>
          {" "}
          <h2 className="font-bold text-xs pl-2 mr-5 py-3 uppercase ">
            Trung tâm Giám sát an toàn <br />
            không gian mạng quốc gia (NCSC)
          </h2>
        </div>
        <div>
          <div class="flex justify-center text-center py-5">
            <div className="px-5">
              {" "}
              <img src={MIC} width="80vh" />
            </div>
            <div className="px-5">
              {" "}
              <img src={MOH} width="80vh" />
            </div>
          </div>
        </div>
      </div>

      {/* <h1>hehe</h1><br/>
         <div class= ""><img  src={ncscLogo} style={{width:"100px"}}  /></div> */}
      <div className="w-full flex text-left  items-center text-xs text-gray-500">
        <div className="mr-2 text-xs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <span>
          Dữ liệu được thu thập từ nhiều nguồn, cập nhật tại các thời điểm khác
          nhau và có thể không phải lúc nào cũng thống nhất.
        </span>
      </div>
    </div>
  );
};

export default TopCountry;
