import { atom } from "recoil";

const provinceByDayState = atom({
  key: "provinceByDay", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

const provinceByDayTotal = atom({
  key: "provinceByDayTotal", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

const provinceStatState = atom({
  key: "provinceStat",
  default: {
    total_case: false
  },
})

export { provinceByDayState, provinceByDayTotal, provinceStatState };
