/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ExpanedButton from "./ExpanedButton";

const PopupExpaned = ({ children, title = "", textButton = "Mở rộng" }) => {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ExpanedButton text={textButton} />
      </div>
      <Transition.Root show={open} as={Fragment} style={{ zIndex: 1000 }}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className=" transform rotate-90 lg:rotate-0 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl max-h-screen overflow-y-auto transition-all sm:my-8 sm:align-middle  w-full lg:w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="p-5 text-2xl font-bold">
                  <div className="flex justify-between">
                    <h3>{title}</h3>
                    <div
                      title="Đóng"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      className="w-7 h-7 cursor-pointer bg-gray-400 flex justify-center items-center text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 pb-4 sm:pb-4">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
export default PopupExpaned;
