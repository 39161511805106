import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import bluezoneState from "../../recoil/atom-bluezone";
import countrySelected from "../../recoil/atom-country";
const CardStatistic = () => {
  const [currentCountry] = useRecoilState(countrySelected);
  const dataBluezone = useRecoilValue(bluezoneState());
  const [dataCard, setDataCard] = useState();

  useEffect(() => {
    setDataCard(dataBluezone.national);
  }, [dataBluezone]);

  const CardInfo = !currentCountry.id
    ? [
        {
          label: "Đã cài đặt",
          color: "#14B8A6",
          objectKey: "total_installs_nationwide",
        },
        {
          label: "Đang hoạt động",
          color: "#22C55E",
          objectKey: "total_not_uninstalled",
        },
        {
          label: "Đã đăng ký số điện thoại",
          color: "#A855F7",
          objectKey: "total_registered_phone_number",
        },
      ]
    : [];
  return (
    <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
      {CardInfo.map((card) => {
        return (
          <div className="pr-1 mb-3">
            <div
              className="border-t-8 h-full  p-3 justify-center items-start flex flex-col rounded-lg shadow-lg bg-white"
              style={{ borderColor: card.color }}
            >
              <div className="font-medium text-sm pb-1">{card.label}</div>
              <div className="font-bold text-2xl" style={{ color: card.color }}>
                {dataCard && dataCard[card.objectKey].toLocaleString("vi-VN")}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardStatistic;
