import React from "react";

const LoadingMenuLeft = () => {
  return (
    <div className="px-3 h-screen mt-20">
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-4 py-1">
          <div class="h-4 bg-gray-100 rounded w-2/4"></div>
          <div class="h-2 bg-gray-100 rounded w-3/4 mb-5"></div>
          <div class="space-y-2 mb-3">
            <div class="h-4 bg-gray-100 rounded"></div>
            <div class="h-4 bg-gray-100 rounded w-5/6"></div>
          </div>

          <div class="space-y-2 mb-3">
            <div class="h-4 bg-gray-100 rounded"></div>
            <div class="h-4 bg-gray-100 rounded w-5/6"></div>
          </div>

          <div class="space-y-2 mb-3">
            <div class="h-4 bg-gray-100 rounded w-2/5"></div>
            <div class="h-6 bg-gray-100 rounded w-2/6"></div>
            <div class="h-9 bg-gray-100 rounded w-3/6"></div>
          </div>
          <div class="space-y-2 mb-3">
            <div class="h-4 bg-gray-100 rounded"></div>
            <div class="h-4 bg-gray-100 rounded"></div>
          </div>
          <div class="space-y-2 mb-3">
            <div class="h-4 bg-gray-100 rounded w-2/5"></div>
            <div class="h-6 bg-gray-100 rounded w-2/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMenuLeft;
