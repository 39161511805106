import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import PopupExpaned from "../commons/PopupExpaned";
import { ChartNewCaseOnlyChart } from "../graph/ChartNewCase";
import totalState from "../../recoil/atom-total";
import caseByDayState from "../../recoil/atom-case-by-day";
import countrySelected from "../../recoil/atom-country";
import currentProvinceState from "../../recoil/atom-current-province";
import ChartTotalStats from "../graph/ChartTotalStats";

const Trend = () => {
  const history = useHistory();
  const [total] = useRecoilState(totalState);
  const selectedProvince = useRecoilValue(countrySelected);
  const province = useRecoilValue(
    currentProvinceState({ province: selectedProvince.id })
  );

  const [data, setData] = useState(total);

  useEffect(() => {
    selectedProvince.id
      ? province.total_case > -1 && setData(province)
      : setData(total);
  }, [selectedProvince, total, province]);

  const [caseByDay, setCaseByDay] = useRecoilState(caseByDayState);
  useEffect(() => {
    api.get(`/covid/national_case_by_day`).then((success) => {
      const _data = success.data;
      setCaseByDay(_data);
    });
  }, []);
  const height = 200;
  const [filterTrend, setFilterTrend] = useState(0);
  const listFilterType = [{ label: "Ca nhiễm mới" }, { label: "Ca tử vong" }];
  const options = {
    locale: 'vi-VN',
    scales: {
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
        grid: {
          display: false,
        },

        // display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const primaryColor = "#37A5BF";
  const [filter, setFilter] = useState(30);
  const [label, setLabel] = useState([]);

  let _label = (data.death_by_time && Object.keys(data.death_by_time)) || [];
  if (filter !== 0) {
    _label = _label.slice(_label.length - filter, _label.length);
  }

  const filterTime = [
 
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const totalDeath = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, "rgb(255,106,7)");
    gradient.addColorStop(1, "rgb(252,242,233)");
    let dataChart = data.death_by_time ? Object.values(data.death_by_time) : [];
    if (filter !== 0) {
      dataChart = dataChart.slice(dataChart.length - filter, dataChart.length);
    }

    return {
      labels: _label || [],
      datasets: [
        {
          label: "Đã tử vong",
          backgroundColor: "#9CA3AF", // Put the gradient here as a fill color
          borderColor: "#ff6c23",
          borderWidth: 0,
          pointColor: "#fff",
          pointRadius: 1,
          fill: "start",
          backgroundColor: "#9CA3AF",
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          data: dataChart,
        },
      ],
    };
  };
  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-2">
        <span className="font-bold">Xu hướng lây nhiễm</span>
        {filterTrend === 0 ? (
          <PopupExpaned title="Biểu đồ ca nhiễm mới">
            <ChartNewCaseOnlyChart leftSelectedDate={filter} />
          </PopupExpaned>
        ) : (
          <PopupExpaned title={"Số ca tử vong"}>
            {/* <div className="w-1/3 pt-2">
              <Select
                title=""
                options={filterTime}
                value={filter}
                onChange={(value) => {
                  setFilter(value);
                }}
              />
            </div> */}
            {/* <Bar data={totalDeath} options={options} /> */}
            <ChartTotalStats
              title="Tử vong"
              objectKey="death"
              label="Tử vong"
              chartColor="gray"
              noResize
              leftSelectedDate={filter}
            />
          </PopupExpaned>
        )}
      </div>
      <div className="flex mb-3">
        {listFilterType.map((menuItem, index) => (
          <div
            onClick={() => {
              setFilterTrend(index);
            }}
            className="color-primary text-sm cursor-pointer mr-3"
            style={
              filterTrend === index
                ? {
                    color: primaryColor,
                    borderBottom: `3px solid ${primaryColor}`,
                  }
                : {}
            }
          >
            {menuItem.label}
          </div>
        ))}
      </div>
      <div className="flex mb-3">
        {filterTime.map((time) => (
          <div
            onClick={() => {
              setFilter(time.value);
            }}
            className={
              "rounded h-full px-2 py-1 border-1 text-xs font-medium mr-1 cursor-pointer " +
              (time.value === filter ? "bg-gray-500 text-white" : "bg-gray-200")
            }
            // className="mr-2 bg-gray-300 px-3 py-1 text-sm font-medium rounded-lg text-gray-600 cursor-pointer hover:bg-gray-400"
            // style={
            //   time.value === filter
            //     ? { backgroundColor: "#666666", color: "white" }
            //     : {}
            // }
          >
            {time.label}
          </div>
        ))}
      </div>
      {filterTrend === 0 && (
        <div>
          <ChartNewCaseOnlyChart leftSelectedDate={filter} left noLegend />
        </div>
      )}
      {filterTrend === 1 && (
        // <Bar data={totalDeath} options={options} />
        <ChartTotalStats
          title="Tử vong"
          objectKey="death"
          label="Tử vong"
          chartColor="gray"
          onLeftBar
          noResize
          leftSelectedDate={filter}
        />
      )}
      <div className="flex justify-center my-3">
        <div
          onClick={() => {
            history.push("graph");
          }}
          className="border px-5 py-1 rounded-full text-sm flex items-center hover:bg-gray-100 cursor-pointer"
        >
          <span> Xem thêm biểu đồ</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
      <div className="border-b my-2"></div>
    </div>
  );
};

export default Trend;
