import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { listProvincesState } from "../../recoil/atom-provinces";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDetectClickOutside } from "react-detect-click-outside";

const SearchBox = () => {
  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const [listProvinces] = useRecoilState(listProvincesState);
  const [search, setSearch] = useState("");
  const [onSearch, setOnSearch] = useState(false);
  const [keyPressSelect, setKeyPressSelect] = useState(-1);
  const [historySearch, setHistorySearch] = useState([]);
  const closeSearch = () => {
    setOnSearch(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeSearch });
  const filterSearch = (data) => {
    return data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  useEffect(() => {
    try {
      const getLocalSearch = JSON.parse(localStorage.getItem("s")) || [];
      setHistorySearch(getLocalSearch);
    } catch (e) {
      setHistorySearch([]);
    }
  }, []);

  const handleSelectCountry = (ct) => {
    setOnSearch(false);
    setSearch(ct.name);
    setCurrentCountry(ct);
    const currentId = parseInt(ct.id);
    if (historySearch.findIndex((item) => item === currentId) < 0) {
      const newHistory = [currentId, ...historySearch];
      setHistorySearch(newHistory);
      localStorage.setItem("s", JSON.stringify(newHistory));
    }
    // alert("Đang hoàn thiện tính năng search!");
  };

  useEffect(() => {
    localStorage.setItem("s", JSON.stringify(historySearch));
  }, [historySearch]);

  const handleRemoveHistory = (e, history, index) => {
    e.stopPropagation();
    const _history = [...historySearch];
    _history.splice(index, 1);
    setHistorySearch(_history);
  };

  const clearAllHistorySearch = (e) => {
    e.stopPropagation();
    setHistorySearch([]);
  };

  const handleKeyDown = (e) => {
    const listSearchArr = listProvinces.filter(filterSearch);
    if (listSearchArr.length > 0) {
      if (e.keyCode === 40) {
        keyPressSelect <= listSearchArr.length - 2 &&
          setKeyPressSelect(keyPressSelect + 1);
      } else if (e.keyCode === 38) {
        keyPressSelect >= 0 && setKeyPressSelect(keyPressSelect - 1);
      } else if (e.keyCode === 13) {
        if (search.length === 0) {
          return;
        }
        setCurrentCountry(listSearchArr[keyPressSelect]);
        handleSelectCountry(listSearchArr[keyPressSelect]);
      } else if (e.keyCode === 8) {
        setKeyPressSelect(0);
      }
      return;
    }
    return;
  };
  
  return (
    <div
      className={`${onSearch && "shadow-2xl rounded-b-2xl"
        } w-full z-30 bg-white p-2 border-r`}
      ref={ref}
    >
      {/* <div class="grid grid-cols-3 gap-4">
 
  <div class="col-span-2 ..."><span className="text-xs uppercase ">Vận hành và phát triển </span>  </div>
  <div class="..."></div>
  <div class= ""><img className="object-center" src={ncscLogo}  /></div>
  <div class="col-span-2 ..."><span className="text-xs text-bold ">Trung tâm Giám sát an toàn không gian mạng quốc gia (NCSC)</span></div>
</div>
  
  
  
  

     
<div class="flex justify-center text-center py-5">
  <div className="px-5"> <img src={MIC} width="80vh"  /></div> 
  <div className="px-5"> <img src={MOH}  width="80vh"  /></div>
  
</div> */}

      {/* <div className="flex flex-col absolute w-1/4">
        <SearchBox />
      </div> */}

      <div className="flex items-center">
        {onSearch && (
          <div
            className="cursor-pointer p-2 hover:bg-gray-200 rounded-full mr-1"
            onClick={() => {
              setOnSearch(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 17l-5-5m0 0l5-5m-5 5h12"
              />
            </svg>
          </div>
        )}
        <div className="border p-2 rounded-lg w-full text-sm">
          <div className="flex">
            {!onSearch && (
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            )}
            <input
              onFocus={() => {
                setOnSearch(true);
              }}
              onKeyDown={handleKeyDown}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              // disabled={location.pathname === "/graph"}
              placeholder="Tìm kiếm địa điểm"
              className="outline-none w-full"
            />
            {search.length > 0 && (
              <div
                title="Xóa tất cả"
                className="cursor-pointer"
                onClick={() => {
                  setSearch("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      {onSearch && (
        <div className="my-3 lg:max-h-80 max-h-screen overflow-y-auto">
          {search.length > 0 ? (
            listProvinces.filter(filterSearch).map((ct, index) => {
              return (
                <div
                  className={`${keyPressSelect === index && "bg-gray-100"
                    } cursor-pointer py-1 hover:bg-gray-200 px-2 mb-2 rounded-lg flex items-center`}
                  onClick={() => {
                    handleSelectCountry(ct);
                  }}
                >
                  <div className="bg-gray-200 p-2 rounded-full mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <span className="font-bold">{ct?.name}</span>
                </div>
              );
            })
          ) : (
            <div>
              {historySearch.length > 0 ? (
                <div className="overflow-y-auto">
                  <div className="flex justify-between">
                    <h4 className="text-lg text-gray-600  font-bold px-1">
                      Tìm kiếm gần đây
                    </h4>
                    <span
                      className="text-red-400 cursor-pointer hover:text-red-600"
                      onClick={clearAllHistorySearch}
                    >
                      Xóa tất cả
                    </span>
                  </div>
                  {historySearch.map((history, index) => {
                    const showHistory = listProvinces.find(
                      (item) => parseInt(item.id) === history
                    );
                    return (
                      <div
                        onClick={() => {
                          handleSelectCountry(showHistory);
                        }}
                        className="flex mb-2 justify-between cursor-pointer py-1 hover:bg-gray-100 px-2 rounded-lg"
                      >
                        <div className="flex items-center">
                          <div className="bg-gray-200 p-2 rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <span className="ml-2 font-bold">
                            {showHistory?.name}
                          </span>
                        </div>
                        <div
                          className="items-center flex"
                          title="Xóa kết quả này"
                          onClick={(e) => {
                            handleRemoveHistory(e, history, index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <h3 className="text-center text-gray-500 font-bold">
                  Lịch sử tìm kiếm rỗng
                </h3>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
