import { atom } from "recoil";

const totalState = atom({
  key: "totalState", // unique ID (with respect to other atoms/selectors)
  default: {
    active_by_day: [],
    case_by_day: [],
    death_by_day: [],
    active_by_time: [],
    case_by_time: [],
    death_by_time: [],
    total_case: false
  }, // default value (aka initial value)
});

export default totalState;
