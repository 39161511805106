import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import bluezoneState from "../../recoil/atom-bluezone";
import countrySelected from "../../recoil/atom-country";
import InformationPopup from "../commons/InformationPopup";
import ProvinceSelector from "../commons/ProvinceSelector";
import CardStatistic from "./CardStatistic";
import TableBluezone from "./TableBluezone";
import WrapChart from "../graph/WrapChart";

const Bluezone = () => {
  const [currentCountry] = useRecoilState(countrySelected);
  const dataBluezone = useRecoilValue(bluezoneState());
  const listDayUpdate = dataBluezone?.national?.date || "gần đây";
  return (
    <div className="w-full flex flex-col p-0 md:p-2 lg:p-5">
      <div className=" flex flex-col px-2 lg:p-0">
        <div className="flex flex-row items-end">
          <span className="text-xl font-medium mr-1">
            Thống kê tình hình triển khai PC-Covid{" "}
            {currentCountry.id ? `tại ${currentCountry.name}` : "của cả nước"}
          </span>
          <div className="transform -translate-y-1/4 z-50">
            <InformationPopup>
              <div className="text-xs">
                Ghi chú: Ngày 29/6/2021, dữ liệu tập thuê bao trên toàn quốc
                được cập nhật mới nhất theo tiêu chí chỉ tính các thuê bao có
                phát sinh dữ liệu 3G/4G và sự di chuyển của các thuê bao qua các
                địa bàn. Do đó, dẫn tới sự thay đổi số liệu theo hướng chính xác
                hơn.
              </div>
            </InformationPopup>
          </div>
        </div>
      </div>
      <span className="text-xs text-gray-500">
        Nguồn: Bộ Thông tin và Truyền thông. Cập nhật lần cuối: {listDayUpdate}
      </span>
      <div className="px-2 md:p-0">
        <ProvinceSelector noPadding />
      </div>
      <div className="px-2 md:p-0">
        <CardStatistic />
      </div>
      <WrapChart
        title="Thông số PC-Covid"
      >
         <TableBluezone />
      </WrapChart>
     
    </div>
  );
};

export default Bluezone;
