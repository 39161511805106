import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import countrySelected from "../../recoil/atom-country";

const NavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedProvince, setSelectedProvince] =
    useRecoilState(countrySelected);

  const routeNav = [
    {
      title: "Tổng quan",
      path: "",
    },
    {
      title: "Ca nhiễm",
      path: "/graph",
    },
    {
      title: "Tiêm chủng",
      path: "/vaccine",
    },
    {
      title: "Giãn cách",
      path: "/distancing",
    },
    {
      title: "PC-Covid",
      path: "/pc-covid",
    },
    {
      title: "Tin tức",
      path: "/news",
    },
    // {
    //   title: "Phân tích",
    //   path: "dulieu/article",
    // },
  ];
  return (
    <div
      className="flex fixed min-w-full z-20 h-15 overflow-x-auto"
      style={{ zIndex: 1000 }}
    >
      <div className="w-full flex bg-gray-100">
        {routeNav.map((menuItem) => {
          return (
            <div className="relative flex-none bg-gray-100">
              <Link to={`../dulieu${menuItem.path}`}>
                <div className="p-3 cursor-pointer text-sm font-medium">
                  {menuItem.title}
                </div>
              </Link>
              {location.pathname === `/dulieu${menuItem.path}` && (
                <div
                  className="absolute bottom-0 h-1 w-2/3 left-2/4  transform -translate-x-2/4"
                  style={{ backgroundColor: "#028eae" }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavBar;
