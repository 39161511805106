import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { listProvincesState } from "../../recoil/atom-provinces";
import api from "../../services/api";
import ProvinceSelector from "../commons/ProvinceSelector";
import LoadingChartScreen from "../Loading/loading-chart-screen";
import totalState from "../../recoil/atom-total";

const Article = () => {
  const [total] = useRecoilState(totalState);
  const [listProvinces] = useRecoilState(listProvincesState);

  const [regions, setRegion] = React.useState({
    north: [],
    central: [],
    southern: [],
  });
  const [currentRegion, setCurentRegion] = React.useState("");
  useEffect(() => {
    api.get("/provinces/metadata").then((res) => {
      const data = res.data;
      setRegion({
        north: data[0],
        central: data[1],
        southern: data[2],
      });
    });
  }, []);
  const selectedProvince = useRecoilValue(countrySelected);
  const getPeekCase = () => {
    return 10;
  };
  React.useEffect(() => {
    let _currentRegion = { provinces: [] };
    if (regions.north.some((item) => item.name === selectedProvince.name)) {
      _currentRegion = { label: "Miền Bắc", provinces: regions.north };
    } else if (
      regions.central.some((item) => item.name === selectedProvince.name)
    ) {
      _currentRegion = { label: "Miền Trung", provinces: regions.central };
    } else if (
      regions.southern.some((item) => item.name === selectedProvince.name)
    ) {
      _currentRegion = { label: "Miền Nam", provinces: regions.southern }
    }
    _currentRegion.provinces = _currentRegion.provinces
      .map((item) => {
        const result = listProvinces.find(
          (itemProv) => itemProv.id === item.id
        );
        return { ...item, ...result, peek: getPeekCase() };
      })
      .sort((a, b) => b.total_case - a.total_case)
      .splice(0, 3);
    setCurentRegion(_currentRegion);
  }, [selectedProvince, regions]);
  console.log(selectedProvince);
  return (
    <div className="w-full justify-center pb-6 py-5">
      <div className="pt-5 lg:px-5 px-2 pb-2 flex flex-col">
        <span className="text-xl font-medium">
          Phân tích tình hình dịch:{" "}
          {selectedProvince.id ? selectedProvince.name : "Toàn quốc"}
        </span>
      </div>
      <ProvinceSelector />
      <div className="px-40  bg-white py-5">
        <div className="text-center w-full flex flex-col">
          <span className="text-red-500 text-4xl">
            {selectedProvince.id ? selectedProvince.name : "Toàn quốc"}
          </span>
          <span className="text-lg">Tình hình dịch bệnh đang đạt đỉnh </span>
          <span className="text-lg">và đang tăng</span>
          <span className="text-sm">
            83 ca nhiễm trên 100,000 người trong 7 ngày vừa qua
          </span>
          <span className="text-sm">Cập nhật: 3:08 AM IT</span>
        </div>
        <div className="w-full flex flex-col mb-3">
          <div
            className="p-3 border-blue-500 mb-5"
            style={{ borderLeft: "3px solid red" }}
          >
            <span className="text-lg text-justify">
              Số ca tử vong liên quan tới COVID-19 hàng này ở Việt Nam đạt
              ngưỡng kỷ lục mới: hơn
              <b className="mx-1">
                {total.death_by_day &&
                  Object.values(total.death_by_day).sort((a, b) => b - a)[0]}
              </b>
              ca.
            </span>
          </div>
          {/* <span className="mb-5 text-justify">
            Số ca nhiễm COVID-19 tại Việt Nam đạt đỉnh khi ghi nhận số ca nhiễm
            trung bình theo ngày là{" "}
            <b>
              {total.case_by_day &&
                Object.values(total.case_by_day).sort((a, b) => b - a)[0]}
            </b>{" "}
            ca nhiễm mới một ngày.
          </span> */}
          <span>
            Cho đến nay,{" "}
            <b>{selectedProvince.id ? selectedProvince.name : "Toàn quốc"}</b> đã ghi
            nhận tổng số{" "}
            <b className="text-red-500">
              {selectedProvince.id ? selectedProvince.total_case?.toLocaleString() : total?.total_case?.toLocaleString()}
            </b>{" "}
            ca nhiễm và{" "}
            <b className="mr-1">  {selectedProvince.id ? selectedProvince.total_death?.toLocaleString() : total?.total_death?.toLocaleString()}</b>
            ca tử vong có liên quan tới COVID-19.
          </span>
        </div>
        <React.Suspense fallback={<LoadingChartScreen />}>
          <div className="mb-5">
            <span className="text-2xl mb-7">Tình hình dịch bệnh</span>
            {/* <div className="w-full flex">
              <div className="w-1/2 pr-3 h-full">
                <span className="text-md font-bold">
                  Ca nhiễm mới theo ngày
                </span>
                <ChartNewCase />
              </div>
              <div className="w-1/2 pr-3 h-full">
                <ChartTotalStats
                  title="Tử vong"
                  objectKey="death"
                  label="Tử vong"
                  chartColor="gray"
                />
              </div>
            </div> */}
          </div>
          <div className="flex flex-col mb-5">
            <span className="text-2xl mb-3">Tiêm chủng</span>
            <span className="mb-3 text-justify">
              Tính đến thời điểm hiện tại, Việt Nam đã tiêm chủng ít nhất
              17,647,353 liều vắc-xin COVID-19, đạt tỉ lệ tiêm chủng 9.1% toàn
              bộ dân số.
            </span>
            <div className="bg-gray-200 p-7 flex flex-col">
              <span className="font-bold">Chú thích</span>
              <span className="text-justify">
                Dữ liệu về vắc-xin là dữ liệu được thể hiện là số lượng liều
                vắc-xin đã được tiêm, không phải số lượng người đã được tiêm
                chủng.
              </span>
            </div>
          </div>
          <div className="mb-5">
            <span className="text-justify">
              Trong tuần vừa qua, trung bình Việt Nam có <b>438,321</b> liều
              vắc-xin được tiêm mỗi ngày. Nếu tiếp tục với tỉ lệ này, chúng ta
              cần thêm khoảng <b>45</b> ngày để có thể tiêm chủng cho 10% dân
              số.
            </span>
          </div>
          <div className="flex flex-col mb-5">
            <span className="text-2xl mb-3 text-justify">
              So sánh tình hình dịch bệnh với các tỉnh thành khác
            </span>
            <span className="text-justify mb-3">
              Hiện tại chúng ta không có một số liệu hoàn hảo để có thể so sánh
              tình hình dịch bệnh giữa các nước trên thế giới trong bối cảnh đại
              dịch COVID-19. Thay vào đó, chúng ta có thể dựa vào nhiều loại số
              liệu khác nhau để có thể có cái nhìn toàn cảnh về tình hình dịch
              bệnh của các quốc gia.
            </span>
            <span className="text-justify mb-3">
              Các biểu đồ dưới đây thể hiện nhiều loại số liệu khác nhau và giúp
              chúng ta có cái nhìn tổng thể về tình hình dịch bệnh của Việt Nam
              so với các nước trên thế giới.
            </span>
            <div className="bg-gray-200 flex  rounded-md mb-7">
              <div className="w-1/4 border-r-2 border-gray-400 p-7 text-center">
                Tổng ca nhiễm và tử vong
              </div>
              <div className="w-1/4 border-r-2 border-gray-400 p-7 text-center">
                Tỷ lệ ca nhiễm / triệu người
              </div>
              <div className="w-1/4 border-r-2 border-gray-400 p-7 text-center">
                Ca nhiễm trung bình theo ngày
              </div>
              <div className="w-1/4  p-7 text-center flex items-center justify-center">
                Tỷ lệ thay đổi
              </div>
            </div>
            <div className="w-full text-center mb-3">
              <span className="text-2xl mb-3 text-justify">
                {currentRegion.label}
              </span>
            </div>
            <div className="w-full flex justify-center items-center flex-col">
              {currentRegion.provinces &&
                currentRegion.provinces.map((prov) => {
                  return (
                    <div>
                      <span className="uppercase text-red-500 mr-4">
                        {prov?.name}
                      </span>
                      <span className="mr-4">{prov?.total_case}</span>
                      <span>
                        <b>{prov.percent_peak_disease.toFixed(1)}%</b> đỉnh dịch
                      </span>
                    </div>
                  );
                })}
            </div>
            <div></div>
            <div className="flex flex-col justify-center w-full items-center mb-7">
              <span className="text-3xl">COVID-19</span>
              <span className="text-lg">Việt Nam</span>
            </div>
            <div className="mb-7">{/* search */}</div>
            <div className="flex flex-col justify-center w-full items-center mb-7">
              <span>Các tỉnh thành của Việt Nam phân theo 3 miền</span>
              <span>
                ▲ Tỷ lệ ca nhiễm mới trung bình{" "}
                <b className="text-red-500">tăng lên</b> trong 2 tuần vừa qua
              </span>
              <span>
                ▼ Tỷ lệ ca nhiễm mới trung bình{" "}
                <b className="text-green-500">giảm xuống</b> trong 2 tuần vừa
                qua
              </span>
            </div>
          </div>
        </React.Suspense>
      </div>
      {/* Vùng miền */}
      <div className="text-sm bg-white">
        <div className="mb-20">
          <div className="w-full text-center mb-3">
            <span className="text-2xl mb-3 text-justify">Miền Bắc</span>
          </div>
          <div className="flex-wrap flex justify-center">
            {regions.north.map((region) => {
              return <ItemRegion {...region} />;
            })}
          </div>
        </div>
        <div className="mb-20">
          <div className="w-full text-center mb-3">
            <span className="text-2xl mb-3 text-justify">Miền Trung</span>
          </div>
          <div className="flex-wrap flex justify-center">
            {regions.central.map((region) => {
              return <ItemRegion {...region} />;
            })}
          </div>
        </div>
        <div className="mb-20">
          <div className="w-full text-center mb-3">
            <span className="text-2xl mb-3 text-justify">Miền Nam</span>
          </div>
          <div className="flex-wrap flex justify-center">
            {regions.southern.map((region) => {
              return <ItemRegion {...region} />;
            })}
          </div>
        </div>
        <div className="pb-10">
          <div className="w-full flex justify-center mb-7">
            <div className="w-3/4 border-b-2 border-black" />
          </div>
          <div className="w-full flex justify-center mb-7 flex-col items-center">
            <span className="text-lg">Nguồn dữ liệu</span>
            <span className="text-md">Bộ Y Tế</span>
          </div>
          <div className="w-full flex justify-center mb-7 flex-col items-center">
            <span className="text-lg">Phát triển bởi</span>
            <span className="text-md">
              Trung tâm giám sát an toàn không gian mạng quốc gia
            </span>
          </div>
          <div className="w-full flex justify-center mb-7 flex-col items-center px-20 text-center">
            <span className="text-lg">Đội ngũ thực hiện</span>
            <span className="text-md">
              Mai Quang Vinh, Do Quang Anh, Hoang Manh Quan, Le Van Manh, Phan
              Tung Duong, Nguyen Ngoc Thang BB, Nguyen Ngoc Thang DD, Nguyen Thi
              Phuong Anh, Do Anh Tuan, Luu Minh Tri,...
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const ItemRegion = ({ id, name, state }) => {
  return (
    <div className="mr-3 cursor-pointer rounded-md hover:underline hove:font-bold flex items-center">
      <span className="mr-2">{name}</span>
      <div className="text-xs">
        <span>{state === "increase" && "▲"}</span>
        <span>{state === "decrease" && "▼"}</span>
      </div>
    </div>
  );
};
export default Article;
