import React from "react";
import WrapChart from "../graph/WrapChart";

const LoadingChartScreen = () => {
  const fakeCardItem = [1, 2, 3, 4];
  return (
    <div className="">
      <div class="animate-pulse flex flex-col">
        <div class="flex w-full justify-between mb-6 px-2 flex-wrap">
          {fakeCardItem.map(() => {
            return (
              <div class="p-1 xl:w-1/4  w-1/2 ">
                <div className="h-36 bg-gray-200 rounded-lg"></div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-wrap xl:px-3 p-0">
          {fakeCardItem.map(() => (
            <div className="xl:w-1/2 w-full xl:pr-2 mb-3">
              <WrapChart className="h-80">
                <div class="space-y-2 mb-5">
                  <div class="h-4 bg-gray-100 rounded"></div>
                  <div class="h-4 bg-gray-100 rounded w-5/6"></div>
                </div>
                <div class="space-y-2 mb-5">
                  <div class="h-4 bg-gray-100 rounded"></div>
                  <div class="h-4 bg-gray-100 rounded w-5/6"></div>
                </div>
              </WrapChart>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingChartScreen;
