import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  useMap,
} from "react-leaflet";
import React from "react";
import { colorScale, colorScaleTotal, colorScaleVaccine } from "./fillColormap";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { renderToString } from "react-dom/server";
import { listProvincesState } from "../../recoil/atom-provinces";
import { useRecoilState, useRecoilValue } from "recoil";
import ratioData from "../../recoil/atom-ratio";
import { countryByDayState } from "../../recoil/atom-top-country";
import PopupMap from "./PopupMap";
import FilterMap from "./FilterMap";
import countrySelected from "../../recoil/atom-country";
import api from "../../services/api";

const NewLeafLetMapVn = () => {
  const [listProvinces] = useRecoilState(listProvincesState);
  const provinceByDay = useRecoilValue(countryByDayState({ filter: 1 }));
  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const [dataRatio] = useRecoilState(ratioData);
  const [type, setType] = React.useState(2);
  const [zoom, setZoom] = React.useState(5.5);
  const isSovereignty = [
    { label: "Hoàng Sa", latlng: [17.11524313400008, 111.55987251100004] },
    { label: "Trường Sa", latlng: [10.910343714000078, 113.47572430900004] },
    {
      label: "Hà Nội",
      latlng: [21.023694, 105.83559],
    },
    {
      label: "Đà Nẵng",
      latlng: [16.053118, 108.2052],
    },
    {
      label: "Hồ Chí Minh",
      latlng: [10.815068, 106.65832],
    },
    {
      label: "Cần Thơ",
      latlng: [9.969658, 104.1883701],
    },
  ];
  const [dataJsonMap, setDataJsonMap] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const mapDefault = {
    center: [15.4626168, 106.6402231],
    zoom: 5.5,
  };
  const [mapControl, setMapControl] = React.useState(mapDefault);
  React.useEffect(() => {
    api
      .get(
        "https://raw.githubusercontent.com/bad199xqn/map-data/master/diaphantinh_geojson.json"
      )
      .then((res) => {
        setDataJsonMap(res.data);
      });
  }, []);
  React.useEffect(() => {
    !currentCountry.id && setMapControl(mapDefault);
  }, [currentCountry]);
  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  return (
    <div>
      {listProvinces.length === 0 ? (
        <div className="h-screen  flex justify-center items-center">
          <span className="text-lg">Đang tải bản đồ</span>
        </div>
      ) : (
        <MapContainer
          whenReady={() => {
            setLoading(true);
          }}
          {...mapControl}
          scrollWheelZoom={true}
          style={{ height: "90vh", width: "100wh" }}
        >
          <ChangeView {...mapControl} />
          <TileLayer
            attribution="NCSC"
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
          {isSovereignty.map((item) => (
            <Marker
              position={item.latlng}
              icon={L.divIcon({
                className: "my-div-icon",
                html: `<div class="w-20 font-semibold text-gray-500">${item.label}</div>`,
              })}
            />
          ))}
          {dataJsonMap && (
            <GeoJSON
              onEachFeature={(feature, layer) => {
                const currentName = feature.properties.NAME_1;
                const currentId = parseInt(feature.properties.CODE);
                const dataCase = listProvinces.find(
                  (item) => item.id === currentId
                );
                const currentData = dataRatio.find(
                  (item) => item.id === currentId
                );
                const dataNewCase =
                  currentData && Object.values(provinceByDay[currentData.id]);
                const dataVaccine = dataRatio.find(
                  (item) => item.id === currentId
                );
                dataCase &&
                  layer.on("mouseover", function (e) {
                    layer
                      .bindPopup(
                        renderToString(
                          <PopupMap
                            name={currentName}
                            {...dataCase}
                            {...dataVaccine}
                            new_case={
                              dataNewCase[dataNewCase.length - 2] -
                              dataNewCase[dataNewCase.length - 3]
                            }
                            isDetail
                          />
                        )
                      )
                      .openPopup();
                  });
                dataCase &&
                  layer.on("mouseout", function (e) {
                    if (
                      [28, 61, 29, 41, 54, 17, 37, 47, 12, 134].some(
                        (item) => item === dataCase.id
                      ) &&
                      !!dataCase
                    ) {
                      // Sửa lỗi một số tỉnh bị loop event bật tắt popup do geoJson
                      layer
                        .bindPopup(
                          renderToString(
                            <PopupMap
                              name={currentName}
                              {...dataCase}
                              {...dataVaccine}
                              isDetail={!currentCountry.id}
                            />
                          )
                        )
                        .openPopup();
                    } else {
                      this.closePopup();
                    }
                  });
                layer.on("click", function (e) {
                  const latlng = e.latlng;
                  if (currentCountry.id) {
                    setCurrentCountry();
                  }
                  setMapControl({
                    center: [latlng.lat, latlng.lng],
                    zoom: 9,
                  });
                  setCurrentCountry(dataCase);
                });
              }}
              style={(feature) => {
                const currentId = parseInt(feature.properties.CODE);
                let fillColor = "#FAFAF8";
                if (type === 3) {
                  const currentData = dataRatio.find(
                    (item) => item.id === currentId
                  );
                  fillColor = colorScaleVaccine(
                    (100 / currentData?.population) * currentData?.totalInjected
                  );
                } else {
                  const currentData = listProvinces.find(
                    (item) => item.id === currentId
                  );
                  if (type === 1) {
                    fillColor = colorScaleTotal(currentData?.total_case);
                  } else {
                    if (currentData) {
                      const dataNewCase = Object.values(
                        provinceByDay[currentData.id]
                      );
                      fillColor = colorScale(
                        dataNewCase[dataNewCase.length - 2] -
                          dataNewCase[dataNewCase.length - 3]
                      );
                    }
                  }
                }
                return {
                  color: "#fff",
                  opacity: 0.5,
                  weight: 1.5,
                  fillColor: fillColor,
                  fillOpacity: 1,
                };
              }}
              data={dataJsonMap}
            />
          )}
        </MapContainer>
      )}
      <FilterMap
        type={type}
        setType={(newType) => {
          setType(newType);
        }}
      />
    </div>
  );
};

export default NewLeafLetMapVn;
