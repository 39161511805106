const ProgressBar = (props) => {
  const { bgcolor, count, full, cutoff = 35 } = props;

  let bg = "";

  if (bgcolor === "yellow") bg = "#F59E0B";
  if (bgcolor === "purple") bg = "#8B5CF6";
  function bytesToSize(bytes) {
    var sizes = ["", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes < 1000) {
        return bytes + sizes[i];
      } else if (i === 0 && bytes < 10000) return bytes;
      else {
        bytes = parseFloat(bytes / 1000).toFixed(2);
      }
    }
    return 0;
  }

  // console.log(count)
  const containerStyles = {
    position: "relative",
    height: "23px",
    width: "100%",
    //   borderRadius: 20,
  };

  const fillerStyles = {
    height: "100%",
    width: `${(count / full) * 100 || 0}%`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: bg,
  };

  const absoluteStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  };

  const labelStyles = {
    padding: "0 6px",
    fontWeight: "500",
    fontSize: "0.75rem",
    transition: "opacity 1s ease-in-out",
    color: (count / full) * 100 >= cutoff ? "#fff" : bg,
  };

  const getLocaleValue = (count) => {
    if (typeof count === String) {
      return count.toLocaleString("vi-VN");
    } else
      return count.toLocaleString().replace(/[,\.]/g, function (val) {
        return val === "." ? "," : ".";
      });
  };

  return (
    <div style={containerStyles} className="bg-gray-100">
      <div
        style={absoluteStyles}
        className="flex flex-row justify-start overflow-hidden"
      >
        <div style={fillerStyles} className={`bg-${bgcolor}-500`}>
          {(count / full) * 100 >= cutoff && (
            <div className="flex justify-end items-center h-full">
              <div
                style={labelStyles}
                className="text-white"
              >{`${getLocaleValue(bytesToSize(count))}`}</div>
            </div>
          )}
        </div>
        {((count / full) * 100 < cutoff || full == 0) && (
          <div className="flex justify-end items-center h-full">
            <div style={labelStyles} className={`text-${bgcolor}-500`}>{`${
              getLocaleValue(bytesToSize(count)) || 0
            }`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
