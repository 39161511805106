import React from "react";
import { isMobile } from "react-device-detect";
const dataType = [
  {
    title: "Tổng ca nhiễm",
    color: "#EF4444",
    background: "bg-red-400",
  },
  {
    title: "Ca nhiễm mới",
    color: "#F59E0B",
    background: "bg-gray-400",
  },
  {
    title: "Tiến độ tiêm chủng",
    color: "#10B981",
    background: "bg-green-400",
  },
];
const FilterMap = ({ type, setType }) => {
  const [openFilter, setOpenFilter] = React.useState(!isMobile);
  return (
    <div className="filter-map">
      {openFilter ? (
        <div className="bg-white p-3">
          {dataType.map((item, index) => {
            return (
              <div
                className="bg-gray-100 px-3 mb-3 rounded-sm text-center cursor-pointer hover:bg-gray-200"
                style={{
                  borderLeft: `3px solid {item.color}`,
                  backgroundColor: type === index + 1 && item.color,
                  color: type === index + 1 && "white",
                }}
                onClick={() => {
                  setType(index + 1);
                }}
              >
                {item.title}
              </div>
            );
          })}
          {type !== 3 && (
            <div className="flex text-sm flex-col">
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-10"
                  style={{ backgroundColor: "#22C55E" }}
                ></div>
                <div className="text-center">1-100</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-10"
                  style={{ backgroundColor: "#FFA34F" }}
                ></div>
                <div className="text-center">101-500</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-10"
                  style={{ backgroundColor: "#FF392B" }}
                ></div>
                <div className="text-center">501-1000</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-10"
                  style={{ backgroundColor: "#D40638" }}
                ></div>
                <div className="text-center">1.001-10.000</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-10"
                  style={{ backgroundColor: "#5D0000" }}
                ></div>
                <div className="text-center">{">"}10.000</div>
              </div>
            </div>
          )}
          {type === 3 && (
            <div className="flex flex-col text-sm ">
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-20"
                  style={{ backgroundColor: "#D0F0BF" }}
                ></div>
                <div className="text-center">0-30%</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-20"
                  style={{ backgroundColor: "#AAE0B4" }}
                ></div>
                <div className="text-center">30-40%</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-20"
                  style={{ backgroundColor: "#6AC680" }}
                ></div>
                <div className="text-center">40-50%</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-20"
                  style={{ backgroundColor: "#4CA054" }}
                ></div>
                <div className="text-center">50-60%</div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="h-3 w-20"
                  style={{ backgroundColor: "#2E672B" }}
                ></div>
                <div className="text-center">70%+</div>
              </div>
            </div>
          )}
          <div
            className="text-center cursor-pointer"
            onClick={() => {
              setOpenFilter(false);
            }}
          >
            Thu gọn
          </div>
        </div>
      ) : (
        <div
          className="bg-white py-3 rounded-tl-lg rounded-bl-lg cursor-pointer"
          onClick={() => {
            setOpenFilter(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default FilterMap;
