import React from "react";
import iconExpand from "../../assets/icons/icon-expand.svg";
const ExpanedButton = ({ text }) => {
  return (
    <div
      title="Mở rộng biểu đồ"
      className="border px-3 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-lg"
    >
      <span className="mr-2 text-sm">{text}</span>
      <img src={iconExpand} className="w-3 h-3" />
    </div>
  );
};

export default ExpanedButton;
