import React, { useEffect, useState } from "react";
import WrapChart from "./WrapChart";
import TopCards from "./TopCards";
import ChartNewCase from "./ChartNewCase";
import ChartTotalStats from "./ChartTotalStats";
import ChartCompare from "./ChartCompare";
import ChartByProvince from "./ChartByProvince";
import PopupExpaned from "../commons/PopupExpaned";
import ChartCumulativeCase from "./ChartCumulativeCase";
import ChartASEANComparison from "./ChartASEANComparison";
import countrySelected from "../../recoil/atom-country";
import { useRecoilState, useRecoilValue } from "recoil";
import { listProvincesState } from "../../recoil/atom-provinces";
import LoadingChartScreen from "../Loading/loading-chart-screen";
import ProvinceSelector from "../commons/ProvinceSelector";
import InfoPopup from "../commons/InformationPopup";
import totalState from "../../recoil/atom-total";

const ChartContainer = () => {
  const total = useRecoilValue(totalState);
  const selectedProvince = useRecoilValue(countrySelected);
  const [listProvinces] = useRecoilState(listProvincesState);

  const [newest, setNewest] = useState();

  useEffect(() => {
    if (!selectedProvince.id) {
      total?.last_updated && setNewest(total.last_updated);
    } else {
      setNewest(selectedProvince.last_updated);
    }
  }, [total, selectedProvince]);

  document.title = `Ca nhiễm ${
    selectedProvince.id ? selectedProvince.name : "Toàn quốc"
  }`;

  return (
    <div className="w-full justify-center pb-6">
      <div className="pt-5 lg:px-5 px-2 flex flex-col">
        <div className="flex flex-row items-center">
          <span className="text-xl font-medium mr-1">
            Thống kê ca nhiễm:{" "}
            {selectedProvince.id ? selectedProvince.name : "Toàn quốc"}
          </span>
          <div className="pt-1">
            <InfoPopup>
              <div className="text-xs italic px-2 xl:p-0">
                Ghi chú:
                <br />
                Tỷ lệ nhiễm = Số ca nhiễm / Dân số
                <br />
                Tỷ lệ tử vong = Số ca tử vong / Số ca nhiễm
              </div>
            </InfoPopup>
          </div>
        </div>
        <span className="text-xs text-gray-500">
          Nguồn: Bộ Y tế. Cập nhật lần cuối: {newest ? newest : ""}
        </span>
      </div>
      {listProvinces[0] ? (
        <>
          <ProvinceSelector />
          <React.Suspense fallback={<LoadingChartScreen />}>
            <TopCards selectedProvince={selectedProvince} />
            <>
              <div className="flex flex-col w-full  lg:px-5 px-0">
                <div className="flex flex-row  flex-wrap xl:flex-nowrap">
                  <WrapChart
                    className="mb-4 lg:mr-2 mr-0"
                    title="Ca theo ngày"
                    expaned={
                      <PopupExpaned title="Ca theo ngày" textButton="Mở rộng">
                        <ChartNewCase expand />
                      </PopupExpaned>
                    }
                  >
                    <ChartNewCase />
                  </WrapChart>
                  <WrapChart
                    className="mb-4"
                    title="Ca nhiễm cộng dồn"
                    expaned={
                      <PopupExpaned
                        title="Ca nhiễm cộng dồn"
                        textButton="Mở rộng"
                      >
                        <ChartCumulativeCase />
                      </PopupExpaned>
                    }
                  >
                    <ChartCumulativeCase />
                  </WrapChart>
                </div>
                <div className="flex flex-row  flex-wrap xl:flex-nowrap">
                  {!selectedProvince.id && (
                    <>
                      <WrapChart
                        className=" mb-4 lg:mr-2 mr-0"
                        title="Đang điều trị"
                        expaned={
                          <PopupExpaned
                            title="Đang điều trị"
                            textButton="Mở rộng"
                          >
                            <ChartTotalStats
                              title="Đang điều trị"
                              objectKey="active"
                              label="Điều trị"
                              chartColor="orange"
                              noResize
                              // byDay
                            />
                          </PopupExpaned>
                        }
                      >
                        <ChartTotalStats
                          title="Đang điều trị"
                          objectKey="active"
                          label="Điều trị"
                          chartColor="orange"
                          // byDay
                        />
                      </WrapChart>
                      <WrapChart
                        className="mb-4 lg:mr-2 mr-0"
                        title="Đã hồi phục"
                        expaned={
                          <PopupExpaned
                            title="Đã hồi phục"
                            textButton="Mở rộng"
                          >
                            <ChartTotalStats
                              title="Đã hồi phục"
                              objectKey="recovered"
                              label="Hồi phục"
                              chartColor="green"
                              noResize
                              byDay
                            />
                          </PopupExpaned>
                        }
                      >
                        <ChartTotalStats
                          title="Đã hồi phục"
                          objectKey="recovered"
                          label="Hồi phục"
                          chartColor="green"
                          byDay
                        />
                      </WrapChart>
                      <WrapChart
                        className="mb-4"
                        title="Tử vong"
                        expaned={
                          <PopupExpaned title="Tử vong" textButton="Mở rộng">
                            <ChartTotalStats
                              title="Tử vong"
                              objectKey="death"
                              label="Tử vong"
                              chartColor="gray"
                              noResize
                            />
                          </PopupExpaned>
                        }
                      >
                        <ChartTotalStats
                          title="Tử vong"
                          objectKey="death"
                          label="Tử vong"
                          chartColor="gray"
                        />
                      </WrapChart>
                    </>
                  )}
                  {selectedProvince.id && (
                    <WrapChart
                      className="mb-4"
                      title="Tử vong"
                      expaned={
                        <PopupExpaned title="Tử vong" textButton="Mở rộng">
                          <ChartTotalStats
                            title="Tử vong"
                            objectKey="death"
                            label="Tử vong"
                            chartColor="gray"
                            noResize
                          />
                        </PopupExpaned>
                      }
                    >
                      <ChartTotalStats
                        title="Tử vong"
                        objectKey="death"
                        label="Tử vong"
                        chartColor="gray"
                        longChart
                      />
                    </WrapChart>
                  )}
                </div>
              </div>
              <React.Suspense
                fallback={
                  <div className="">
                    <div class="animate-pulse flex flex-col px-3">
                      <div class="h-96 bg-gray-200 rounded-xl mx-2"></div>
                    </div>
                  </div>
                }
              >
                <div className="lg:px-5 px-0">
                  <WrapChart
                    title="Biểu đồ so sánh theo tỉnh thành"
                    expaned={
                      <PopupExpaned
                        title="Biểu đồ so sánh theo tỉnh thành"
                        textButton="Mở rộng"
                      >
                        <ChartCompare />
                      </PopupExpaned>
                    }
                  >
                    <ChartCompare />
                  </WrapChart>
                </div>
              </React.Suspense>
              <div className="lg:px-5 px-0 mt-5">
                <WrapChart
                  title="Bảng so sánh theo tỉnh thành"
                  expaned={
                    <PopupExpaned title="Bảng so sánh theo tỉnh thành">
                      <ChartByProvince maxHeight="80vh" />
                    </PopupExpaned>
                  }
                >
                  <ChartByProvince maxHeight="45.5vh" />
                </WrapChart>
              </div>
              {!selectedProvince.id && (
                <div className="lg:px-5 px-0 mt-5">
                  <WrapChart
                    title="Khu vực Đông Nam Á"
                    expaned={
                      <PopupExpaned title="Khu vực Đông Nam Á">
                        <ChartASEANComparison maxHeight="100%" />
                      </PopupExpaned>
                    }
                    popup={
                      <InfoPopup>
                        <div className="text-xs h-full text-gray-500">
                          Dữ liệu cập nhật từ WorldOMeter và có thể chậm hơn so
                          với thực tế.
                          <br />
                        </div>
                      </InfoPopup>
                    }
                  >
                    <ChartASEANComparison maxHeight="45.5vh" />
                  </WrapChart>
                </div>
              )}
            </>
          </React.Suspense>
        </>
      ) : (
        <LoadingChartScreen />
      )}
    </div>
  );
};

export default ChartContainer;
