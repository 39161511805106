import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import ratioData from "../../recoil/atom-ratio";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import ProgressQA from "../commons/ProgressQA";

const ChartVaccinceOfCity = () => {
  const [dataVaccine] = useRecoilState(ratioData);
  const [data, setData] = useState();

  const [vaccine, setVaccine] = useState(dataVaccine);

  console.log(vaccine,"vaccine")

  const [search, setSearch] = useState("");

  const filterProvince = ({ name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataVaccine.length > 0) {
      setLoading(true);
      setVaccine(dataVaccine);
    }
    setData(dataVaccine);
  }, []);
  function bytesToSize(bytes) {
    var sizes = ["", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes < 1000) {
        return bytes + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1000).toFixed(2);
      }
    }
    return 0;
  }
  const labels = [
    {
      label: "Tỉnh/Thành phố",
      key: "name",
      percent: false,
      color: false,
      borderLeft: false,
    },
    {
      label: "Phân bổ dự kiến",
      key: "totalVaccineAllocated",
      percent: false,
      color: false,
      borderLeft: false,
    },
    {
      label: "Phân bổ thực tế",
      key: "totalVaccineAllocatedReality",
      percent: false,
      color: false,
      borderLeft: false,
    },

    {
      label: "Số liều đã tiêm",
      key: "totalInjected",
      percent: false,
      color: false,
      borderLeft: false,
    },
    {
      label: "Tiêm 1 mũi",
      key: "totalOnceInjected",
      percent: true,
      color: true,
      borderLeft: true,
    },

    {
      label: "Đã phân bổ ",
      key: "totalVaccineAllocatedReality",
      percent: true,
      color: true,
      borderLeft: false,
    },
    {
      label: "Kế hoạch phân bổ",
      key: "totalVaccineAllocated",
      percent: true,
      color: true,
      borderLeft: false,
    },
    {
      label: "Dân số >= 18 tuổi",
      key: "popOverEighteen",
      percent: false,
      color: true,
      borderLeft: false,
    },
  ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  const handleSort = (itemKey, index, percent) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index, percent);
    else sortByKey(itemKey, 1, index, percent);
  };

  const sortByKey = (objectKey, direction, index = -1, percent) => {
    let sorted;

    if (objectKey === "name") {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (direction === 1) return a[objectKey].localeCompare(b[objectKey]);
        else return -a[objectKey].localeCompare(b[objectKey]);
      });
    } else if (!percent) {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (a[objectKey] > b[objectKey]) return direction * -1;
        else if (a[objectKey] < b[objectKey]) return direction;
        else return 0;
      });
    } else {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (
          a[objectKey] / (a.popOverEighteen * 2) >
          b[objectKey] / (b.popOverEighteen * 2)
        )
          return direction * -1;
        else if (
          a[objectKey] / (a.popOverEighteen * 2) <
          b[objectKey] / (b.popOverEighteen * 2)
        )
          return direction;
        else return 0;
      });
    }

    // console.log(sorted);

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setVaccine([...sorted]);
  };

  return (
    <>
      <div class="grid grid-cols-3 gap-4">
        <div className="w-2/4 flex items-center border rounded-lg mb-3">
          <input
            className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none rounded"
            id="search"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Tìm kiếm"
          />
        </div>
        <div></div>
        <div class="font-bold px-5">Tỉ lệ dân số từ 18 tuổi</div>
      </div>
      <div
        className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative"
        style={{ height: "59vh" }}
      >
        <table className="table w-full border-collapse">
          <thead className="border-b border-black sticky top-0 z-10 bg-white">
            <tr className="p-2 border-b  border-black">
              {labels.map((item, index) => (
                <th
                  className={`  text-center text-xs cursor-pointer p-2 font-bold ${
                    item.color ? "bg-gray-50" : ""
                  } `}
                  style={
                    item.borderLeft ? { borderLeft: "1px solid lightgray" } : {}
                  }
                  scope="col"
                  onClick={() => handleSort(item.key, index, item.percent)}
                >
                  <span className="flex flex-row justify-center items-center">
                    {item.label}
                    {sortArray[index] !== 0 ? (
                      <img
                        src={sortArray[index] === 1 ? descend : ascend}
                        className="ml-1 w-4 h-4"
                      />
                    ) : (
                      <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {vaccine &&
              vaccine.filter(filterProvince).map((item, idx) => {
                return (
                  <>
                    <tr className="border-b text-center font-medium ">
                      <td
                        className="px-3 py-3 whitespace-nowrap font-bold w-1/8 "
                        style={{ fontSize: "15.5px" }}
                      >
                        {item.name}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap w-1/8 ">
                        {bytesToSize(item.totalVaccineAllocated).replace(
                          /\./g,
                          ","
                        )}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap font-xs w-1/8">
                        {bytesToSize(item.totalVaccineAllocatedReality).replace(
                          /\./g,
                          ","
                        )}
                      </td>

                      <td className="px-3 py-3 whitespace-nowrap w-1/8">
                        {bytesToSize(item.totalInjected).replace(/\./g, ",")}
                      </td>
                      <td
                        className={`px-3 py-3 whitespace-nowrap bg-gray-50 w-1/8 `}
                        style={{ borderLeft: "1px solid lightgray" }}
                      >
                        <ProgressQA
                          selected={0}
                          key={idx}
                          // count={item.totalInjected}
                          bgcolor="#01662a"
                          percent={(
                            (item.totalInjected / item.popOverEighteen) *
                            100
                          ).toFixed(2)}
                        />
                      </td>

                      <td className="px-3 py-3 whitespace-nowrap bg-gray-50 w-1/8">
                        <ProgressQA
                          selected={0}
                          key={idx}
                          percent={(
                            (item.totalVaccineAllocatedReality /
                              (item.popOverEighteen * 2)) *
                            100
                          ).toFixed(2)}
                          bgcolor="#1b9442"
                          // full={}
                        />
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap bg-gray-50 w-1/8">
                        <ProgressQA
                          selected={0}
                          // percent={item.totalInjected}
                          key={idx}
                          bgcolor="#aae0b4"
                          percent={(
                            (item.totalVaccineAllocated /
                              (item.popOverEighteen * 2)) *
                            100
                          ).toFixed(2)}
                        />
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap font-medium bg-gray-50 w-1/8">
                        {bytesToSize(item.popOverEighteen).replace(/\./g, ",")}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <small class="pl-2 text-sm text-gray-500  mt-1">Dữ liệu dân số: Tổng cục thống kê, dân số Việt Nam năm 2019 </small>
    </>
  );
};

export default ChartVaccinceOfCity;