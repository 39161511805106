import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log(error);
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="hover:p-4">
          <div className="h-screen w-full flex justify-center items-center  flex-col">
            <span className="text-lg font-bold text-gray-500 mb-3">
              Hệ thống đang xảy ra lỗi kết nối.
            </span>
            <div
              onClick={() => {
                window.location.reload();
              }}
              className="rounded-lg bg-blue-600 p-3 text-white cursor-pointer"
            >
              Thử lại
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
