/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DateRange } from "react-date-range";
import ButtonSelectInput from "./ButtonSelectInput";
import dayjs from 'dayjs';

const PopupTime = ({
  active = false,
  title = "Chọn theo giai đoạn",
  newestDate = dayjs(),
  textButton = "Tùy chọn",
  onSave = () => { },
}) => {
  const [open, setOpen] = useState(false);
  const [dateTime, setDateTime] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [selectedPhase, setSelectedPhase] = useState(0);
  const cancelButtonRef = useRef(null);

  const phaseOptions = [
    {
      value: 0,
      label: "Giai đoạn 1",
      startDate: "2020/01/23",
      endDate: "2020/07/24"
    },
    {
      value: 1,
      label: "Giai đoạn 2",
      startDate: "2020/07/25",
      endDate: "2021/01/27"
    },
    {
      value: 2,
      label: "Giai đoạn 3",
      startDate: "2021/01/28",
      endDate: "2021/04/26"
    },
    {
      value: 3,
      label: "Giai đoạn 4",
      startDate: "2021/04/27",
      endDate: new Date()
    },
  ];

  
  const formatDate = (range) => {
    let start = dayjs(range[0].startDate);
    let end = range[0].endDate ? dayjs(range[0].endDate) : dayjs();

    let startDiff = newestDate.diff(start, 'day') + 1;
    let endDiff = newestDate.diff(end, 'day');

    return {
      start: startDiff > 0 ? startDiff : 0,
      end: endDiff > 0 ? endDiff : 0
    }
  }

  const handleSelectPhase = (phase) => {
    let phaseDate = [{
      startDate: new Date(phaseOptions[phase].startDate),
      endDate: new Date(phaseOptions[phase].endDate),
      key: "selection"
    }]
    setDateTime(phaseDate);
  }

  return (
    <div class="h-full">
      <div
        className={"flex rounded h-full px-2 py-1 border-1 text-xs font-medium mr-1 cursor-pointer "
        + (active ? "bg-gray-500 text-white" : "bg-gray-200")}
        onClick={() => setOpen(true)}
      >
        {textButton}{" "}
      </div>
      <Transition.Root show={open} as={Fragment} style={{ zIndex: 1000 }}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="rounded-lg bg-white  sm:my-8   absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="p-5 text-lg font-bold">
                  <div className="flex justify-between">
                    <h3>{title}</h3>
                    <div
                      title="Đóng"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      className="w-7 h-7 cursor-pointer bg-gray-400 flex justify-center items-center text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                  Chức năng này sẽ được cập nhật sắp tới
                </span> */}
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      setDateTime([item.selection])
                      setSelectedPhase(-1);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={dateTime}
                  />
                  <div className="py-3">
                    <ButtonSelectInput
                      title=""
                      options={phaseOptions}
                      value={selectedPhase}
                      onChange={(value) => {
                        setSelectedPhase(value);
                        handleSelectPhase(value);
                      }}
                    />
                  </div>
                  <button
                    class="transition duration-200 bg-gray-500 text-white hover:bg-gray-300 hover:text-gray-600 font-bold py-2 px-4 rounded"
                    onClick={() => {
                      onSave(formatDate(dateTime));
                      setOpen(false);
                    } }
                  >
                    Lưu
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
export default PopupTime;
