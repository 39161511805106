const validateSlice = (data, range) => {
  if (range.start - 1 === range.end || range.start > data.length) {
    return false;
  }
  return true;
}

const sliceChart = (data, range) => {
  if (range.start === 0) return data;
  return data.slice(data.length - range.start, data.length - range.end);
}

export { validateSlice, sliceChart };