import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import countrySelected from '../../recoil/atom-country';
import currentProvinceState from '../../recoil/atom-current-province';
import totalState from '../../recoil/atom-total';
import SmallSelect from '../commons/SelectInputSmall';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PopupTime from '../commons/PopupTime';
import { sliceChart, validateSlice } from '../../services/chartRange';

const ChartTotalStats = ({
  objectKey,
  label,
  chartColor,
  leftSelectedDate = 60,
  onLeftBar = false,
  longChart = false,
  byDay = false
}) => {
  const [total] = useRecoilState(totalState);
  const [selectedDate, setSelectedDate] = useState({ active: leftSelectedDate, start: leftSelectedDate, end: 0 });
  const [selectedType, setSelectedType] = useState(byDay ? 1 : 0);
  const [fullKey, setFullKey] = useState(byDay ? objectKey + "_by_day" : objectKey + '_by_time');

  const selectedProvince = useRecoilValue(countrySelected);
  const province = useRecoilValue(currentProvinceState({ province: selectedProvince.id }));
  const height = 200;

  const [data, setData] = useState();
  const [color, setColor] = useState({ light: "#FFEDD5", dark: "#FB923C", line: "#F97316" });

  useEffect(() => {
    setSelectedDate({ active: leftSelectedDate, start: leftSelectedDate, end: 0 });
  }, [leftSelectedDate]);

  useEffect(() => {
    chartColor === "orange" && setColor({ light: "#FFEDD5", dark: "#FB923C", line: "#F97316" });
    chartColor === "green" && setColor({ light: "#DCFCE7", dark: "#4ADE80", line: "#22C55E" });
    chartColor === "gray" && setColor({ light: "#F3F4F6", dark: "#9CA3AF", line: "#6B7280" });
  }, [])

  useEffect(() => {
    selectedProvince.id ? (province.total_case > -1 && setData(province)) : setData(total);
  }, [selectedProvince, total, province])

  let options = {
    locale: 'vi-VN',
    maintainAspectRatio: false,
    aspectRatio: longChart ? 1.8 : 1.2,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
        },
        grid: {
          display: true,
          drawBorder: false,
        }
      },
      x: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        }
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        align: selectedDate !== 7 ? 'left' : 'top',
        anchor: 'end',
        padding: selectedDate !== 7 ? 0 : -10,
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
        // textShadowColor: 'rgba(255,255,255,1)',
        // textShadowBlur: 4,
        display: onLeftBar ? false : function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return (index === data.length - 1);
        },
        formatter: function (value) {
          if (!value) return 0;
          else return (value.y || value.y === 0) ? value.y.toLocaleString('vi-VN') + '\n' : value.toLocaleString('vi-VN') + '\n';
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
  };

  const getMeanLine = (fullData, label) => {
    const _fullData = [...fullData];

    let dataset = [];
    let averageData = [];

    for (let i = 0; i < fullData.length; i++) {
      let average = 0;

      if (i >= 6) {
        let week = _fullData.slice(i - 6, i + 1);
        average = Math.floor(week.reduce((a, b) => a + b) / 7);
      }

      averageData.push(average);
    }

    averageData = sliceChart(averageData, selectedDate);

    label.forEach((item, index) => {
      dataset.push({ x: item, y: averageData[index] });
    });

    return dataset;
  };


  const dateOptions = [
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const typeOptions = [
    {
      value: 0,
      label: "Cộng dồn",
    },
    {
      value: 1,
      label: "Theo ngày",
    },
  ];

  const totalStats = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, color.dark); //#ff6a07
    gradient.addColorStop(1, color.light); //#fcf2e9

    let fullDataChart = data[fullKey] ? Object.values(data[fullKey]) : [];

    if (!validateSlice(fullDataChart, selectedDate)) {
      setSelectedDate({ active: 60, start: 60, end: 0 });
    }

    let dataChart = sliceChart(fullDataChart, selectedDate);

    let unsplitted = (total[fullKey] && Object.keys(total[fullKey])) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _label = unsplitted.map((day) => {
      let split = day.split("/");
      return parseInt(split[0]) + "/" + parseInt(split[1]);
    })

    let meanLine = getMeanLine(fullDataChart, _label);

    if (selectedType === 0)
      return {
        labels: _label || [],
        datasets: [
          {
            label: label,
            backgroundColor: gradient, // Put the gradient here as a fill color
            borderColor: color.line,
            borderWidth: 1,
            pointColor: "#fff",
            pointRadius: 0,
            fill: "start",
            backgroundColor: gradient,
            pointStrokeColor: color.line,
            pointHighlightFill: "#fff",
            pointHighlightStroke: color.line,
            data: dataChart,
          },
        ],
      };
    else
      return {
        labels: _label || [],
        datasets: [
          {
            type: "line",
            label: " Trung bình 7 ngày",
            backgroundColor: "red",
            fill: false,
            borderColor: "red",
            pointColor: "#fff",
            pointRadius: 0,
            borderWidth: 1,
            pointColor: "red",
            pointStrokeColor: "red",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "red",
            data: meanLine,
          },
          {
            label: label,
            backgroundColor: color.dark, // Put the gradient here as a fill color
            borderColor: color.line,
            borderWidth: 0,
            pointColor: "#fff",
            pointRadius: 0,
            pointStrokeColor: color.line,
            pointHighlightFill: "#fff",
            pointHighlightStroke: color.line,
            data: dataChart,
          },
        ],
      };
  };

  return (
    <div className="flex flex-col justify-center">
      <>
        {!onLeftBar &&
          <div className="mb-3 flex flex-row justify-start items-center h-full">
            <div className="flex pr-1">
              <SmallSelect
                title=""
                options={dateOptions}
                value={selectedDate.active}
                active={selectedDate.active !== -1}
                onChange={(value) => {
                  setSelectedDate({ active: value, start: value, end: 0 });
                }}
              />
            </div>
            <div className="flex pr-1">
              <SmallSelect
                title=""
                options={typeOptions}
                value={selectedType}
                onChange={(value) => {
                  setSelectedType(value);
                  value === 0 ? setFullKey(objectKey + '_by_time') : setFullKey(objectKey + '_by_day');
                }}
              />
            </div>
            <PopupTime
              active={selectedDate.active === -1}
              onSave={(range) => {
                setSelectedDate({ active: -1, ...range })
              }}
            />
          </div>
        }
        <div className="" style={{maxHeight: '50vh'}}>
          {selectedType === 0
            ?
            <Line width={null} height={null} plugins={[ChartDataLabels]} data={totalStats} options={options} />
            :
            <Bar width={null} height={null} plugins={[ChartDataLabels]} data={totalStats} options={options} />
          }
        </div>
      </>

    </div>
  );
};

export default React.memo(ChartTotalStats);