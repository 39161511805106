import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import countrySelected from '../../recoil/atom-country';
import { quarantineProvinceState } from '../../recoil/atom-quarantine';
import SmallSelect from '../commons/SelectInputSmall';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const ChartByStat = ({ data, popup = false }) => {
  const selectedProvince = useRecoilValue(countrySelected);
  const provinceQuarantine = useRecoilValue(quarantineProvinceState({ province: selectedProvince.id }))
  const [selectedDate, setSelectedDate] = useState(60);

  const objectKey = data.objectKey + "_percent_change_from_baseline";

  const dateOptions = [
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const chartOptions = {
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 5,
        },
        min: -100,
        max: 100,
        grid: {
          display: true,
          drawBorder: false,
        }
      },
      x: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        }
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return context.dataset.label + ": "+ context.parsed.y + "%";
          }
        }
      },
      legend: {
        display: false,
      },
      datalabels: {
        align: 'left',
        anchor: 'end',
        padding: 2,
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
        textShadowColor: 'rgba(255,255,255,1)',
        textShadowBlur: 4,
        display: function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return (index === data.length - 1);
        },
        formatter: function (value) {
          return (value >= 0 ? "+" : "") + value.toLocaleString("vi-VN") + "%" + "\n";
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
  };

  const getLabelArray = (dataArray) => {
    let result = dataArray.map((date) => {
      let split = date.split("-");
      return parseInt(split[2]).toString() + "/" + parseInt(split[1]).toString();
    })
    return result;
  }

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, data.color.light); //#ff6a07
    gradient.addColorStop(1, data.color.dark); //#fcf2e9

    const dataset = Object.values(provinceQuarantine[objectKey]);


    const slicedDataset = selectedDate === 0 ? dataset :
      dataset.slice(dataset.length - selectedDate, dataset.length);

    const labels = selectedDate === 0 ? Object.keys(provinceQuarantine[objectKey]) :
    Object.keys(provinceQuarantine[objectKey]).slice(dataset.length - selectedDate, dataset.length);

    const newLabels = getLabelArray(labels);

    return {
      labels: newLabels || [],
      datasets: [
        {
          label: data.label,
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: data.color.line,
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          fill: "origin",
          backgroundColor: gradient,
          pointStrokeColor: data.color.line,
          pointHighlightFill: "#fff",
          pointHighlightStroke: data.color.line,
          data: slicedDataset,
        }
      ]
    }
  }

  return (
    <div className="flex flex-col justify-center">
      {provinceQuarantine ?
        <>
          <div className="mb-3 flex flex-row">
            <div className="pr-1">
              <SmallSelect
                title=""
                options={dateOptions}
                value={selectedDate}
                onChange={(value) => {
                  setSelectedDate(value);
                }}
              />
            </div>
            {/* <PopupTime /> */}
          </div>
          <Line width={popup ? 320 : 250} height={150} data={chartData} plugins={[ChartDataLabels]} options={chartOptions} />
        </>
        :
        <></>
      }

    </div>
  );
};

export default ChartByStat;