import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import ratioData from "../../recoil/atom-ratio";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import ProgressQA from "./../commons/ProgressQA";
import ButtonSelectInput from "../commons/ButtonSelectInput";

const ChartVaccine = () => {
  const [dataVaccine] = useRecoilState(ratioData);
  const [vaccine, setVaccine] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(2);
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const filterProvince = ({ name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  useEffect(() => {
    if (dataVaccine.length > 0) {
      setLoading(true);
      setVaccine(dataVaccine);
    }
    setData(dataVaccine);
  }, [dataVaccine]);
  // console.log(dataVaccine)
  const handleChange = (e) => {
    // console.log(e.target.value);
    // setSelected(parseInt(e.target.value));
    setSelected(e);
  };

  const labelsPeople = [
    { label: "Tỉnh thành", key: "name", people: false },
    { label: "Số người đã tiêm", key: "totalInjected", people: true },
    {label: "Số mũi đã về",key: "totalVaccineAllocatedReality",people: true,
    },
  ];

  const labelsEightTeen = [
    { label: "Tỉnh thành", key: "name", people: false },
    { label: "Số người đã tiêm", key: "totalOnceInjected", people: false },
    {label: "Số mũi đã về",key: "totalVaccineAllocatedReality",people: false},
  ];

  const labels = [
    { label: "Tỉnh thành", key: "name",percent:false },
    { label: "Số người đã tiêm", key: "totalInjected",percent:false },
    { label: "Số mũi đã về", key: "totalVaccineAllocatedReality",percent:false },
  ];

  const [sortArray1, setSort1] = useState([0, 0, 0]);
  const [sortArray, setSort] = useState([0, 0, 0]);
  const [sortArray2, setSort2] = useState([0, 0, 0])

  const handleSort = (itemKey, index, percent) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index, percent);
    else sortByKey(itemKey, 1, index, percent);
  };

  const sortByKey = (objectKey, direction, index = -1, percent) => {
    let sorted;

    if (objectKey === "name") {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (direction === 1) return a[objectKey].localeCompare(b[objectKey]);
        else return -a[objectKey].localeCompare(b[objectKey]);
      });
    } else if (!percent) {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (a[objectKey] > b[objectKey]) return direction * -1;
        else if (a[objectKey] < b[objectKey]) return direction;
        else return 0;
      });
    } 

    // console.log(sorted);

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setVaccine([...sorted]);
  };

  
  
  const handleSort1 = (itemKey, index, people) => {
    let direction = sortArray1[index];
    if (direction === 1) sortByKey1(itemKey, -1, index, people);
    else sortByKey1(itemKey, 1, index, people);
  };

  const sortByKey1 = (objectKey, direction, index = -1, people) => {
    let sorted;

    if (objectKey === "name") {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (direction === 1) return a[objectKey].localeCompare(b[objectKey]);
        else return -a[objectKey].localeCompare(b[objectKey]);
      });
    } 
   
    else   {
      sorted = [...dataVaccine].sort(function (a, b) {
        if ((a[objectKey] / a.popOverEighteen) > (b[objectKey] / b.popOverEighteen)) 
          return direction * -1;
        else if ((a[objectKey] / a.popOverEighteen) < (b[objectKey] / b.popOverEighteen)) 
          return direction;
        else return 0;
      });
    }
    

    // console.log(sorted);

    if (index > -1) {
      let newSort = [...sortArray1].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort1(newSort);
    }

    setVaccine([...sorted]);
  };




    
  const handleSort2 = (itemKey, index, people) => {
    let direction = sortArray2[index];
    if (direction === 1) sortByKey2(itemKey, -1, index, people);
    else sortByKey2(itemKey, 1, index, people);
  };

  const sortByKey2 = (objectKey, direction, index = -1, people) => {
    let sorted;

    if (objectKey === "name") {
      sorted = [...dataVaccine].sort(function (a, b) {
        if (direction === 1) return a[objectKey].localeCompare(b[objectKey]);
        else return -a[objectKey].localeCompare(b[objectKey]);
      });
    } 
   
    else   {
      sorted = [...dataVaccine].sort(function (a, b) {
        if ((a[objectKey] / a.population) > (b[objectKey] / b.population)) 
          return direction * -1;
        else if ((a[objectKey] / a.population) < (b[objectKey] / b.population)) 
          return direction;
        else return 0;
      });
    }
    

    // console.log(sorted);

    if (index > -1) {
      let newSort2 = [...sortArray2].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort2(newSort2);
    }

    setVaccine([...sorted]);
  };



  const getMax = (objectKey, index) => {
    let sorted = Object.values(dataVaccine).sort(function (a, b) {
      if (parseInt(a[objectKey]) > parseInt(b[objectKey])) return -1;
      else if (parseInt(a[objectKey]) < parseInt(b[objectKey])) return 1;
      else return 0;
    });

    return sorted[0][objectKey] * 1.25;
  };

  const selectOptions = [
    { value: 0, label: "% dân số" },
    { value: 1, label: "% dân số từ 18 tuổi" },
    { value: 2, label: "Số tuyệt đối" }
  ]

  return (
    <div>
      <div>
        <div class="mb-2">
        <ButtonSelectInput
            title=""
            options={selectOptions}
            value={selected}
            onChange={(value) => {
              handleChange(value);
            }}
          />
        </div>
    
        <div className=" items-center rounded-lg mb-3">
          <input
            className="w-full py-2 px-4 text-gray-700 text-sm border  leading-tight focus:outline-none rounded mr-6"
            id="search"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Tìm kiếm"
          />
          
          
        </div>
      </div>
      {/* {selected === 0 && ( */}
      <div
        className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative"
        style={{ height: "48vh" }}
      >
        <table className="table w-full border-collapse">
          <thead className="border-b border-black sticky top-0 z-10 bg-white">
            <tr className="p-2 border-b border-black">
              {selected === 0 && (
                <>
                  {" "}
                  {labelsEightTeen.map((item, index) => (
                    <th
                      className="w-1/6 p-2 text-xs font-medium text-center cursor-pointer"
                      scope="col"
                      onClick={() =>
                        handleSort2(item.key, index, item.people)
                      }
                    >
                      <div className="w-full text-center flex flex-row justify-center items-center">
                        {item.label}
                        <span>
                          {sortArray1[index] !== 0 ? (
                            <img
                              src={sortArray1[index] === 1 ? descend : ascend}
                              className="ml-1 w-4 h-4"
                            />
                          ) : (
                            <img
                              src={noSort}
                              className="ml-1 w-4 h-4 opacity-20"
                            />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </>
              )}
              {selected === 1 && (
                <>
                  {" "}
                  {labelsPeople.map((item, index) => (
                    <th
                      className="w-1/6 p-2 text-xs font-medium text-center cursor-pointer"
                      scope="col"
                      onClick={() => handleSort1(item.key, index, item.people)}
                    >
                      <div className="w-full text-center flex flex-row justify-center items-center">
                        {item.label}
                        <span>
                          {sortArray1[index] !== 0 ? (
                            <img
                              src={sortArray1[index] === 1 ? descend : ascend}
                              className="ml-1 w-4 h-4"
                            />
                          ) : (
                            <img
                              src={noSort}
                              className="ml-1 w-4 h-4 opacity-20"
                            />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </>
              )}
              {selected === 2 && (
                <>
                  {" "}
                  {labels.map((item, index) => (
                    <th
                      className="w-1/6 p-2 text-xs font-medium text-center cursor-pointer"
                      scope="col"
                      onClick={() => handleSort(item.key, index, item.percent)}
                    >
                      <div className="w-full text-center flex flex-row justify-center items-center">
                        {item.label}
                        <span>
                          {sortArray[index] !== 0 ? (
                            <img
                              src={sortArray[index] === 1 ? descend : ascend}
                              className="ml-1 w-4 h-4"
                            />
                          ) : (
                            <img
                              src={noSort}
                              className="ml-1 w-4 h-4 opacity-20"
                            />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </>
              )}
            </tr>
          </thead>

          {selected === 0 && (
            <tbody>
              {vaccine &&
                vaccine.filter(filterProvince).map((item, idx) => {
                  return (
                    <>
                      <tr className="border-b">
                        <td
                          className="font-bold p-2"
                          style={{ fontSize: "13px" }}
                        >
                          {item.name}
                        </td>

                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={selected}
                            percent={(
                              (item.totalOnceInjected / item.population) *100
                            ).toFixed(2)}
                          // full={getMax("totalOnceInjected")}
                          />
                        </td>
                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={selected}
                            percent={(
                              (item.totalVaccineAllocatedReality / item.population) * 100
                            ).toFixed(2)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          )}

          {selected === 1 && (
            <tbody>
              {vaccine &&
                vaccine.filter(filterProvince).map((item, idx) => {
                
                  return (
                    <>
                      <tr className="border-b">
                        <td
                          className="font-bold p-2"
                          style={{ fontSize: "13px" }}
                        >
                          {item.name}
                        </td>
                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={0}
                            percent={(
                              (item.totalInjected / item.popOverEighteen) *100
                            ).toFixed(2)}
                            // full={getMax("totalOnceInjected")}
                            bgcolor="green-500"
                          />

                        </td>
                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={0}
                            percent={(
                              (item.totalVaccineAllocatedReality /item.popOverEighteen) *100
                            ).toFixed(2)}
                            // full={getMax("totalOnceInjected")}
                            bgcolor="green-800"
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          )}
          {selected === 2 && (
            <tbody>
              {vaccine &&
                vaccine.filter(filterProvince).map((item, idx) => {
                  return (
                    <>
                      <tr className="border-b">
                        <td
                          className="font-bold p-2"
                          style={{ fontSize: "13px" }}
                        >
                          {item.name}
                        </td>
                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={1}
                            count={item["totalInjected"]}
                            full={getMax("popOverEighteen")}
                            bgcolor="green-500"
                          />
                        </td>
                        <td className="font-weight-bold p-2">
                          <ProgressQA
                            selected={1}
                            count={item["totalVaccineAllocatedReality"]}
                            full={getMax("popOverEighteen")}
                            bgcolor="green-800"
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>
      <small class="pl-2 text-sm text-gray-500  mt-1">Dữ liệu dân số: Tổng cục thống kê, dân số Việt Nam năm 2019 </small>
    </div>
  );
};

export default ChartVaccine;
