import React, { useEffect, useState } from "react";
import ChartVaccine from "./ChartVaccine";
import ChartVaccineByDay from "./ChartVaccineByDay";
import WrapChart from "../graph/WrapChart";
import ChartVaccineByTime from "./ChartVaccineByTime";
import MapTiemChung from "../map/MapTiemChung";
import PopupExpaned from "./../commons/PopupExpaned";
import CardVaccine from "./CardVaccine";
import { useRecoilState } from "recoil";
import {
  dataByDayOnceVaccine,
  dataByDayTwiceVaccine,
  dataByDayVaccine,
  overViewTotalVaccine,
  overViewVaccineDay,
  overViewVaccineTime,
  overViewVaccineTotal,
  overViewVaccineType,
  overViewVaccineTypeByMonth,
} from "../../recoil/atom-overviewvaccine";
import api from "./../../services/api";
import MapTotalVaccineAllocated from "./../map/MapTotalVaccineAllocated";
import MapTotalVaccineAllocatedReality from "./../map/MapTotalVaccineAllocatedReality";
import ChartVaccinceOfCity from "./ChartVaccinceOfCity";
import ChartCompareAsiaVaccine from "./ChartCompareAsiaVaccine";
import ButtonSelectInput from "./../commons/ButtonSelectInput";
import countrySelected from "../../recoil/atom-country";
import { listProvincesState } from "../../recoil/atom-provinces";
import ProvinceSelector from "../commons/ProvinceSelector";
import VaccineType from "./VaccineType";
import ChartProjection from "./ChartProjection";
import VaccineTypeByTime from "./VaccineTypeByTime";
import InfoPopup from './../commons/InformationPopup';
import dayjs from "dayjs";

const VaccinationIndex = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [, setDataOverViewVaccineDay] =
    useRecoilState(overViewVaccineDay);
  const [, setDataOverViewVaccineTime] =
    useRecoilState(overViewVaccineTime);
  const [dataTimeVaccineProvince, setDataTimeVaccineProvince] =
    useRecoilState(dataByDayVaccine);
  const [dataTimeOnceVaccineProvince, setDataTimeOnceVaccineProvince] =
    useRecoilState(dataByDayOnceVaccine);
  const [dataTimeTwiceVaccineProvince, setDataTimeTwiceVaccineProvince] =
    useRecoilState(dataByDayTwiceVaccine);
  const [, setDataVaccineType] =
    useRecoilState(overViewVaccineType);
  const [, setDataVaccineTypeByMonth] =
    useRecoilState(overViewVaccineTypeByMonth);
  const [, setDataVaccineTotal] =
    useRecoilState(overViewVaccineTotal);
  const [listProvinces] = useRecoilState(listProvincesState);
  const [selectedProvince] =
    useRecoilState(countrySelected);
    const [, setTotalVaccine] =
    useRecoilState(overViewTotalVaccine);
  useEffect(() => {
    api
      .get("/vaccine/national_injected_by_day?filter_type=people")
      .then((success) => {
        setDataOverViewVaccineDay(success.data);
      });
    api
      .get("/vaccine/national_injected_by_time?filter_type=people")
      .then((success) => {
        setDataOverViewVaccineTime(success.data);
      });
    // api
    //   .get("/vaccine/provinces/by_day?filter_type=province_total_injected")
    //   .then((success) => {
    //     setDataTimeVaccineProvince(success.data);
    //   });
    // api
    //   .get("/vaccine/provinces/by_day?filter_type=province_once_injected")
    //   .then((success) => {
    //     setDataTimeOnceVaccineProvince(success.data);
    //   });
    // api
    //   .get("/vaccine/provinces/by_day?filter_type=province_twice_injected")
    //   .then((success) => {
    //     setDataTimeTwiceVaccineProvince(success.data);
    //   });
    api.get("/vaccine/national_vaccine").then((success)=>{
      setTotalVaccine(success.data)
    
    })
    api
      .get("/vaccine/national_summary")
      .then((success) => {
        setDataVaccineTotal(success.data);
      })
    api.get("/vaccine/vaccine_import_by_type").then((success) => {
      setDataVaccineType(success.data);
    });
    api.get("/vaccine/vaccine_imported_by_month").then((success) => {
      setDataVaccineTypeByMonth(success.data);
    });
  }, []);

  useEffect(() => {
    return listProvinces.find((item) => item.id === selectedProvince);
  }, [selectedProvince]);

  const dateOptions = [
    {
      value: 0,
      label: "Đã tiêm",
    },
    {
      value: 1,
      label: "Kế hoạch phân bổ",
    },
    {
      value: 2,
      label: "Tiến độ phân bổ",
    },
  ];

  document.title = "Số liệu tiêm chủng";
  return (
    <>
      <div className="w-full d-flex flex-column justify-center pb-6">
        <div className="pt-5 xl:px-5 px-2 flex-container">
        <div className="flex flex-row items-center">
          <span className="text-xl font-medium">
        Thống kê tiêm chủng:{" "}
            {selectedProvince.id ? selectedProvince.name : "Toàn quốc"} 
          
          </span>
          <div className="pt-1">
            <InfoPopup>
              <div className="text-xs italic px-2 xl:p-0">
              Ghi chú: Tổng người đã tiêm = Số người đã tiêm 1 mũi + Số người đã
            tiêm 2 mũi; 
            <br />
            Mục tiêu Vaccine = Số Vaccine thực tế đã về / Mục tiêu
            đặt ra; 
            <br/> 
            Mục tiêu Vaccine toàn quốc: 150 triệu liều;
            <br/>
            <b>Dữ liệu tiêm chủng hàng ngày có thể cập nhật chậm hơn thực tế</b>
              </div>
            </InfoPopup>
          </div>
          </div>
     
          
 

         
          </div>
          <p className="xl:px-5 px-2 text-xs text-gray-500">Nguồn: Bộ Y tế. Cập nhật lần cuối:{" "}{dayjs().format("DD/MM/YYYY")} </p>
        <ProvinceSelector />
        <CardVaccine selectedProvince={selectedProvince.id} />
        <div className="flex flex-col w-full  md:px-5 px-0">
         
          {!selectedProvince.id && (
            <div className="flex flex-row flex-wrap xl:flex-nowrap">
            {/* <WrapChart
              className="mb-4 xl:mr-2 mr-0"
             
              // expaned={
              //   <PopupExpaned title="Số liệu vaccine">
              //     <CardVaccineTotal maxHeight="100%" />
              //   </PopupExpaned>
              // }
            >
              <CardOne />
            </WrapChart>
            <WrapChart
              className="mb-4 "
              // expaned={
              //   <PopupExpaned title="Số liệu vaccine">
              //     <CardVaccineTotal maxHeight="100%" />
              //   </PopupExpaned>
              // }
            >
              <CardTwo />
            </WrapChart> */}
            </div>
          )}

          {!selectedProvince.id && (<></>
            // <div className="flex flex-row flex-wrap xl:flex-nowrap">
            //   <WrapChart
            //     className="mb-4 xl:mr-2 mr-0"
            //     title="Số liều về theo tháng"
            //     expaned={
            //       <PopupExpaned title="Số liều về theo tháng">
            //         {/* <ChartVaccineByDay maxHeight="100%" /> */}
            //         <VaccineTypeByTime maxHeight="100%" />
            //       </PopupExpaned>
            //     }
            //   >
            //     <VaccineTypeByTime />
            //   </WrapChart>
            //   <WrapChart
            //     className="mb-4"
            //     title="Số liều về theo loại"
            //     expaned={
            //       <PopupExpaned title="Số liều về theo loại">
            //         <VaccineType maxHeight="100%" />
            //       </PopupExpaned>
            //     }
            //   >
            //     <VaccineType />
            //   </WrapChart>
            // </div>
          )}
{!selectedProvince.id&&(<div className="flex flex-row flex-wrap xl:flex-nowrap">
            <WrapChart
              className="mb-4 xl:mr-2 mr-0"
              title="Số người đã tiêm vaccine"
              expaned={
                <PopupExpaned title="Số người đã tiêm vaccine">
                  <ChartVaccineByDay maxHeight="100%" />
                </PopupExpaned>
              }
            >
              <ChartVaccineByDay />
            </WrapChart>
            <WrapChart
              className="mb-4"
              title="Tổng số người đã tiêm vaccine"
              expaned={
                <PopupExpaned title="Tổng số người đã tiêm vaccine">
                  <ChartVaccineByTime maxHeight="100%" />
                </PopupExpaned>
              }
            >
              <ChartVaccineByTime />
            </WrapChart>
          </div>)}
          
          <div className="flex flex-row flex-wrap xl:flex-nowrap">
            <WrapChart
              className="mb-4 md:mr-2 mr-0"
              title="Số lượng vaccine đã tiêm"
              expaned={
                <PopupExpaned title="Số lượng vaccine đã tiêm">
                  <ChartVaccinceOfCity maxHeight="100%" maxWidth="1000px" />
                </PopupExpaned>
              }
            >
              <ChartVaccine />
            </WrapChart>
            <WrapChart
              // title="Bản đồ vaccine"
              className="mb-4 "
              expaned={
                <PopupExpaned title="Bản đồ vaccine">
                  <MapTiemChung maxHeight="100%" />
                </PopupExpaned>
              }
            >
              {tabSelected === 0 && (
                <>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">Bản đồ vaccine</span>
                    <PopupExpaned title="Bản đồ Vaccine đã tiêm thực tế">
                      <MapTiemChung maxHeight="100%" />{" "}
                    </PopupExpaned>
                  </div>
                  <div className="w-full flex flex-row justify-start">
                    <div className="flex ">
                      <ButtonSelectInput
                        title=""
                        options={dateOptions}
                        value={tabSelected}
                        onChange={(value) => {
                          setTabSelected(value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <Tabs
                onChange={(value) => {
                  setTabSelected(value);
                }}
                tabs={["Đã tiêm", "Kế hoạch phân bổ", "Tiến độ phân bổ"]}
                tabSelected={tabSelected}
                // response_status={responseStatus}
              /> */}
                  <h1 className="text-center font-bold uppercase mt-5 mb-5">
                    Bản đồ Vaccine đã tiêm thực tế
                  </h1>

                  <MapTiemChung />
                </>
              )}
              {tabSelected === 1 && (
                <>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">Bản đồ vaccine</span>
                    <PopupExpaned title="Bản đồ Vaccine phân bổ theo kế hoạch">
                      <MapTotalVaccineAllocated maxHeight="100%" />{" "}
                    </PopupExpaned>
                  </div>
                  <div className="w-full flex flex-row justify-start">
                    <div className="flex ">
                      <ButtonSelectInput
                        title=""
                        options={dateOptions}
                        value={tabSelected}
                        onChange={(value) => {
                          setTabSelected(value);
                        }}
                      />
                    </div>
                  </div>
                  <h1 className="text-center font-bold uppercase mt-5 mb-5">
                    Bản đồ Vaccine phân bổ theo kế hoạch
                  </h1>
                  <MapTotalVaccineAllocated />{" "}
                </>
              )}
              {tabSelected === 2 && (
                <>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">Bản đồ vaccine</span>

                    <PopupExpaned title=" Bản đồ Vaccine phân bổ theo thực tế">
                      <MapTotalVaccineAllocatedReality maxHeight="100%" />
                    </PopupExpaned>
                  </div>
                  <div className="w-full flex flex-row justify-start">
                    <div className="flex ">
                      <ButtonSelectInput
                        title=""
                        options={dateOptions}
                        value={tabSelected}
                        onChange={(value) => {
                          setTabSelected(value);
                        }}
                      />
                    </div>
                  </div>
                  <h1 className="text-center font-bold uppercase mt-5 mb-5">
                    Bản đồ Vaccine phân bổ theo thực tế
                  </h1>

                  <MapTotalVaccineAllocatedReality />
                </>
              )}
            </WrapChart>
          </div>
          {/* <div className="flex flex-row flex-wrap xl:flex-nowrap">
            <WrapChart
              className="mb-4 xl:mr-2 mr-0"
              title="Mũi tiêm đăng ký qua Nền tảng CN theo ngày"
              expaned={
                <PopupExpaned title="Mũi tiêm đăng ký qua Nền tảng CN theo ngày">
                  <ChartInjectedDay maxHeight="100%" />
                </PopupExpaned>
              }
            >
              <ChartInjectedDay />
            </WrapChart>
            <WrapChart
              title="Tổng mũi tiêm đăng ký qua Nền tảng CN"
              className="mb-4"
              expaned={
                <PopupExpaned title="Tổng mũi tiêm đăng ký qua Nền tảng CN">
                  <ChartInjectedTime maxHeight="100%" />
                </PopupExpaned>
              }
            >
              <ChartInjectedTime />
            </WrapChart>
          </div> */}
          {/* <WrapChart
            className="mb-4"
            title="So sánh số mũi đã tiêm theo tỉnh thành"
            expaned={
              <PopupExpaned
                title="So sánh số mũi đã tiêm theo tỉnh thành"
                textButton="Mở rộng"
              >
                <ChartCompareVaccineOfCity />
              </PopupExpaned>
            }
          >
            <ChartCompareVaccineOfCity />
          </WrapChart> */}
          <div className="mb-4">
            <WrapChart
              title="Dự đoán miễn dịch cộng đồng"
              expaned={
                <PopupExpaned title="Dự đoán miễn dịch cộng đồng">
                  <ChartProjection />
                </PopupExpaned>
              }
              popup={
                <InfoPopup>
                  <div className="text-xs h-full text-gray-500">
                    Biểu đồ dự đoán miễn dịch cộng đồng được tính dựa vào kế hoạch nhập vaccine về Việt Nam và số liệu cập nhật tiêm vaccine thực tế.
                  </div>
                </InfoPopup>
              }
            >
              <ChartProjection />
            </WrapChart>
          </div>
          <WrapChart
            title="Khu vực Đông Nam Á"
            expaned={
              <PopupExpaned title="Biểu đồ so sánh vaccine theo khu vực Đông Nam Á">
                <ChartCompareAsiaVaccine maxHeight="100%" />
              </PopupExpaned>
            }
          >
            <ChartCompareAsiaVaccine />
          </WrapChart>
        </div>
      </div>
    </>
  );
};

export default VaccinationIndex;
