const ProgressQA = (props) => {
  const { bgcolor, count, full, percent, cutoffPercent = 50, cutoff = 35, selected } = props;

  function bytesToSize(bytes) {
    var sizes = ["", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes < 1000) {
        return bytes + sizes[i];
      }
      else {
        bytes = parseFloat(bytes / 1000).toFixed(2);
      }
    }
    return 0;
  }

  // console.log(count)
  const containerStyles = {
    position: 'relative',
    height: "23px",
    width: "100%",
    //   borderRadius: 20,
  };

  const fillerStyles = {
    height: "100%",
    width: `${count / full * 100}%`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: `#065F46`

  };
  const fillerStylesPercent = {
    height: "100%",
    width: `${percent}%`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: `#50C878`
  };

  const absoluteStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  }

  const labelStyles = {
    padding: '0 6px',
    fontWeight: '500',
    fontSize: '0.75rem',
    transition: "opacity 1s ease-in-out",
  };

  return (
    <div style={containerStyles} className="bg-gray-100">
      {selected === 0 && (
        <div style={absoluteStyles} className="flex flex-row justify-start overflow-hidden">
          <div style={fillerStylesPercent} >
            {(percent) >= cutoffPercent &&
              <div className="flex justify-end items-center h-full">
                <div style={labelStyles} className="text-white">{`${percent.replace(/\./g, ',')}%`}</div>
              </div>
            }
          </div>
          {(percent) < cutoffPercent &&
            <div className="flex justify-end items-center h-full">
              <div style={labelStyles} className={`text-${bgcolor}`}>{`${percent.replace(/\./g, ',')}% `}</div>
            </div>
          }
        </div>
      )}
      {selected === 1 && (
        <div style={absoluteStyles} className="flex flex-row justify-start overflow-hidden">
          <div style={fillerStyles} >
            {(count / full * 100) >= cutoff &&
              <div className="flex justify-end items-center h-full">
                <div style={labelStyles} className="text-white">{`${bytesToSize(count).toLocaleString('vi-VN') || 0}`}</div>
              </div>
            }
          </div>
          {((count / full * 100) < cutoff || full == 0) &&
            <div className="flex justify-end items-center h-full">
              <div style={labelStyles} className={`text-${bgcolor}`}>{` ${bytesToSize(count).toLocaleString('vi-VN') || 0}`}</div>
            </div>
          }
        </div>
      )}


    </div>
  );
};

export default ProgressQA;
