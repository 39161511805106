import React, { useEffect, useState } from "react";
import PopupExpaned from "../commons/PopupExpaned";
import ProvinceSelector from "../commons/ProvinceSelector";
import WrapChart from "../graph/WrapChart";
import LoadingChartScreen from "../Loading/loading-chart-screen";
import ChartByDistancing from "./ChartByDistancing";
import ChartByStat from "./ChartByStat";
import ChartGeneralMobility from "./ChartGeneralMobility";
import MapQuarantine from "./MapQuarantine";
import TopCards from "./TopCards";
import InformationPopup from "../commons/InformationPopup";
import InfoPopup from "../commons/InformationPopup";
import { useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { quarantineProvinceState } from "../../recoil/atom-quarantine";
import dayjs from "dayjs";

const Quarantine = () => {
  const selectedProvince = useRecoilValue(countrySelected);
  const provinceQuarantine = useRecoilValue(quarantineProvinceState({ province: 0 }))
  const [newest, setNewest] = useState();

  useEffect(() => {
    if (provinceQuarantine.parks_percent_change_from_baseline) {
      let someArray = Object.keys(provinceQuarantine.parks_percent_change_from_baseline)
      let newestDate = someArray[someArray.length - 1]
      setNewest(dayjs(newestDate).format("DD/MM/YYYY"));
    }
  }, [provinceQuarantine])

  const individualCharts = [
    {
      label: "Siêu thị & Hiệu thuốc",
      objectKey: "grocery_and_pharmacy",
      color: {
        light: "#CCFBF1",
        dark: "#2DD4BF",
        line: "#14B8A6",
      },
    },
    {
      label: "Công viên",
      objectKey: "parks",
      color: {
        light: "#DCFCE7",
        dark: "#4ADE80",
        line: "#22C55E",
      },
    },
    {
      label: "Khu dân cư",
      objectKey: "residential",
      color: {
        light: "#F3E8FF",
        dark: "#C084FC",
        line: "#A855F7",
      },
    },
    {
      label: "Mua sắm & Giải trí",
      objectKey: "retail_and_recreation",
      color: {
        light: "#DBEAFE",
        dark: "#60A5FA",
        line: "#3B82F6",
      },
    },
    {
      label: "Bến xe & Nhà ga",
      objectKey: "transit_stations",
      color: {
        light: "#FCE7F3",
        dark: "#F472B6",
        line: "#EC4899",
      },
    },
    {
      label: "Nơi làm việc",
      objectKey: "workplaces",
      color: {
        light: "#FFEDD5",
        dark: "#FB923C",
        line: "#F97316",
      },
    },
  ];

  document.title = `Giãn cách ${selectedProvince.id ? selectedProvince.name : "Toàn quốc"}`;

  return (
    <div className="w-full flex flex-col p-0 md:p-2 lg:p-5">
      <div className=" flex flex-col px-2 lg:p-0">
        <div className="flex flex-row items-end">
          <span className="text-xl font-medium mr-1">Thống kê giãn cách:{" "}
            {selectedProvince.id ? selectedProvince.name : "Toàn quốc"}</span>
          <div className="transform -translate-y-1/4 z-50">
            <InformationPopup>
              <div className="text-xs">
                Chỉ số giãn cách thể hiện sự thay đổi trong di chuyển của người dân so với bình thường, với 0 là mức bình thường.<br /><br />
                Chỉ số thống kê nhỏ hơn 0 đồng nghĩa với việc người dân ít tụ tập lại ở những địa điểm đó hơn;
                chỉ số lớn hơn 0 đồng nghĩa với việc người dân tụ tập lại ở những địa điểm đó nhiều hơn.<br /><br />
                Ví dụ: chỉ số Mua sắm & giải trí ở Hà Nội là -70% có nghĩa là người dân Hà Nội ít đi mua sắm, giải trí hơn 70% so với bình thường.
                Ngược lại, chỉ số Khu dân cư ở Hà Nội là 40% có nghĩa là người dân Hà Nội ở nhà nhiều hơn 40% so với bình thường.
              </div>
            </InformationPopup>
          </div>
        </div>
        <span className="text-xs text-gray-500">Nguồn: <a href="https://www.google.com/covid19/mobility/">Google Mobility</a>. Cập nhật lần cuối:{" "}{newest ? newest : ""}</span>
      </div>
      <div className="px-2 md:p-0">
        <ProvinceSelector noPadding />
      </div>
      <React.Suspense fallback={<LoadingChartScreen />}>
        <div className="px-2 md:p-0">
          <TopCards />
        </div>
        <div className="flex flex-row  flex-wrap xl:flex-nowrap">
          <WrapChart
            className="mb-4 lg:mr-2 mr-0 w-full xl:w-1/2"
            title="Tình hình giãn cách trên toàn quốc"
            popup={
              <InfoPopup>
                <div className="text-xs h-full text-gray-500">
                  <i>Ghi chú: Màu càng nhạt (chỉ số càng nhỏ) là giãn cách càng tốt</i>
                </div>
              </InfoPopup>
            }
            expaned={
              <PopupExpaned
                title="Tình hình giãn cách trên toàn quốc"
                textButton="Mở rộng"
              >
                <MapQuarantine popup />
              </PopupExpaned>
            }
          >
            <MapQuarantine />
          </WrapChart>
          <WrapChart
            title="Biến động chỉ số giãn cách"
            className="mb-4 w-full xl:w-1/2"
            popup={
              <InfoPopup>
                <div className="text-xs h-full text-gray-500">
                  <i>Chỉ số giãn cách thể hiện mức giảm đi lại chung của người dân tại các địa điểm công cộng như công viên, siêu thị, bến xe, nhà ga...so với bình thường.</i>
                </div>
              </InfoPopup>
            }
            expaned={
              <PopupExpaned
                title="Biến động chỉ số giãn cách"
                textButton="Mở rộng"
              >
                <ChartGeneralMobility popup />
              </PopupExpaned>
            }
          >
            <ChartGeneralMobility />
          </WrapChart>
        </div>
        <div className="flex flex-col lg:grid xl:grid-cols-2  lg:gap-2  lg:grid-rows-2">
          {individualCharts.map((data, index) => (
            <WrapChart
              className="mb-2"
              title={data.label}
              expaned={
                <PopupExpaned title={data.label} textButton="Mở rộng">
                  <ChartByStat data={data} popup />
                </PopupExpaned>
              }
            >
              <ChartByStat data={data} />
            </WrapChart>
          ))}
        </div>
        <WrapChart
          className="mb-2"
          title="So sánh giãn cách các tỉnh thành"
          expaned={
            <PopupExpaned title="So sánh giãn cách các tỉnh thành" textButton="Mở rộng">
              <ChartByDistancing popup />
            </PopupExpaned>
          }
        >
          <ChartByDistancing maxHeight="45.5vh" />
        </WrapChart>
      </React.Suspense>
    </div>
  );
};

export default Quarantine;
