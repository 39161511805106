import React, { useEffect, useState } from "react";
import api from "../../services/api";
import * as dayjs from "dayjs";
const TrendingNews = () => {
  const [newsData, setNewsData] = useState();
  const [page, setPage] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    api
      .post("https://crawler.vnalert.vn/v1/covid-dashboard/trends/", {
        number: 12,
      })
      .then((success) => {
        setNewsData(success.data);
        setPage(1);
        setLoaded(true);
      })
      .catch((e) => console.log(e));
  }, []);
  const fakeItemLoading = [1, 2, 3, 4];
  const OneNew = ({ item }) => {
    return (
      <div className="mb-3 rounded-lg hover:bg-gray-100 p-1  md:w-1/2 xl:w-1/4 w-full">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <a href={item.url} target="_blank">
              <img
                src={item.feature_image}
                className="rounded-lg object-fill  shadow-lg h-48 lg:h-36  min-w-full max-w-full mb-2"
              />
              <span className="text-sm font-bold">
                {item.title.length <= 80
                  ? item.title
                  : `${item.title.slice(0, 80)}...`}
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex w-full border-b px-2">
      {loaded ? (
        <div className="w-full flex items-center">
          {page === 1 ? (
            <div className="hidden lg:block w-5"></div>
          ) : (
            <span
              className="hidden lg:block"
              onClick={() => setPage((prevPage) => prevPage - 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 lg:mb-12 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          )}
          <div className="flex flex-wrap">
            {newsData &&
              newsData.data.slice((page - 1) * 4, page * 4).map((item) => {
                return <OneNew item={item} />;
              })}
          </div>
          {page === 3 ? (
            <div className="hidden lg:block w-5"></div>
          ) : (
            <span
              className="hidden lg:block"
              onClick={() => setPage((prevPage) => prevPage + 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 lg:mb-12 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          )}
        </div>
      ) : (
        <div className="w-full px-3">
          <div className="animate-pulse flex flex-wrap">
            {fakeItemLoading.map(() => (
              <div className="xl:w-1/4 w-full md:w-1/2 mb-3 pr-3">
                <div className="flex flex-col justify-center items-start ">
                  <div className="h-48  lg:h-24 min-w-full max-w-full mb-2 bg-gray-200 rounded-lg"></div>
                  <div className="h-4 w-full mb-1 bg-gray-200"></div>
                  <div className="h-4 w-2/4 bg-gray-200"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendingNews;
