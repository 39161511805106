import React from "react";
import newlogo from "../../assets/logo.svg";
import miclogo from "../../assets/MIC.png";
import mohlogo from "../../assets/moh.png";
import { useHistory } from "react-router-dom";
import BootAnimation from "../commons/BootAnimation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = () => {
  const [boot, setBoot] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setBoot(false);
    }, 1500);
  }, []);

  return boot ? (
    <BootAnimation />
  ) : (
    <div className="fixed w-full hidden md:inline-block">
      <div
        className=" w-full border-b flex flex-row items-center p-4  bg-white z-30 cursor-pointer"
        title="Về trang tổng quan"
        style={{ height: "7vh" }}
      >
        <div className="flex flex-row items-center w-1/2">
          <Link to="../dulieu">
            <div className="flex">
              <img src={newlogo} style={{ height: 30 }} className="max-h-12" />
              <h2 className="font-bold text-lg pl-2 mr-5 uppercase    ">
                Dữ liệu COVID-19
              </h2>
            </div>
          </Link>
          <LiveMode />
        </div>

        <div className="flex flex-row items-center w-1/2 justify-end">
          <h2 className="font-normal text-xs pl-2 mr-5">
            <i>Trang thử nghiệm vận hành nội bộ</i>
          </h2>
          <img src={miclogo} style={{ height: 30 }} className="max-h-12" />

          <img src={mohlogo} style={{ height: 30 }} className="max-h-12 pl-3" />
        </div>
      </div>

      {/* <div className="flex flex-col absolute w-1/4">
        <SearchBox />
      </div> */}
    </div>
  );
};
const LiveMode = () => {
  const [liveMode, setLiveMode] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const autoLoadPage = ["/graph", "/vaccine", "/distancing"];
  const [indexPage, setIndexPage] = React.useState(0);
  React.useEffect(() => {
    liveMode === true && history.push(autoLoadPage[indexPage]);
  }, [indexPage]);
  React.useEffect(() => {
    if (!!liveMode) {
      let x = 0;
      setInterval(() => {
        x = x >= 2 ? 0 : x + 1;
        setIndexPage(x);
      }, 20000);
    }
  }, [liveMode]);
  return (
    <div
      title="Chế độ giám sát. Tự động chuyển đổi giữa các chuyên trang mỗi 30 giây"
      className={`${
        liveMode ? "animate-pulse bg-red-500" : "bg-gray-400"
      } flex rounded-2xl py-1 px-4 `}
      onClick={() => {
        setLiveMode(!liveMode);
      }}
    >
      <div className="text-white font-bold">live</div>
    </div>
  );
};
export default Header;
