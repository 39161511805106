import React, { useEffect, useState } from "react";
import api from "../../services/api";
import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import ProgressQA from "../commons/ProgressQA";

const ChartCompareAsia = ({ maxHeight }) => {
  const [data, setData] = useState();
  const [initData, setInitData] = useState([]);

  // const labels = [
  //   "Tỉnh/Thành phố",
  //   "Tỷ lệ dân số", "Số tuyệt đối", "Tỷ lệ dân số", "Số tuyệt đối"
  // ];

  useEffect(() => {
    api
      .get("vaccine/southeast_asian")
      .then((success) => {
        // console.log(success)
        setInitData(success.data);
        setData(success.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const labels = [
    { label: "Quốc gia", key: "country-other", percent: false },
    { label: "Tỷ lệ dân số", key: "people_vaccinated", percent: true },
    { label: "Số tuyệt đối", key: "people_vaccinated", percent: false },
    { label: "Tỷ lệ dân số", key: "people_fully_vaccinated", percent: true },
    { label: "Số tuyệt đối", key: "people_fully_vaccinated", percent: false },
  ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0]);

  const handleSort = (itemKey, index, percent) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index, percent);
    else sortByKey(itemKey, 1, index, percent);
  };

  const sortByKey = (objectKey, direction, index = -1, percent) => {
    let sorted;

    if (objectKey === "country-other") {
      sorted = [...initData].sort(function (a, b) {
        if (direction === 1) return a[objectKey].localeCompare(b[objectKey]);
        else return -a[objectKey].localeCompare(b[objectKey]);
      });
    } else if (!percent) {
      sorted = [...initData].sort(function (a, b) {
        if (a[objectKey] > b[objectKey]) return direction * -1;
        else if (a[objectKey] < b[objectKey]) return direction;
        else return 0;
      });
    } else {
      sorted = [...initData].sort(function (a, b) {
        if (
          a[objectKey] / a.population >
          b[objectKey] / b.population
        )
          return direction * -1;
        else if (
          a[objectKey] / a.population <
          b[objectKey] / b.population
        )
          return direction;
        else return 0;
      });
    }

    // console.log(sorted);

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };
  // console.log("initData",typeof initData)
  const getMax = (objectKey) => {
    let sorted = initData.sort(function (a, b) {
      if (parseInt(a[objectKey]) > parseInt(b[objectKey]))
        return -1;
      else if (parseInt(a[objectKey]) < parseInt(b[objectKey]))
        return 1;
      else return 0;
    });

    return sorted[0][objectKey] * 1.25;
  };
  return (
    <div>
     
      <div class="grid grid-cols-4  place-items-start  ...">
        <div></div>
        <div className="font-extrabold text-xs">Số người Tiêm 1 mũi</div>
        <div></div>
        <div className="font-extrabold text-xs">Số người Tiêm 2 mũi</div>
      </div>

      <div className="overflow-auto overflow-y-hidden">
        <table className="table w-full border-collapse">
          <thead className="border-b border-black sticky top-0 z-10 bg-white">
            <tr className="p-2 border-b border-black">
              {labels.map((item, index) => (
                <th
                  className="w-1/6 p-2 text-lg font-medium text-center cursor-pointer"
                  scope="col"
                  onClick={() => handleSort(item.key, index, item.percent)}
                >
                  <div className="w-full text-center flex flex-row justify-center items-center">
                    {item.label}
                    <span>
                      {sortArray[index] !== 0 ? (
                        <img
                          src={sortArray[index] === 1 ? descend : ascend}
                          className="ml-1 w-4 h-4"
                        />
                      ) : (
                        <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((country, idx) => {
                return (
                  <>
                    <tr className="border-b">
                      <td
                        className="font-bold p-2"
                        style={{ fontSize: "13px" }}
                      >
                        {country["country-other"]}
                      </td>
                      <td className="font-weight-bold p-2">
                        <ProgressQA
                          selected={0}
                          percent={(
                            (country.people_vaccinated /
                              country.population) *
                            100
                          ).toFixed(2)}
                          full={getMax("people_vaccinated")}
                        />
                      </td>
                      <td className="font-weight-bold p-2">
                        <ProgressQA
                          selected={1}
                          count={country["people_vaccinated"]}
                          full={getMax("people_vaccinated")}
                        />
                      </td>
                      <td className="font-weight-bold p-2">
                        <ProgressQA
                          selected={0}
                          percent={(
                            (country.people_fully_vaccinated /
                              country.population) *
                            100
                          ).toFixed(2)}
                          full={getMax("people_vaccinated")}
                        />
                      </td>
                      <td className="font-weight-bold p-2">
                        <ProgressQA
                          selected={1}
                          count={country["people_fully_vaccinated"]}
                          full={getMax("people_fully_vaccinated")}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    <small class="pl-2 text-sm text-gray-500 ">Dữ liệu từ Our World in Data và có thể cập nhật chậm hơn thực tế </small>
    </div>
  );
};

export default React.memo(ChartCompareAsia);
