import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import articleState from "../../recoil/atom-article";
import countrySelected from "../../recoil/atom-country";

const NewsGrid = () => {
  const [selectedProvince] = useRecoilState(countrySelected);
  const provinceArticles = useRecoilValue(
    articleState({ province: selectedProvince.id })
  );
  const [slicedArticles, setSlicedArticles] = useState();
  const [last, setLast] = useState(0);

  useEffect(() => {
    provinceArticles && setSlicedArticles(provinceArticles);
    setLast(8);
  }, [provinceArticles]);

  const handleScroll = () => {
    // console.log(window.scrollY, window.innerHeight, document.body.clientHeight)
    if (
      window.scrollY + window.innerHeight ===
      window.clientHeight + window.offsetTop
    ) {
      // console.log('a');
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll(), { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll());
    };
  }, []);

  return (
    <div>
      {!provinceArticles[0] && (
        <div className="pl-5 font-medium text-red-500">
          Hiện không có tin tức nào về tỉnh {selectedProvince.name}.
        </div>
      )}
      <div className=" flex flex-col lg:grid xl:grid-cols-2 lg:gap-6 mb-16 lg:mb-0">
        {slicedArticles &&
          slicedArticles.map((article, index) => {
            return (
              <a href={article.url} target="_blank">
                <div
                  className={
                    " mx-5 py-2 flex flex-row justify-start items-start max-h-60"
                  }
                >
                  <img
                    src={article.feature_image}
                    className="rounded-lg object-cover w-24 h-18 lg:w-36 lg:h-24"
                  />
                  <div className="pl-2 flex flex-col">
                    <div className="text-sm font-medium text-justify pb-1">
                      {article.title}
                    </div>
                    <div className="text-xs text-justify">
                      {article.description}
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default NewsGrid;
