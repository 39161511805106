const colorScaleTotal = (pos_case) => {
  if (1 <= pos_case && 500 >= pos_case) {
    return "#22C55E";
  }
  if (501 <= pos_case && 1000 >= pos_case) {
    return "#FFA34F";
  }
  if (1001 <= pos_case && 5000 >= pos_case) {
    return "#FF392B";
  }
  if (5001 <= pos_case && 50000 >= pos_case) {
    return "#D40638";
  }
  if (50001 <= pos_case) {
    return "#5D0000";
  }
  return "#fef0eb";
};

const colorScale = (pos_case) => {
  if (1 <= pos_case && 100 >= pos_case) {
    return "#FFCFB4";
  }
  if (101 <= pos_case && 500 >= pos_case) {
    return "#FFA34F";
  }
  if (501 <= pos_case && 1000 >= pos_case) {
    return "#FF392B";
  }
  if (1001 <= pos_case && 10000 >= pos_case) {
    return "#D40638";
  }
  if (10001 <= pos_case) {
    return "#5D0000";
  }
  return "#22C55E";
};

const colorScaleVaccine = (per) => {
  if (0 <= per && 30 > per) {
    return "#D0F0BF";
  }
  if (30 <= per && 40 > per) {
    return "#AAE0B4";
  }
  if (40 <= per && 50 > per) {
    return "#6AC680";
  }
  if (50 <= per && 60 > per) {
    return "#4CA054";
  }
  if (70 <= per) {
    return "#2E672B";
  }
  return "#D0F0BF";
};

export { colorScale, colorScaleVaccine, colorScaleTotal };
