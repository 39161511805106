import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import Highcharts from "highcharts/highmaps";
import HighMaps from 'highcharts/highmaps'
import VnMapAllProvide from "./MapJson/VnProvince";
import DataMap from "./MapJson/Data-MapVN";
import api from "../../services/api";

// import "../assets/MapVietNam.css";
function bytesToSize(bytes) {
  var sizes = ["", "K", "M", "G", "T", "P"];
  for (var i = 0; i < sizes.length; i++) {
    if (bytes < 1000) {
      return bytes + sizes[i];
    }
    else {
      bytes = parseFloat(bytes / 1000).toFixed(2);
    }
  }
  return 0;
}

class MapTotalVaccineAllocatedReality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataMap: [],

      dataNew: [],
      loading: false,
    };
  }

  componentDidMount() {
    api.get("/vaccine/provinces").then((success) => {
      this.setState({ dataMap: success.data, loading: true });
      this.state.dataMap.map((item) => {

        // console.log("213123", (item.totalVaccineAllocatedReality / (item.popOverEighteen *2) * 100).toFixed(2))
        this.state.dataNew.push({
          name: item.id.toString(),
          value: (item.totalVaccineAllocatedReality / (item.popOverEighteen * 2) * 100).toFixed(2),
          yearsold: item.popOverEighteen.toLocaleString("vi-VN"),
          totalVaccine: item.totalVaccineAllocatedReality.toLocaleString("vi-VN")
        })
      });




      const options = {
        chart: {
          style: {
            fontFamily: ''
          }
        },
        title: {
          text: "",
          style: {
            fontSize: "13px",
          },
          align: "center",
          verticalAlign: "bottom",
        },
        plotOptions: {
          map: {
            states: {
              hover: {
                color: "#EEDD66",
              },
            },
          },
        },
        colors: [
          "#D0F0BF",
          "#AAE0B4",
          "#6AC680",
          "#2EA053",
          "#01662A",
          "#008000",
        ],
        colorAxis: {
          dataClassColor: "category",
          dataClasses: [
            //     to: 20,
            //     name: '< 20%'
            // }, {
            //     from: 20,
            //     to: 200,
            //     name: '20% - 200%'
            // }, {
            //     from: 200,
            //     name: '>200%'
            // }
            {

              to: 0,
              name: '0 - 30%'
            },

            {
              from: 30,
              to: 40,
              name: "30% - 40%"
            },
            {
              from: 40,
              to: 50,
              name: "40% - 50%"
            },
            {
              from: 50,
              to: 60,
              name: "50% - 60%"
            },
            {
              from: 70,
              name: "> 70%"
            }
          ],
        },

        credits: {
          enabled: true,
        },
        subtitle: {
          text: ``,
        },
        legend: {
          title: {
            text: "",
          },
          align: "center",
          verticalAlign: "bottom",
          floating: false,
          layout: "horizontal",
          valueDecimals: 0,
          backgroundColor: "rgba(255,255,255,0.9)",
          symbolRadius: 0,
          symbolHeight: 14,
        },

        // tooltip: {
        //   // useHTML: true,
        //   // backgroundColor: "#9c9c9c",
        //   // borderColor: "#aaa",

        //   formatter: `<div style="text-align: center;color:white">'
        // +'<span style="text-align: center;"><b>{series.city}</b></span></div>'
        // +' <div style="color:white"> <b>Số lượt tham gia: <span class="badge badge-pill badge-danger">{point.values}</span></b> <br/>`,
        // },
        tooltip: {
          useHTML: true,
          backgroundColor: "#fafafa",
          borderColor: "#aaa",
          formatter: function () {
            return (
              `
<div class="flex">
<div class="relative">
 <div class="text-xs rounded right-0 bottom-full text-center">
 <div > <span class="font-bold text-red-500 text-base">${this.point.name}</span> </div>
 <div class="h-px bg-gray-400 mt-1 mb-1.5"></div>
 <div >Tỷ lệ:
 <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-green-100 bg-green-600 rounded-full ">${this.point.value.replace(/\./g, ',')}%</span>
 </div>
 <div class="mt-2">Dân số >=18 tuổi: <span class="font-bold text-blue-500">${this.point.yearsold.replace(/,/g, '.')}</span> </div>
 </div>
 <div class="mt-2">Tổng số vaccine: <span class="font-bold text-blue-500">${this.point.totalVaccine.replace(/,/g, '.')}</span> </div>
 </div>
</div>
`

            );
          },

          valueSuffix: "%",
        },
        series: [
          {
            mapData: VnMapAllProvide,
            data: this.state.dataNew,


            name: "Việt Nam",
            cursor: "pointer",
            states: {
              hover: {
                color: "#948c94",
              },
            },
            dataLabels: {
              enabled: !1,
              color: "#666666",
              format: "{point.id}",
              style: {
                fontWeight: "normal",
                textOutline: 1,
              },
            },
            joinBy: ["hc-key", "name"],
            // dataLabels: {
            //   enabled: false,
            //   format: "",
            // },
          },
        ],
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "top",
          },
        },
      };

      if (this.state.loading && this.state.dataMap !== null) {
        this.chart = new HighMaps['Map'](
          findDOMNode(this),
          options
        );
      }

    });
  }

  // componentWillUnmount() {
  //   if(this.chart) this.chart.destroy();
  // }

  render() {
    return (
      <>
        {this.state.loading && this.state.dataMap !== null ? (
          <div className="in-highchart" style={{ height: "48vh" }} />
        ) : (
          <div style={{ paddingTop: "8em", textAlign: "center" }}>
            Đang tải bản đồ...
          </div>
        )}
      </>
    );

    // <div className="in-highchart" />;
  }
}

export default MapTotalVaccineAllocatedReality;
