import React, { useEffect, useState } from 'react';

function ButtonSelectInput({
  options = [],
  value,
  onChange = () => { },
}) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    const sltd = options.find((i) => i.value === value);
    if (!!sltd) {
      setSelected(sltd);
    } else {
      setSelected(options[0]);
    }
  }, [value]);

  useEffect(() => { }, [selected]);

  const handleChange = (value) => {
    onChange(value);
  }

  return (
    <div className="flex flex-row w-full items-center">
      {options.map((item) => {

        return (
          <div
            onClick={() => handleChange(item.value)}
            className={"rounded h-full px-2 py-1 border-1 text-xs font-medium mr-1 cursor-pointer "
            + ((value === item.value) ? "bg-gray-500 text-white" : "bg-gray-200")}
          >
            {item.label}
          </div>
        )
      })}
    </div>
  );
};

export default ButtonSelectInput;