import React, { useState } from "react";
// import MapActiveCase from "../map/MapVN";
import LoadingMenuLeft from "../Loading/loading-menu-left";
import NewLeafLetMapVn from "../map/NewLeafLetMapVn";
const HomePage = () => {
  document.title = "Dữ liệu COVID-19";

  const [mapFilter, setMapFilter] = useState([
    { id: 1, title: "Tổng số ca nhiễm", isChoose: true },
    { id: 1, title: "Mọi lúc", isChoose: true },
  ]);

  const handleMapFilter = (type, time) => {
    setMapFilter([type, time]);
    // console.log(type);
  };
  return (
    <React.Suspense fallback={<LoadingMenuLeft />}>
      <div>
        <div className="p-0">
          <div className="hidden md:inline">
            {/* <VNLeafletMap type={mapFilter[0].id} /> */}
            <NewLeafLetMapVn/>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};

export default HomePage;
