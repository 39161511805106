import React, {  } from "react";
import TrendingNews from "./TrendingNews";
import ProvinceNews from "./ProvinceNews";
import dayjs from "dayjs";

const CovidNews = () => {
  document.title = "Tin tức COVID-19";
  return (
    <div className="w-full flex flex-col p-0 md:py-2 lg:py-5">
      <div className=" flex flex-col px-2 md:px-2 lg:px-5 mb-5">
        <span className="text-xl font-medium">Tin tức COVID-19 nổi bật</span>
        <span className="text-xs ">Cập nhật lần cuối:{" "}{dayjs().format("DD/MM/YYYY")}</span>
      </div>
      <TrendingNews />
      <ProvinceNews />
    </div>
  );
};

export default CovidNews;
