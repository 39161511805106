import React from "react";

const ItemCountry = ({ data, removeItem = () => {} }) => {
  return (
    <div className="rounded bg-gray-200 h-full px-2 py-1 border-1 text-xs font-medium m-1 cursor-pointer  flex items-center  justify-between flex-none">
      <div className="mr-2">{data.name}</div>
      <div
        title="Xóa"
        className="cursor-pointer"
        onClick={() => {
          removeItem();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  );
};
export default ItemCountry;
