import React from "react";
import newlogo from "../../assets/logo.svg";
const BootAnimation = () => {
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 w-full h-screen bg-white flex justify-center items-center flex-col"
      style={{ zIndex: 1800 }}
    >
      <img className="w-32 mb-3" src={newlogo} />
      <span className="font-bold">Dữ liệu COVID-19</span>
    </div>
  );
};

export default BootAnimation;
