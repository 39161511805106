import { selectorFamily } from "recoil";
import api from "../services/api";

const bluezoneState = selectorFamily({
  key: "bluezoneData",
  get: () => async () => {
    const response = await api.get(`/bluezone`);
    if (response.error) {
      throw response.error;
    }
    return response.data;
  },
});

export default bluezoneState;
