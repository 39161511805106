import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Header from "./components/layout/Header";
import AppRouter from "./components/layout/AppRouter";
import NavBar from "./components/layout/NavBar";
import MenuLeft from "./components/layout/MenuLeft";
import "./styles/global.css";
import RightBar from "./components/HomePage/RightBar";
import NavBarMobile from "./components/layout/NavBarMobile";

import ErrorBoundary from "./components/commons/ErrorBoundary";
const App = () => {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <div className="">
        <Header />
        <div className="md:hidden">
          <NavBar />
        </div>
        <ErrorBoundary>
          <div className="flex flex-col" style={{ paddingTop: "7vh" }}>
            <div className="flex flex-col md:flex-row">
              <div className="w-full  xl:w-1/4 lg:w-2/5 lg:border-r">
                <MenuLeft />
              </div>
              <div
                className={`w-full  xl:w-3/4 lg:w-3/5 menu-left relative lg:bg-gray-50 bg-white`}
              >
                <div className="desktop">
                  <NavBar />
                </div>
                <div className="sm:pt-10 h-full">
                  <AppRouter />
                </div>
              </div>
            </div>
          </div>
        </ErrorBoundary>
        {/* <div className="desktop">
          <RightBar />
        </div> */}
      </div>
      {/* <div className="mobile">
        <NavBarMobile />
      </div> */}
    </Router>
  );
};

export default App;
