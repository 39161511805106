import React, { useEffect, useState } from "react";
import api from "../../services/api";
import ProgressBar from "../commons/ProgressBar";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";

const ChartASEANComparison = ({ maxHeight }) => {
  const [data, setData] = useState();
  const [initData, setInitData] = useState();

  const labels = [
    { label: "Quốc gia", key: "country-other" },
    { label: "Nhiễm mới", key: "new-cases" },
    { label: "Tổng nhiễm", key: "total-cases" },
    { label: "Tử vong", key: "total-deaths" },
    { label: "Hồi phục", key: "total-recoverd" },
    { label: "Đang điều trị", key: "active-cases" },
  ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    api
      .get("/covid/southeast_asian")
      .then((success) => {
        setInitData([...success.data]);
        setData([...success.data]);
      })
      .catch((e) => console.log(e));
  }, []);

  const handleSort = (itemKey, index) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index);
    else sortByKey(itemKey, 1, index);
  };

  const sortByKey = (objectKey, direction, index = -1) => {
    let sorted;

    if (objectKey === "country-other") {
      sorted = initData.sort(function (a, b) {
        if (direction === 1)
          return a["country-other"].localeCompare(b["country-other"]);
        else return -a["country-other"].localeCompare(b["country-other"]);
      });
    } else {
      sorted = initData.sort(function (a, b) {
        return (parseInt(b[objectKey]) - parseInt(a[objectKey])) * direction;
      });
    }

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };

  const getMax = (objectKey) => {
    let sorted = initData.sort(function (a, b) {
      return parseInt(b[objectKey]) - parseInt(a[objectKey])
    });

    return sorted[0][objectKey] * 1.25;
  };

  return (
    <div>
      <div className=" mb-2 my-auto text-gray-500 text-sm items-center">
      </div>
      <div
        className="overflow-y-scroll no-scrollbar"
        style={{ maxHeight: maxHeight }}
      >
        <table className="table w-full border-collapse">
          <thead className="border-b border-black sticky top-0 z-10 bg-white">
            <tr className="p-2 border-b border-black">
              {labels.map((item, index) => (
                <th
                  className="w-1/6 p-2 text-md font-medium text-center cursor-pointer"
                  scope="col"
                  onClick={() => handleSort(item.key, index)}
                >
                  <div className="w-full text-center text-sm flex flex-row justify-center items-center relative">
                    {item.label}
                    <span className="absolute right-0">
                      {sortArray[index] !== 0 ? (
                        <img
                          src={sortArray[index] === 1 ? descend : ascend}
                          className="ml-1 w-4 h-4"
                        />
                      ) : (
                        <img src={noSort} className="ml-1 w-4 h-4 opacity-20" />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && initData && data.map((country) => {
              return (
                <tr className="border-b">
                  <td
                    className="font-bold p-2"
                    style={{ fontSize: "13px" }}
                  >
                    {country["country-other"]}
                  </td>
                  <td className="font-weight-bold p-2">
                    <ProgressBar
                      count={parseInt(country["new-cases"]) > 0 ? parseInt(country["new-cases"]) : 0}
                      full={getMax("new-cases")}
                      bgcolor="blue"
                    />
                  </td>
                  <td className="font-weight-bold p-2">
                    <ProgressBar
                      count={parseInt(country["total-cases"]) > 0 ? parseInt(country["total-cases"]) : 0}
                      full={getMax("total-cases")}
                      bgcolor="red"
                    />
                  </td>
                  <td className="font-weight-bold p-2">
                    <ProgressBar
                      count={parseInt(country["total-deaths"]) > 0 ? parseInt(country["total-deaths"]) : 0}
                      full={getMax("total-deaths")}
                      bgcolor="gray"
                    />
                  </td>
                  <td className="font-weight-bold p-2">
                    <ProgressBar
                      count={parseInt(country["total-recoverd"]) > 0 ? parseInt(country["total-recoverd"]) : 0}
                      full={getMax("total-recoverd")}
                      bgcolor="green"
                    />
                  </td>
                  <td className="font-weight-bold p-2">
                    <ProgressBar
                      count={parseInt(country["active-cases"]) > 0 ? parseInt(country["active-cases"]) : 0}
                      full={getMax("active-cases")}
                      bgcolor="yellow"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(ChartASEANComparison);
