import { atom } from "recoil";

const overViewVaccineDay = atom({
  key: "overViewVaccineDay", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const overViewVaccineTime = atom({
  key: "overViewVaccineTime", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const overViewInjectedEachDays = atom({
  key: "overViewInjectedEachDays", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const overViewInjectedEachTime = atom({
  key: "overViewInjectedEachTime", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const dataByDayVaccine = atom({
  key: "dataByDayVaccine", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const dataByDayOnceVaccine = atom({
  key: "dataByDayOnceVaccine", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const dataByDayTwiceVaccine = atom({
  key: "dataByDayTwiceVaccine", // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});
const overViewVaccineType = atom ({
  key: "overViewVaccineType", // unique ID (with respect to other atoms/selectors)
  default: [] 
})
const overViewVaccineTypeByMonth = atom ({
  key: "overViewVaccineTypeByMonth", // unique ID (with respect to other atoms/selectors)
  default: [] 
})
const overViewVaccineTotal = atom ({
  key:"overViewVaccineTotal",
  default:[]
})
const overViewTotalVaccine = atom ({
  key:"overViewTotalVaccine",
  default:[]
})





export { overViewTotalVaccine,overViewVaccineTotal,overViewVaccineTypeByMonth,overViewVaccineType,dataByDayTwiceVaccine,dataByDayOnceVaccine,overViewVaccineDay, overViewVaccineTime,overViewInjectedEachDays,overViewInjectedEachTime,dataByDayVaccine };