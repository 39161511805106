import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import currentProvinceState from "../../recoil/atom-current-province";
import totalState from "../../recoil/atom-total";
import ButtonSelectInput from "../commons/ButtonSelectInput";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PopupTime from "../commons/PopupTime";
import { sliceChart, validateSlice } from "../../services/chartRange";

const ChartCumulativeCase = () => {
  const [total] = useRecoilState(totalState);
  const selectedProvince = useRecoilValue(countrySelected);
  const province = useRecoilValue(
    currentProvinceState({ province: selectedProvince.id })
  );

  const [data, setData] = useState();

  useEffect(() => {
    selectedProvince.id
      ? province.total_case > -1 && setData(province)
      : setData(total);
  }, [selectedProvince, total, province]);

  const [selectedDate, setSelectedDate] = useState({ active: 60, start: 60, end: 0 });
  const [outOfRange, setOutOfRange] = useState(false);

  const options = {
    locale: 'vi-VN',
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
        },
        min: 0,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        align: selectedDate !== 7 ? 'left' : 'top',
        anchor: 'end',
        padding: selectedDate !== 7 ? 0 : -10,
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
        display: function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return index === data.length - 1;
        },
        formatter: function (value) {
          return value.toLocaleString('vi-VN') + "\n";
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const dateOptions = [

    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const dataSet = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "rgb(255,106,7)");
    gradient1.addColorStop(1, "rgb(252,242,233)");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient2.addColorStop(0, "#6B7280");
    gradient2.addColorStop(1, "#E5E7EB");

    let caseByDay = Object.values(data.case_by_time);
    let activeByDay = Object.values(data.active_by_time);

    if (!validateSlice(caseByDay, selectedDate)) {
      setSelectedDate({ active: 60, start: 60, end: 0 });
      setOutOfRange(true);
    }

    const slicedCase = sliceChart(caseByDay, selectedDate);
    const slicedActive = sliceChart(activeByDay, selectedDate);


    let unsplitted = (data.case_by_time && Object.keys(data.case_by_time)) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _caseLabel = unsplitted.map((day) => {
      let split = day.split("/");
      return parseInt(split[0]) + "/" + parseInt(split[1]);
    })

    return {
      labels: _caseLabel,
      datasets: [
        // {
        //   label: "Ca đang điều trị",
        //   backgroundColor: gradient2,
        //   borderColor: "#6B7280",
        //   borderWidth: 1,
        //   pointColor: "#fff",
        //   pointRadius: 0,
        //   fill: "start",
        //   backgroundColor: gradient2,
        //   pointStrokeColor: "#D1D5DB",
        //   pointHighlightFill: "#fff",
        //   pointHighlightStroke: "#D1D5DB",
        //   data: slicedActive,
        // },
        {
          label: "Tổng số ca bệnh",
          backgroundColor: gradient1,
          borderColor: "#ff6c23",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          fill: "start",
          backgroundColor: gradient1,
          pointStrokeColor: "#EF4444",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#EF4444",
          data: slicedCase,
        },
      ],
    };
  };

  return (
    <div className="flex flex-col justify-center">
      {data && data.total_case > -1 && (
        <>
          <div className="mb-3 flex flex-row justify-start items-center my-2">
            <div className="w-full flex flex-row justify-start">
              <div className="flex">
                <ButtonSelectInput
                  title=""
                  options={dateOptions}
                  value={selectedDate.active}
                  onChange={(value) => {
                    setOutOfRange(false);
                    setSelectedDate({ active: value, start: value, end: 0 });
                  }}
                />
              </div>
              <PopupTime
                onSave={(range) => {
                  setOutOfRange(false);
                  setSelectedDate({ active: -1, ...range })
                }}
              />
            </div>
          </div>
          {outOfRange && <div className="text-xs text-red-600 italic">Khoảng thời gian chưa có dữ liệu hoặc không hợp lệ.</div>}
          <Line data={dataSet} width="10" height="6vh" plugins={[ChartDataLabels]} options={options} />
        </>
      )}
    </div>
  );
};

export default React.memo(ChartCumulativeCase);
