import { atom, selectorFamily } from "recoil";
import api from "../services/api";

const quarantineProvinceState = selectorFamily({
  key: "quarantineProvince",
  get:
    ({ province }) =>
      async ({ get }) => {
        if (province) {
          const responseDetail = await api.get(`/mobility?provinceCode=${province}`);
          if (responseDetail.error) {
            throw responseDetail.error;
          }
          return responseDetail.data;
        } else {
          const responseDetail = await api.get(`/mobility?provinceCode=0`);
          if (responseDetail.error) {
            throw responseDetail.error;
          }
          return responseDetail.data;
        }
        return {};
      },
});

const quarantineProvinceByDay = selectorFamily({
  key: "quarantineProvinceByDay",
  get:
    ({ day }) =>
      async ({ get }) => {
        if (day) {
          const responseDetail = await api.get(`/mobility?average_days=${day}`);
          if (responseDetail.error) {
            throw responseDetail.error;
          }
          return responseDetail.data;
        } else {
          const responseDetail = await api.get(`/mobility?average_days=0`);
          if (responseDetail.error) {
            throw responseDetail.error;
          }
          return responseDetail.data;
        }
        return {};
      },
})

export { quarantineProvinceState, quarantineProvinceByDay };
