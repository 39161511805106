import React, { useMemo } from "react";
import L from "leaflet";
import api from "../../services/api";
// import transition from "d3-transition";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { isMobile } from "react-device-detect";
import { listProvincesState } from "../../recoil/atom-provinces";
import { useHistory } from "react-router-dom";
// import "../assets/MapVietNam.css";
import "./leaflet.css";
import { useEffect } from "react";
import { useState } from "react";
import ratioData from "../../recoil/atom-ratio";
import SmallSelect from "../commons/SelectInputSmall";
import totalState from "../../recoil/atom-total";
import { countryByDayState } from "../../recoil/atom-top-country";

const VNLeafletMapActiveCase = (props) => {
  const [type, setType] = React.useState(1);
  const [isHaveData, setIsHaveData] = useState(false);
  const [dataRatio] = useRecoilState(ratioData);
  const [listProvinces] = useRecoilState(listProvincesState);
  const provinceByDay = useRecoilValue(countryByDayState({ filter: 1 }));
  const total = useRecoilValue(totalState);

  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const [map, setMap] = useState();
  const [geojson2, setGeojson2] = useState();
  const [geojson1, setGeojson1] = useState();
  const [vaccine, setVaccine] = useState([]);
  const getPercent = (total, value) => {
    return ((100 / total) * value).toFixed(2);
  };
  // let view =
  //   window.screen.width > 1366 ? [16.40896, 110.66536] : [11.40896, 113.66536];
  let view = [15.4626168, 106.6402231];
  let scale = window.screen.width >= 1366 ? 6 : 5.5;
  // if (window.screen.width >= 2560) {
  //   scale = 6.5;
  //   view = [14.90896, 110.66536];
  // }

  const colorScale = (pos_case) => {
    if (1 <= pos_case && 100 >= pos_case) {
      return "#22C55E";
    }
    if (101 <= pos_case && 500 >= pos_case) {
      return "#FFA34F";
    }
    if (501 <= pos_case && 1000 >= pos_case) {
      return "#FF392B";
    }
    if (1001 <= pos_case && 10000 >= pos_case) {
      return "#D40638";
    }
    if (10001 <= pos_case) {
      return "#5D0000";
    }
    return "#fef0eb";
  };

  const colorScaleVaccine = (per) => {
    if (0 <= per && 3 > per) {
      return "#D0F0BF";
    }
    if (3 <= per && 6 > per) {
      return "#AAE0B4";
    }
    if (6 <= per && 10 > per) {
      return "#6AC680";
    }
    if (10 <= per && 20 > per) {
      return "#4CA054";
    }
    if (20 <= per) {
      return "#2E672B";
    }
    return "#D0F0BF";
  };
  const topCountryVN = [
    {
      name: "Hà Nội",
      latlng: [21.023694, 105.83559],
    },
    {
      name: "Hải Phòng",
      latlng: [20.853789, 106.698919],
    },
    {
      name: "Đà Nẵng",
      latlng: [16.053118, 108.2052],
    },
    {
      name: "Hồ Chí Minh",
      latlng: [10.815068, 106.65832],
    },
    {
      name: "Cần Thơ",
      latlng: [9.969658, 104.1883701],
    },
  ];

  // TO BE USED ONCE BACKEND RETURNS YESTERDAY'S DATA

  const [today, setToday] = useState();

  const getTodayCase = (province) => {
    if (total.new_case == 0) {
      setToday(false);
      if (province.id) {
        let keys = Object.keys(provinceByDay);
        let values = Object.values(provinceByDay);
        let index = keys.findIndex((key) => parseInt(key) === province.id);
        if (index !== -1) {
          let provinceValues = Object.values(values[index]);
          return (
            provinceValues[provinceValues.length - 2] -
            provinceValues[provinceValues.length - 3]
          );
        } else return 0;
      } else return 0;
    } else {
      setToday(true);
      return province ? province.To_day_case : 0;
    }
  };

  const createMap = (provincesData) => {
    let map = L.map("map-container", { zoomSnap: 0.25 });
    // map.scrollWheelZoom.disable();
    // map.dragging.disable();
    // map.touchZoom.disable();
    // map.doubleClickZoom.disable();
    // map.boxZoom.disable();
    // map.keyboard.disable();

    // if (map.tap) {
    //   map.tap.disable();
    // }

    map.setView([13.40896, 106.66536], 4);
    map.setView(view, scale);

    let bglayer_Positron = L.tileLayer(
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      {
        maxZoom: 19,
        minZoom: 3,
      }
    );

    setMap(map);

    bglayer_Positron.addTo(map);

    getGeoData(
      "https://raw.githubusercontent.com/bad199xqn/map-data/master/diaphantinh_geojson.json"
    ).then((data_tinh) => {
      getGeoData(
        "https://raw.githubusercontent.com/bad199xqn/map-data/master/diaphanhuyen_geojson.json"
      ).then((data_huyen) => {
        var geojson1 = L.geoJson(data_tinh, {
          style: function (feature) {
            const province = provincesData.filter(
              (province) => province.id === parseInt(feature.properties.CODE)
            )[0];
            const pos_case = province ? province.total_case : 0;
            if (
              (feature.properties.NAME_1 === "Hoàng Sa" ||
                feature.properties.NAME_1 === "Trường Sa") &&
              pos_case < 1
            ) {
              return {
                color: "#F2BE21",
                opacity: 1,
                weight: 1,
                fillColor: "#F2BE21",
                fillOpacity: 1,
              };
            }
            return {
              color: "#fff",
              opacity: 1,
              weight: 1,
              fillColor: colorScale(pos_case),
              fillOpacity: 1,
            };
          },
          onEachFeature: function (feature, layer) {
            const province =
              provincesData.filter(
                (province) => province.id === parseInt(feature.properties.CODE)
              )[0] || {};
            const today_case = getTodayCase(province);
            const sum_case = province ? province.Sum_case : 0;

            const currentHoverData =
              (province && dataRatio.find((item) => item.id === province.id)) ||
              {};
            if (!!currentHoverData) {
              layer.on("mouseout", function (e) {
                // layer.openPopup();
                const popup = L.popup().setContent(
                  tooltipCard1(
                    today,
                    province?.name,
                    today_case,
                    province.total_case,
                    province.total_death,
                    province.percent_case_population.toFixed(2),
                    province.case_average_7days.toFixed(0),
                    province.death_average_7days.toFixed(0),
                    getPercent(
                      currentHoverData.population,
                      currentHoverData.totalInjected
                    ),
                    getPercent(
                      currentHoverData.population,
                      currentHoverData.totalOnceInjected
                    ),
                    getPercent(
                      currentHoverData.population,
                      currentHoverData.totalTwiceInjected
                    ),
                    true
                  )
                );
                popup.setLatLng(e.latlng).openOn(map);
              });
            }
            layer.on("click", function (e) {
              setCurrentCountry(province);
              map.fitBounds(e.target.getBounds());
              geojson1.removeFrom(map);
              geojson2.addTo(map);

              // this.setStyle({
              //   color: "#fff",
              //   opacity: 1,
              //   weight: 1,
              //   fillColor: "#FEB79C",
              //   fillOpacity: 0.7,
              // });
            });
          },
        });

        setGeojson1(geojson1);

        var geojson2 = L.geoJson(data_huyen, {
          style: function (feature) {
            return {
              color: "#AFBAC1",
              opacity: 1,
              weight: 1,
              fillColor: "#FAFAF8",
              fillOpacity: 1,
            };
          },
          onEachFeature: function (feature, layer) {
            // const geoLatLng = feature.geometry.coordinates[0][0].reverse();
            // return;
            // layer.on("click", function (e) {
            //   map.setView(view, scale);
            //   geojson1.addTo(map);
            //   geojson2.removeFrom(map);
            //   setCurrentCountry({});
            // });
          },
        });
        topCountryVN.forEach((ct) => {
          const labelMaker = L.divIcon({
            className: "my-div-icon",
            html: `<div class="w-20  font-semibold text-gray-400"><div class="h-1 w-1 bg-gray-300 mr-1"></div>${ct.name}</div>`,
          });
          L.marker(ct.latlng, {
            icon: labelMaker,
          }).addTo(map);
        });
        const hs_label = L.divIcon({
          className: "my-div-icon",
          html: `<div class="w-20  font-semibold text-gray-400">Hoàng Sa</div>`,
        });
        // you can set .my-div-icon styles in CSS

        L.marker([17.11524313400008, 111.55987251100004], {
          icon: hs_label,
        }).addTo(map);

        const ts_label = L.divIcon({
          className: "my-div-icon",
          html: `<div class="w-20 font-semibold text-gray-400">Trường Sa</div>`,
        });
        // you can set .my-div-icon styles in CSS

        L.marker([10.910343714000078, 113.47572430900004], {
          icon: ts_label,
        }).addTo(map);

        geojson1.addTo(map);
        setGeojson2(geojson2);
        //fill: #317581;
        //define a function to get and parse geojson from URL
        function customTip() {
          this.unbindTooltip();
          if (!this.isPopupOpen())
            this.bindTooltip("Short description").openTooltip();
        }
        // map.on("click", function (e) {
        //   map.setView(view, scale);
        //   geojson1.addTo(map);
        //   geojson2.removeFrom(map);
        //   setCurrentCountry({});
        // });
      });
    });
  };

  useEffect(() => {
    if (listProvinces.length !== 0 && !isHaveData && !map) {
      api.get("vaccine/provinces").then((response) => {
        const data = response.data;
        setVaccine(data);
      });
      // d3.selectAll(".center-container").remove();
      createMap(listProvinces);
      // setTimeout(() => {
      //   setIsHaveData(false);
      // }, 600000);
    }
  }, [listProvinces, provinceByDay]);

  useMemo(() => {
    if (type === 3) {
      geojson1.eachLayer(function (layer) {
        const countryData = dataRatio.find(
          (ct) => ct.provinceCode === parseInt(layer.feature.properties.CODE)
        );
        !!countryData &&
          layer.setStyle({
            color: "#fff",
            opacity: 1,
            weight: 1,
            fillColor: colorScaleVaccine(
              (100 / countryData.population) * countryData.totalInjected
            ),
            fillOpacity: 1,
          });
      });
    }
    if (type !== 3 && geojson1) {
      geojson1.eachLayer(function (layer) {
        const province = listProvinces.filter(
          (provinceData) => provinceData.id === layer.feature.properties.CODE
        )[0];

        if (province || layer.feature.properties.NAME_1 === "Cao Bằng") {
          const p_case = province
            ? type === 2
              ? province.To_day_case
              : province.Sum_case
            : 0;
          layer.setStyle({
            color: "#fff",
            opacity: 1,
            weight: 1,
            fillColor: colorScale(p_case),
            fillOpacity: 1,
          });
          const today_case = getTodayCase(province);
          const sum_case = province ? province.Sum_case : 0;
        }
      });
    }
    setCurrentCountry({});
  }, [type]);

  useMemo(() => {
    if (map && !currentCountry.id && geojson2 && geojson1) {
      map.setView(view, scale);
      //map.scrollWheelZoom.disable();
      geojson2.removeFrom(map);
      geojson1.addTo(map);
    }

    if (map && geojson2 && geojson1) {
      // const districts = currentCountry.currentProvince.districts;
      if (currentCountry.id) {
        geojson1.eachLayer(function (layer) {
          if (layer.feature.properties.CODE === currentCountry.id) {
            map.fitBounds(layer.getBounds());
            geojson1.removeFrom(map);
            geojson2.addTo(map);
          }
        });
      }
      geojson2.eachLayer(function (layer) {
        if (layer.feature.properties.NAME_1 === currentCountry.name) {
          const province =
            listProvinces.filter(
              (province) => province.name === currentCountry.name
            )[0] || {};
          const today_case = getTodayCase(province);
          const sum_case = province ? province.Sum_case : 0;

          const currentHoverData =
            (province && dataRatio.find((item) => item.id === province.id)) ||
            {};
          const pCase = currentCountry.total_case || 0;
          // const colorCase =
          //   type === 2 ? district.new_case : district.total_case;
          layer.setStyle({ fillColor: colorScale(pCase) });
          // currentCountry.currentProvince &&
          layer.on("mouseover", function (e) {
            const popup = L.popup().setContent(
              tooltipCard1(
                today,
                province?.name,
                today_case,
                province.total_case,
                province.total_death,
                province.percent_case_population.toFixed(2),
                province.case_average_7days.toFixed(0),
                province.death_average_7days.toFixed(0),
                getPercent(
                  currentHoverData.population,
                  currentHoverData.totalInjected
                ),
                getPercent(
                  currentHoverData.population,
                  currentHoverData.totalOnceInjected
                ),
                getPercent(
                  currentHoverData.population,
                  currentHoverData.totalTwiceInjected
                ),
                false
              )
            );
            popup.setLatLng(e.latlng).openOn(map);
            // this.openPopup();
          });
          layer.on("mouseout", function (e) {
            this.closePopup();
          });
          layer.on("click", function (e) {
            map.setView(view, scale);
            geojson1.addTo(map);
            geojson2.removeFrom(map);
            setCurrentCountry({});
          });
        }
        if (!currentCountry.id) {
          layer.setStyle({
            color: "#AFBAC1",
            opacity: 1,
            weight: 1,
            fillColor: "#FAFAF8",
            fillOpacity: 1,
          });
          layer.on("click", function (e) {
            map.setView(view, scale);
            geojson1.addTo(map);
            geojson2.removeFrom(map);
            setCurrentCountry({});
          });
        }
      });
    }
  }, [currentCountry]);

  const dateOptions = [
    {
      value: 7,
      label: "7 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
    { label: "Giai đoạn 1", value: 70 },
    { label: "Giai đoạn 2", value: 80 },
    { label: "Giai đoạn 3", value: 90 },
    { label: "Giai đoạn 4", value: 100 },
  ];
  const dataType = [
    {
      title: "Tổng ca nhiễm",
      color: "#EF4444",
      background: "bg-red-400",
    },
    {
      title: "Ca nhiễm hôm nay",
      color: "#F59E0B",
      background: "bg-gray-400",
    },
    {
      title: "Tiến độ tiêm chủng",
      color: "#10B981",
      background: "bg-green-400",
    },
  ];
  const [filterDay, setFilterDay] = React.useState(0);
  const [openFilter, setOpenFilter] = React.useState(!isMobile);
  return (
    <div style={{}}>
      <div className="filter-map">
        {openFilter ? (
          <div className="bg-white p-3">
            {dataType.map((item, index) => {
              return (
                <div
                  className="bg-gray-100 px-3 mb-3 rounded-sm text-center cursor-pointer hover:bg-gray-200"
                  style={{
                    borderLeft: `3px solid ${item.color}`,
                    backgroundColor: type === index + 1 && item.color,
                    color: type === index + 1 && "white",
                  }}
                  onClick={() => {
                    setType(index + 1);
                  }}
                >
                  {item.title}
                </div>
              );
            })}
            <div className="mb-3">
              <SmallSelect
                title=""
                options={dateOptions}
                value={filterDay}
                onChange={(value) => {
                  setFilterDay(value);
                }}
              />
            </div>
            {type !== 3 && (
              <div className="flex text-sm flex-col">
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-10"
                    style={{ backgroundColor: "#22C55E" }}
                  ></div>
                  <div className="text-center">1-100</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-10"
                    style={{ backgroundColor: "#FFA34F" }}
                  ></div>
                  <div className="text-center">101-500</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-10"
                    style={{ backgroundColor: "#FF392B" }}
                  ></div>
                  <div className="text-center">501-1.000</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-10"
                    style={{ backgroundColor: "#D40638" }}
                  ></div>
                  <div className="text-center">1.001-10.000</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-10"
                    style={{ backgroundColor: "#5D0000" }}
                  ></div>
                  <div className="text-center">{">"}10.000</div>
                </div>
              </div>
            )}
            {type === 3 && (
              <div className="flex flex-col text-sm ">
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-20"
                    style={{ backgroundColor: "#D0F0BF" }}
                  ></div>
                  <div className="text-center">0-3%</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-20"
                    style={{ backgroundColor: "#AAE0B4" }}
                  ></div>
                  <div className="text-center">3-6%</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-20"
                    style={{ backgroundColor: "#6AC680" }}
                  ></div>
                  <div className="text-center">6-10%</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-20"
                    style={{ backgroundColor: "#4CA054" }}
                  ></div>
                  <div className="text-center">10-20%</div>
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-3 w-20"
                    style={{ backgroundColor: "#2E672B" }}
                  ></div>
                  <div className="text-center">20%+</div>
                </div>
              </div>
            )}
            <div
              className="text-center cursor-pointer"
              onClick={() => {
                setOpenFilter(false);
              }}
            >
              Thu gọn
            </div>
          </div>
        ) : (
          <div
            className="bg-white py-3 rounded-tl-lg rounded-bl-lg cursor-pointer"
            onClick={() => {
              setOpenFilter(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        class="viz"
        id="map-container"
        style={{ backgroundColor: "#EDEDED", height: "100vh" }}
      ></div>
    </div>
  );
};

async function getGeoData(url) {
  let response = await fetch(url);
  let data = await response.json();
  return data;
}

const tooltipCard1 = (
  today,
  name,
  day_case,
  total_case,
  totalDeath,
  percentCase,
  case7,
  death7,
  totalInjected,
  totalOnceInjected,
  totalTwiceInjected,
  isDetail
) => {
  return `  
  <div class=""w-full>
  <div class="w-full text-center"><span class="text-lg font-bold">${name}</span></div>
  <div class="border-b-2 my-1 w-full"></div>
  <div class="mb-1">
    <span class="text-lg font-bold text-red-500">CA NHIỄM</span>
    <div class="px-3">
      <ul class="list-disc">
        <li class="flex justify-between">
          <span class="font-bold">• Tổng ca nhiễm:</span>
          <span class="text-red-500 font-bold">${
            total_case ? total_case.toLocaleString("vi-VN") : 0
          }</span>
        </li>
     
       
        <li class="flex justify-between">
          <span class="font-bold">• F0 hôm ${today ? "nay" : "qua"}:</span>
          <span class="font-bold text-red-500">${
            day_case ? day_case.toLocaleString("vi-VN") : 0
          }</span>
        </li>
        <li class="flex justify-between">
          <span class="font-bold">• Đã tử vong:</span>
          <span class="font-bold text-red-500">${
            !!totalDeath ? totalDeath : 0
          }</span>
        </li>
        <li class="flex justify-between">
          <span class="font-bold">• Ca nhiễm mới 7 ngày qua:</span>
          <span class="font-bold text-red-500">${case7}</span>
        </li>
        <li class="flex justify-between">
          <span class="font-bold">• Đã tử vong trong 7 ngày qua:</span>
          <span class="font-bold text-red-500">${death7}</span>
        </li>
        <li class="flex justify-between">
        <span class="font-bold">• Tỷ lệ nhiễm:</span>
        <span class="font-bold text-red-500">${percentCase}%</span>
      </li>
      </ul>
    </div>
  </div>
  <div>
    <div class="flex items-center">
      <span class="text-lg font-bold text-green-500 mr-2"> VACCINE </span>
      <span> (Trên 18 tuổi) </span>
    </div>
    <div class="px-3">
      <ul class="list-disc">
        <li class="flex justify-between">
          <span class="font-bold">• Tổng đã tiêm:</span>
          <span class="text-green-500 font-bold">${
            totalInjected ? totalInjected.replace(/\./g, ",") : 0
          }%</span>
        </li>
        <li class="flex justify-between">
        <span class="">• Tiêm 2 mũi:</span>
        <span class="font-bold">${
          totalTwiceInjected ? totalTwiceInjected.replace(/\./g, ",") : 0
        }%</span>
      </li>
        <li class="flex justify-between">
          <span class="">• Tiêm 1 mũi:</span>
          <span class="font-bold">${
            totalOnceInjected ? totalOnceInjected.replace(/\./g, ",") : 0
          }%</span>
        </li>
      </ul>
    </div>
  </div>
 <div class="w-full text-center">
 <span class="text-gray-500">${isDetail ? "Bấm để xem chi tiết" : ""}</span>
 </div>
</div>`;
};

export default VNLeafletMapActiveCase;
