import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { listProvincesState } from "../../recoil/atom-provinces";
import ProgressBar from "../commons/ProgressBar";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import ButtonSelectInput from "../commons/ButtonSelectInput";
import dayjs from "dayjs";

const ChartByProvince = ({ maxHeight }) => {
  const [data, setData] = useState();
  const [initData, setInitData] = useState();

  const [listProvinces] = useRecoilState(listProvincesState);
  const [search, setSearch] = useState("");

  const filterProvince = ({ name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

 

  const [tabSelected, setTabSelected] = useState(0);
  const [isYesterday, setIsYesterday] = useState(false);
  
  useEffect(() => {
    
    if (data && data[0].last_updated) {
      let split = data[0].last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0])
      let diff = dayjs().diff(lastUpdated, "day");
      console.log(data, diff);
      
      let isThereNewCase = false;

      for (let i = 0; i < data.length; i++) {
        if (data[i].new_case > 0)
        isThereNewCase = true;
        break;
      }

      if (diff > 0 || !isThereNewCase)
        setIsYesterday(true);

      else
        setIsYesterday(false);
    }

  }, [tabSelected]);

  const tabs = [
    {
      value: 0,
      label: "Tổng quan",
    },
    {
      value: 1,
      label: "Tử vong",
    },
    {
      value: 2,
      label: "Ca nhiễm",
    }
  ]

  const labelsToday = [
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Nhiễm mới", key: "case_yesterday", bgcolor: "blue" },
      { label: "Tổng nhiễm", key: "total_case", bgcolor: "red" },
      { label: "Tử vong", key: "total_death", bgcolor: "gray" },
      // { label: "Hồi phục", key: "total_recovered", bgcolor: "green" },
      // { label: "Đang điều trị", key: "total_active", bgcolor: "yellow" },
    ],
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Tổng tử vong", key: "total_death", bgcolor: "blue" },
      { label: "Tử vong hôm nay", key: "new_death", bgcolor: "purple" },
      { label: "Trung bình tử vong 7 ngày", key: "death_average_7days", bgcolor: "red" },
      { label: "Trung bình tử vong 7 ngày / 1.000.000 dân", key: "death_average_million", bgcolor: "gray" },
      { label: "Tỷ lệ tử vong / ca nhiễm", key: "percent_death_population", bgcolor: "green" },
      { label: "Tử vong / 100.000 dân", key: "death_per_100k", bgcolor: "yellow" },
    ],
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Tổng ca nhiễm", key: "total_case", bgcolor: "blue" },
      { label: "Ca nhiễm hôm nay", key: "new_case", bgcolor: "purple" },
      { label: "Trung bình ca nhiễm 7 ngày", key: "case_average_7days", bgcolor: "red" },
      { label: "Trung bình ca nhiễm 7 ngày / 1.000.000 dân", key: "case_average_million", bgcolor: "gray" },
      { label: "Tỷ lệ nhiễm", key: "percent_case_population", bgcolor: "green" },
      { label: "Ca nhiễm / 100.000 dân", key: "case_per_100k", bgcolor: "yellow" },
    ],
  ];

  const labelsYesterday = [
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Nhiễm mới", key: "new_case", bgcolor: "blue" },
      { label: "Tổng nhiễm", key: "total_case", bgcolor: "red" },
      { label: "Tử vong", key: "total_death", bgcolor: "gray" },
      // { label: "Hồi phục", key: "total_recovered", bgcolor: "green" },
      // { label: "Đang điều trị", key: "total_active", bgcolor: "yellow" },
    ],
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Tổng tử vong", key: "total_death", bgcolor: "blue" },
      { label: "Tử vong hôm qua", key: "death_yesterday", bgcolor: "purple" },
      { label: "Trung bình tử vong 7 ngày", key: "death_average_7days", bgcolor: "red" },
      { label: "Trung bình tử vong 7 ngày / 1.000.000 dân", key: "death_average_million", bgcolor: "gray" },
      { label: "Tỷ lệ tử vong / ca nhiễm", key: "percent_death_population", bgcolor: "green" },
      { label: "Tử vong / 100.000 dân", key: "death_per_100k", bgcolor: "yellow" },
    ],
    [
      { label: "Tỉnh thành", key: "name", bgcolor: "" },
      { label: "Tổng ca nhiễm", key: "total_case", bgcolor: "blue" },
      { label: "Ca nhiễm hôm qua", key: "case_yesterday", bgcolor: "purple" },
      { label: "Trung bình ca nhiễm 7 ngày", key: "case_average_7days", bgcolor: "red" },
      { label: "Trung bình ca nhiễm 7 ngày / 1.000.000 dân", key: "case_average_million", bgcolor: "gray" },
      { label: "Tỷ lệ nhiễm", key: "percent_case_population", bgcolor: "green" },
      { label: "Ca nhiễm / 100.000 dân", key: "case_per_100k", bgcolor: "yellow" },
    ],
  ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (listProvinces[0] && !initData) {
      let firstData = initSortData([...listProvinces]);
      let newData = firstData.map((item) => {
        return {
          ...item,
          case_average_million: (item.case_average_7days / (item.population / 1000000)).toFixed(2),
          death_average_million: (item.death_average_7days / (item.population / 1000000)).toFixed(2),
          percent_death_population: ((item.total_death / item.total_case * 100) || 0).toFixed(2),
          percent_case_population: item.percent_case_population.toFixed(2),
          death_per_100k: (item.total_death / (item.population / 100000) || 0).toFixed(2),
          case_per_100k: (item.total_case / (item.population / 100000) || 0).toFixed(2),
        }
      })

      setData(newData);
      setInitData(newData);
    }
  }, [listProvinces]);


  const initSortData = (total, byDay) => {
    // console.log(total);
    let sortedTotal = total.sort(function (a, b) {
      if (a.total_case > b.total_case) return -1;
      else if (a.total_case < b.total_case) return 1;
      else return 0;
    });

    return sortedTotal;
  };

  const handleSort = (itemKey, index) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index);
    else sortByKey(itemKey, 1, index);
  };

  const sortByKey = (objectKey, direction, index = -1) => {
    let sorted;

    if (objectKey === "name") {
      sorted = initData.sort(function (a, b) {
        if (direction === 1) return a.name.localeCompare(b.name);
        else return -a.name.localeCompare(b.name);
      });
    } else {
      sorted = initData.sort(function (a, b) {
        return (parseFloat(b[objectKey]) - parseFloat(a[objectKey])) * direction;
      });
    }
    // console.log(sorted);

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };

  const getMax = (objectKey) => {
    let sorted = initData.sort(function (a, b) {
      return b[objectKey] - a[objectKey];
    });

    return sorted[0][objectKey] * 1.25;
  };

  return (
    <div>
      <div className=" mb-2 my-auto text-gray-500 text-sm items-center">
        {/* <i>Dữ liệu tính từ đầu dịch</i> */}
      </div>
      <div class="mb-3">
        <ButtonSelectInput
          value={tabSelected}
          options={tabs}
          onChange={(value) => {
            setTabSelected(value)
          }}
        />
      </div>
      <div className="lg:w-1/4 w-full flex items-center border rounded-lg ">
        <input
          className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none rounded text-sm"
          id="search"
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Tìm kiếm"
        />
      </div>
      {listProvinces && (
        <div
          className="overflow-y-scroll no-scrollbar"
          style={{ maxHeight: maxHeight }}
        >
          <table className="table w-full border-collapse">
            <thead className="border-b border-black sticky top-0 z-10 bg-white">
              <tr className="p-2">
                {isYesterday ?
                  labelsYesterday[tabSelected].map((item, index) => (
                    <th
                      className="p-2 text-md font-medium text-center cursor-pointer"
                      style={{ width: `${(100 / labelsYesterday[tabSelected].length)}%` }}
                      scope="col"
                      onClick={() => handleSort(item.key, index)}
                    >
                      <div className="w-full text-sm text-center flex flex-row justify-center items-center relative">
                        <div class="px-4">{item.label}</div>
                        <span class="absolute right-0">
                          {sortArray[index] !== 0 ? (
                            <img
                              src={sortArray[index] === 1 ? descend : ascend}
                              className="ml-1 w-4 h-4"
                            />
                          ) : (
                            <img
                              src={noSort}
                              className="ml-1 w-4 h-4 opacity-20"
                            />
                          )}
                        </span>
                      </div>
                    </th>
                  ))
                  :
                  labelsToday[tabSelected].map((item, index) => (
                    <th
                      className="p-2 text-md font-medium text-center cursor-pointer"
                      style={{ width: `${(100 / labelsToday[tabSelected].length)}%` }}
                      scope="col"
                      onClick={() => handleSort(item.key, index)}
                    >
                      <div className="w-full text-sm text-center flex flex-row justify-center items-center relative">
                        <div class="px-4">{item.label}</div>
                        <span class="absolute right-0">
                          {sortArray[index] !== 0 ? (
                            <img
                              src={sortArray[index] === 1 ? descend : ascend}
                              className="ml-1 w-4 h-4"
                            />
                          ) : (
                            <img
                              src={noSort}
                              className="ml-1 w-4 h-4 opacity-20"
                            />
                          )}
                        </span>
                      </div>
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody style={{ transition: "opacity 1s ease-in-out" }}>
              {data &&
                data.filter(filterProvince).map((province) => {
                  return (
                    <>
                      <tr className="border-b">
                        <td
                          className="font-bold p-2"
                          style={{ fontSize: "13px" }}
                        >
                          {province.name}
                        </td>
                        {isYesterday ?
                          labelsYesterday[tabSelected].slice(1).map((item) => {
                            return (
                              <td className="font-weight-bold p-2 text-sm font-bold text-gray-400 text-center">
                                <ProgressBar
                                  count={province[item.key] > 0 ? province[item.key] : 0}
                                  full={getMax(item.key)}
                                  bgcolor={item.bgcolor}
                                  cutoff={50}
                                />
                              </td>
                            )
                          })
                          : labelsToday[tabSelected].slice(1).map((item) => {
                            return (
                              <td className="font-weight-bold p-2 text-sm font-bold text-gray-400 text-center">
                                <ProgressBar
                                  count={province[item.key] > 0 ? province[item.key] : 0}
                                  full={getMax(item.key)}
                                  bgcolor={item.bgcolor}
                                  cutoff={50}
                                />
                              </td>
                            )
                          })
                        }
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ChartByProvince;
