import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import caseByDayState from "../../recoil/atom-case-by-day";
import countrySelected from "../../recoil/atom-country";
import totalState from "../../recoil/atom-total";
import api from "../../services/api";
import currentProvinceState from "../../recoil/atom-current-province";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import dayjs from "dayjs";

const OverView = () => {
  const [total, setTotal] = useRecoilState(totalState);
  const [data, setData] = useState();
  const [currentCountry] = useRecoilState(countrySelected);
  const currentProvince = useRecoilValue(
    currentProvinceState({ province: currentCountry.id })
  );
  const [caseByDay] = useRecoilState(caseByDayState);
  const [dayStatistic, setdayStatistic] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [increase, setIncrease] = useState("~");
  const [caseByDayObj, setCaseByDayObj] = useState({
    label: "hôm nay",
    count: 0,
    day: dayjs().format("DD/MM/YYYY"),
  });

  useEffect(() => {
    api
      .get("/covid/national_total")
      .then((success) => {
        setTotal(success.data);
        setData(success.data);
        setCaseByDayObj({
          label: "hôm nay",
          count: success.data.new_case,
          day: dayjs().format("DD/MM/YYYY"),
        });
      })
      .then(() => {
        setLoaded(true);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (data?.total_case && !currentCountry.id) {
      let inQuarantine = Object.values(caseByDay).map(
        (item) => item.in_quarantine_area
      );
      let outQuarantine = Object.values(caseByDay).map(
        (item) => item.out_quarantine_area
      );
      let inQuarantineNewest = inQuarantine[inQuarantine.length - 1];
      let outQuarantineNewest = outQuarantine[outQuarantine.length - 1];

      if (data?.last_updated) {
        let split = data?.last_updated.split(" ")[0].split("/");
        let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
        let diff = dayjs().diff(lastUpdated, "day");

        if (diff > 0 || (diff === 0 && data.new_case === 0)) {
          inQuarantineNewest = inQuarantine[inQuarantine.length - 2];
          outQuarantineNewest = outQuarantine[outQuarantine.length - 2];
        }
      }

      const _dayStatistic = {
        inQuarantine: inQuarantineNewest,
        outQuarantine: outQuarantineNewest,
      };
      setdayStatistic(_dayStatistic);
    }
  }, [caseByDay, data]);

  useEffect(() => {
    if (data?.last_updated) {
      const _caseByDay = data.case_by_time
        ? Object.values(data.case_by_time)
        : [];
      const lengthArr = _caseByDay.length;

      if (caseByDayObj.label === "hôm qua") {
        const x = _caseByDay[lengthArr - 2] - _caseByDay[lengthArr - 3];
        const y = _caseByDay[lengthArr - 3] - _caseByDay[lengthArr - 4];
        const _increase = x - y;
        setIncrease(_increase);
      } else {
        const x = _caseByDay[lengthArr - 1] - _caseByDay[lengthArr - 2];
        const y = _caseByDay[lengthArr - 2] - _caseByDay[lengthArr - 3];
        const _increase = x - y;
        setIncrease(_increase);
      }
    }
  }, [data, currentProvince]);

  const getComparisonData = (key) => {
    let dataObject = Object.values(data[key]);
    let length = dataObject.length;
    return dataObject[length - 1] - dataObject[length - 2];
  };

  const getWeekPercent = () => {
    if (data?.case_by_time) {
      let dataObject = Object.values(data.case_by_time);
      let length = dataObject.length;
      let lastWeek = dataObject[length - 8] - dataObject[length - 15];
      let thisWeek = dataObject[length - 1] - dataObject[length - 8];

      return ((thisWeek / lastWeek) * 100).toFixed(2);
    }
    return "0";
  };

  useEffect(() => {
    let _data = currentCountry.id
      ? currentProvince.total_case > -1 && { ...currentProvince }
      : { ...total };
    setData(_data);

    if (!!currentCountry.id && currentProvince) {
      const caseIsolation =
        Object.values(currentProvince.case_in_isolation_by_day) || [];
      const caseCommunity =
        Object.values(currentProvince.case_in_community_by_day) || [];
      const caseBlockade =
        Object.values(currentProvince.case_in_blockade_by_day) || [];
      const caseScreening =
        Object.values(currentProvince.case_in_screening_by_day) || [];
      const caseArrival =
        Object.values(currentProvince.another_case_by_day) || [];

      let _dayStatistic = {
        inQuarantine:
          caseIsolation[caseIsolation.length - 1] +
          caseBlockade[caseBlockade.length - 1] +
          caseArrival[caseArrival.length - 1],
        outQuarantine:
          caseCommunity[caseCommunity.length - 1] +
          caseScreening[caseScreening.length - 1],
      };

      if (data?.last_updated) {
        let split = data?.last_updated.split(" ")[0].split("/");
        let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
        let diff = dayjs().diff(lastUpdated, "day");

        if (diff > 0 || (diff === 0 && data.new_case === 0)) {
          _dayStatistic = {
            inQuarantine:
              caseIsolation[caseIsolation.length - 2] +
              caseBlockade[caseBlockade.length - 2] +
              caseArrival[caseArrival.length - 2],
            outQuarantine:
              caseCommunity[caseCommunity.length - 2] +
              caseScreening[caseScreening.length - 2],
          };
        }
      }

      setdayStatistic(_dayStatistic);
    }
  }, [currentCountry]);

  useEffect(() => {
    if (data?.last_updated) {
      let split = data?.last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
      let diff = dayjs().diff(lastUpdated, "day");
      if (diff > 0)
        setCaseByDayObj({
          label: "hôm qua",
          count: data.new_case,
          day: dayjs().subtract(1, "day").format("DD/MM/YYYY"),
        });
      else if (diff === 0 && data.new_case === 0)
        setCaseByDayObj({
          label: "hôm qua",
          count: Object.values(data.case_by_day)[
            Object.values(data.case_by_day).length - 2
          ],
          day: dayjs().subtract(1, "day").format("DD/MM/YYYY"),
        });
      else
        setCaseByDayObj({
          label: "hôm nay",
          count: data.new_case,
          day: dayjs().format("DD/MM/YYYY"),
        });
    }
  }, [data]);

  // const caseByDayObj = () => {
  //   if (data.new_case === 0)
  //     return {
  //       label: "hôm qua",
  //       count: !currentCountry.id
  //         ? Object.values(data.case_by_day)[
  //         Object.values(data.case_by_day).length - 2
  //         ]
  //         : Object.values(dataCurrentProvince.case_by_day)[
  //         Object.values(dataCurrentProvince.case_by_day).length - 2
  //         ],
  //     }
  //     else return {
  //       label: "hôm nay",
  //       count: !currentCountry.id
  //         ? data.new_case
  //         : dataCurrentProvince.new_case,
  //     };
  // }

  const showArrow = (input) => {
    return <img src={input > 0 ? arrowUp : arrowDown} />;
  };

  return (
    <div className="w-full">
      {loaded && (
        <div className=" flex flex-col">
          <div className="mb-3">
            <div className="flex justify-between items-center">
              <span className="font-bold">
                Số ca nhiễm {caseByDayObj.label + " (" + caseByDayObj.day + ")"}
              </span>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div>
                <span className="text-red-600 text-2xl font-bold">
                  +{caseByDayObj?.count?.toLocaleString("vi-VN")}
                </span>
              </div>
            </div>
          </div>
          {!currentProvince.id && (
            <div>
              <div className="h-2 relative bg-gray-200 rounded-lg  mb-3 flex">
                <div
                  className="bg-red-200  rounded-lg  h-2"
                  style={{
                    width: `${
                      (100 /
                        (dayStatistic?.outQuarantine +
                          dayStatistic?.inQuarantine)) *
                        dayStatistic?.inQuarantine || 0
                    }%`,
                  }}
                ></div>
                <div
                  className="bg-red-600  rounded-lg  h-2 z-20 absolute"
                  style={{
                    width: `${
                      (100 /
                        (dayStatistic?.outQuarantine +
                          dayStatistic?.inQuarantine)) *
                        dayStatistic?.outQuarantine || 0
                    }%`,
                  }}
                ></div>
              </div>

              {!currentProvince.id && (
                <div className="text-xs mb-1">
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <div className="w-2 h-2 bg-red-600 rounded-full mr-2"></div>
                      <span>Số ca cộng đồng</span>
                    </div>
                    <span className="text-gray-500">
                      {(
                        (100 /
                          (dayStatistic?.outQuarantine +
                            dayStatistic?.inQuarantine)) *
                          dayStatistic?.outQuarantine || 0
                      )
                        .toFixed(1)
                        .replace(/\./g, ",")}
                      % ({dayStatistic?.outQuarantine?.toLocaleString("vi-VN")}{" "}
                      ca)
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <div className="w-2 h-2 bg-red-200 rounded-full mr-2"></div>
                      <span>Số ca cách ly, phong tỏa</span>
                    </div>
                    <span className="text-gray-500">
                      {(
                        (100 /
                          (dayStatistic?.outQuarantine +
                            dayStatistic?.inQuarantine)) *
                          dayStatistic?.inQuarantine || 0
                      )
                        .toFixed(1)
                        .replace(/\./g, ",")}
                      % ({dayStatistic?.inQuarantine?.toLocaleString("vi-VN")}{" "}
                      ca)
                    </span>
                  </div>
                </div>
              )}

              <div
                className="flex justify-between mb-3"
                style={{ fontSize: "0.7rem" }}
              >
                <div className="flex items-center">
                  <div>{showArrow(increase)}</div>
                  <div>
                    {increase.toLocaleString("vi-VN")} ca so với{" "}
                    {caseByDayObj.label === "hôm qua" ? "hôm kia" : "hôm qua"}
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <div>{showArrow(getWeekPercent())}</div>
                    <div>
                      {getWeekPercent().replace(/\./g, ",")}% so với tuần trước
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!currentCountry.id ? (
            <div className="flex flex-col">
              <span className="italic text-xs">
                Số liệu ca nhiễm cộng đồng được tổng hợp từ Cục Y tế dự phòng -
                Bộ Y tế
              </span>
              <span className="font-bold mb-2">Số liệu cộng dồn</span>
              <div className="border rounded-lg">
                <div className="flex ">
                  <div className="w-1/2 flex flex-col items-center justify-center p-2 border-r border-b">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng ca nhiễm</span>
                      <div className="flex">
                        <span className="mr-1 font-bold text-red-600">
                          {currentCountry.id
                            ? currentCountry.Sum_case?.toLocaleString("vi-VN")
                            : data.total_case?.toLocaleString("vi-VN")}
                        </span>
                        {data.new_case > 0 && (
                          <div className="bg-gray-100  px-1 rounded-lg text-sm">
                            {"+" + data.new_case?.toLocaleString("vi-VN")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2 flex flex-col items-center justify-center p-2 border-b">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng ca hồi phục</span>
                      <div className="flex">
                        <span className="mr-1 font-bold text-green-600">
                          {data.total_recovered?.toLocaleString("vi-VN")}
                        </span>
                        {getComparisonData("recovered_by_time") > 0 && (
                          <div className="bg-gray-100  px-1 rounded-lg text-sm">
                            {"+" +
                              getComparisonData(
                                "recovered_by_time"
                              ).toLocaleString("vi-VN")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex ">
                  <div className="w-1/2 flex flex-col items-center justify-center p-2 border-r">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng ca tử vong</span>
                      <div className="flex">
                        <span className="mr-1 font-bold">
                          {data.total_death?.toLocaleString("vi-VN")}
                        </span>
                        {getComparisonData("death_by_time") > 0 && (
                          <div className="bg-gray-100  px-1 rounded-lg text-sm">
                            {"+" +
                              getComparisonData("death_by_time").toLocaleString(
                                "vi-VN"
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2 flex flex-col items-center justify-center p-2">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng đang điều trị</span>
                      <div className="flex">
                        <span className="mr-1 font-bold text-yellow-500">
                          {data.total_active?.toLocaleString("vi-VN")}
                        </span>
                        {getComparisonData("active_by_time") > 0 && (
                          <div className="bg-gray-100 px-1 rounded-lg text-sm">
                            {"+" +
                              getComparisonData(
                                "active_by_time"
                              ).toLocaleString("vi-VN")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              {/* <div className="text-sm">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                    <span>Số ca điều tra dịch tễ</span>
                  </div>
                  <span className="text-gray-500">
                    {
                      Object.values(dataCurrentProvince.quarantine_by_day)[
                        dataCurrentProvince.quarantine_by_day.length - 1
                      ]?.out_quarantine_area
                    }
                  </span>
                </div>
                <div className="flex justify-between mb-3">
                  <div className="flex items-center">
                    <div className="w-2 h-2 bg-blue-300 rounded-full mr-2"></div>
                    <span>Số ca trong khu cách ly - phong tỏa</span>
                  </div>
                  <span className="text-gray-500">
                    {sumBy(
                      Object.values(dataCurrentProvince.quarantine_by_day),
                      "in_quarantine_area"
                    )}
                  </span>
                </div>
              </div> */}
              <span className="italic text-xs">
                Số liệu ca nhiễm cộng đồng của tỉnh thành được cập nhật từ Cục Y
                tế dự phòng và có thể không thống nhất với dữ liệu công bố của
                Bộ Y tế
              </span>
              <span className="font-bold mb-2">Số liệu cộng dồn</span>
              <div className="border rounded-lg">
                <div className="flex ">
                  <div className="w-1/2 flex flex-col items-center justify-center p-2 border-r">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng ca nhiễm</span>
                      <div className="flex">
                        <span className="mr-1 font-bold text-red-600">
                          {data?.total_case?.toLocaleString("vi-VN")}
                        </span>
                        {data?.new_case > 0 && (
                          <div className="bg-gray-100  px-1  rounded-lg text-sm">
                            {"+" + data?.new_case?.toLocaleString("vi-VN")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col items-center justify-center p-2">
                    <div className="justify-center flex flex-col items-center">
                      <span class="text-sm">Tổng ca tử vong</span>
                      <div className="flex">
                        <span className="mr-1 font-bold">
                          {data?.total_death?.toLocaleString("vi-VN")}
                        </span>
                        {data?.new_death > 0 && (
                          <div className="bg-gray-100  px-1 rounded-lg text-sm">
                            {"+" + data?.new_death?.toLocaleString("vi-VN")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex ">
                    <div className="w-1/2 flex flex-col items-center justify-center p-2 border-r">
                      <div className="justify-center flex flex-col items-center">
                        <span class="text-sm">Tổng ca tử vong</span>
                        <div className="flex">
                          <span className="mr-1 font-bold">
                            {data?.total_death?.toLocaleString(
                              "vi-VN"
                            )}
                          </span>
                          {data?.new_death > 0 && (
                            <div className="bg-gray-100  px-1 rounded-lg text-sm">
                              {"+" +
                                data?.new_death?.toLocaleString(
                                  "vi-VN"
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2 flex flex-col items-center justify-center p-2">
                      <div className="justify-center flex flex-col items-center">
                        <span class="text-sm">Tổng đang điều trị</span>
                        <div className="flex">
                          <span className="mr-1 font-bold text-yellow-500">
                            {data?.total_active?.toLocaleString(
                              "vi-VN"
                            )}
                          </span>
                          {data?.new_active > 0 && (
                            <div className="bg-gray-100  px-1 rounded-lg text-sm">
                              {"+" +
                                data?.new_active?.toLocaleString(
                                  "vi-VN"
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
          )}
          <div className="border-b my-3"></div>
        </div>
      )}
    </div>
  );
};

export default OverView;
