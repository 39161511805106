import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import { provinceByDayTotal } from "../../recoil/atom-province-stats";
import { listProvincesState } from "../../recoil/atom-provinces";

const ProvinceSelector = ({ noCountry = false, noPadding = false }) => {
  const provinceCumulative = useRecoilValue(provinceByDayTotal);
  const [selectedProvince, setSelectedProvince] =
    useRecoilState(countrySelected);
  const listProvinces = useRecoilValue(listProvincesState);

  const [topThree, setTopThree] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const searchList = [...listProvinces].sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  const filterSearch = (data) => {
    return data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  };

  const getTopThree = () => {
    let sorted = Object.values(listProvinces).sort(
      (a, b) => b.totalInjected - a.totalInjected
    );

    sorted.splice(
      sorted.findIndex((item) => parseInt(item.id) === 1),
      1
    );
    sorted.splice(
      sorted.findIndex((item) => parseInt(item.id) === 2),
      1
    );

    return sorted.slice(0, 3);
  };

  useEffect(() => {
    setTopThree(getTopThree);

    let createListProvinces = listProvinces.map((item) => {
      return { value: parseInt(item.id), label: item.name };
    });

    createListProvinces.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

  }, [provinceCumulative, listProvinces]);

  const SearchDropdown = () => {
    return (
      <div className="max-h-64 overflow-auto relative z-50">
        {searchList.filter(filterSearch).map((province) => {
          return (
            <div
              className="m-2 py-1 px-2 cursor-pointer hover:bg-gray-200 rounded-lg"
              onClick={() => {
                setSelectedProvince(
                  listProvinces.find(
                    (item) => parseInt(item.id) === parseInt(province.id)
                  )
                );
                setSearchTerm("");
                setIsOpen(false);
              }}
            >
              <span className="text-sm font-medium">{province.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={
        "flex flex-row h-full my-3 items-center " +
        (noPadding ? "" : " lg:px-5 px-2")
      }
    >
      {topThree && (
        <>
          <div className="lg:flex hidden">
            <div className="flex">
              {!noCountry && (
                <div
                  onClick={() => setSelectedProvince({})}
                  className={
                    "rounded  px-2 py-1 mr-2 border-1 text-xs font-medium cursor-pointer " +
                    (!selectedProvince.id
                      ? "bg-gray-500 text-white"
                      : "bg-gray-200")
                  }
                >
                  Toàn quốc
                </div>
              )}

              <div
                onClick={() => {
                  setSelectedProvince(
                    listProvinces.find((item) => parseInt(item.id) === 1)
                  );
                }}
                className={
                  "rounded  px-2 py-1 mr-2 border-1 text-xs font-medium cursor-pointer " +
                  (parseInt(selectedProvince.id) === 1
                    ? "bg-gray-500  text-white"
                    : "bg-gray-200")
                }
              >
                Hà Nội
              </div>
              <div
                onClick={() => {
                  setSelectedProvince(
                    listProvinces.find((item) => parseInt(item.id) === 2)
                  );
                }}
                className={
                  "rounded px-2 py-1 mr-2 border-1 text-xs font-medium cursor-pointer " +
                  (parseInt(selectedProvince.id) === 2
                    ? "bg-gray-500  text-white"
                    : "bg-gray-200")
                }
              >
                TP HCM
              </div>
              {topThree.map((province, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedProvince(
                        listProvinces.find(
                          (item) => parseInt(item.id) === parseInt(province.id)
                        )
                      );
                    }}
                    className={
                      "rounded px-2 py-1 mr-2 border-1 text-xs font-medium cursor-pointer " +
                      (parseInt(selectedProvince.id) === parseInt(province.id)
                        ? "bg-gray-500  text-white"
                        : "bg-gray-200")
                    }
                  >
                    {province.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative">
            <div
              onClick={() => setIsOpen((prevState) => !prevState)}
              className="rounded px-2 py-1 mr-2 border-1 text-xs font-medium mr-2 cursor-pointer bg-gray-200 flex flex-row"
            >
              <span>{selectedProvince.name || "Toàn quốc"}</span>
              <span className="ml-2 flex items-center pointer-events-none justify-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </div>
            {isOpen && (
              <div className="absolute z-20 top-8 left-0 w-80 bg-gray-50 border rounded-lg shadow-lg">
                <div className="flex items-center mx-2 border rounded-lg my-3">
                  <input
                    className="w-full py-2 px-4 bg-gray-100 text-gray-700 text-sm z-10 h-10 leading-tight focus:outline-none rounded"
                    autoComplete="off"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    placeholder="Tìm kiếm tỉnh/thành phố"
                  />
                </div>
                <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
                <SearchDropdown />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProvinceSelector;
