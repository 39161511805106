import React from "react";
import { useRecoilState } from "recoil";
import countrySelected from "../../recoil/atom-country";

const ExportFileData = ({ data = [] }) => {
  const dateNow = new Date();
  const [currentCountry] = useRecoilState(countrySelected);
  const getNameUrlProvinve = () => {
    return currentCountry.id
      ? currentCountry.name.replace(/\s+/g, "-").toLowerCase()
      : "toan-quoc";
  };

  const dateInFileName =
    dateNow.getDate() +
    "-" +
    (dateNow.getMonth() + 1) +
    "-" +
    dateNow.getFullYear();
  const exportToCsv = function () {
    const dataExport = [];
    const dataSort = currentCountry.id
      ? data.sort(
          (a, b) => b.bluezoner_has_phone_number - a.bluezoner_has_phone_number
        )
      : data.sort(
          (a, b) =>
            b.ratio_bluezoner_has_phone_number_with_population -
            a.ratio_bluezoner_has_phone_number_with_population
        );

    dataSort.forEach((item, index) => {
      delete item.province_name;
      delete item.date;
      if (!currentCountry.id) {
        dataExport.push([
          [index + 1],
          [item.name],
          [item.bluezoner_has_phone_number],
          [item.population],
          [item.smartphone_number],
          [item.ratio_bluezoner_has_phone_number_with_smartphone_number],
          [item.ratio_bluezoner_has_phone_number_with_population],
        ]);
      } else {
        dataExport.push([
          [index + 1],

          [item.district_name],
          [item.district_code],
          [item.bluezoner_has_phone_number],
          [item.smartphone_number],
        ]);
      }
    });
    var CsvString = !currentCountry.id
      ? "\r\nSTT, Phạm vi, PC-Covid có số điện thoại, Dân số, Số smartphone, Tỷ lệ PC-Covid có số điện thoại/ số smart phone (%),Tỷ lệ PC-Covid có số điện thoại/ dân số (%),  \r\n"
      : "\r\nSTT, Huyện,  Mã huyện, Số PC-Covid có số điện thoại, Tổng smartphone  \r\n";
    // const dataExport = data.map((province) => {
    //   return Object.values(province);
    // });
    dataExport.forEach(function (RowItem, RowIndex) {
      RowItem.forEach(function (ColItem, ColIndex) {
        CsvString += ColItem + ",";
      });
      CsvString += "\r\n";
    });
    CsvString =
      "data:text/csv;charset=utf-8,\uFEFF," + encodeURIComponent(CsvString);
    var x = document.createElement("A");
    x.setAttribute("href", CsvString);
    x.setAttribute(
      "download",
      `pc-covid-${getNameUrlProvinve()}-${dateInFileName}.csv`
    );
    document.body.appendChild(x);
    x.click();
  };
  return (
    <div
      onClick={exportToCsv}
      className="bg-blue-500 truncate flex items-center mb-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl cursor-pointer"
    >
      <svg
        class="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
      </svg>
      <span>Tải xuống</span>
    </div>
  );
};

export default ExportFileData;
