import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useRecoilValue } from "recoil";
import caseByDayState from "../../recoil/atom-case-by-day";
import countrySelected from "../../recoil/atom-country";
import currentProvinceState from "../../recoil/atom-current-province";
import ButtonSelectInput from "../commons/ButtonSelectInput";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PopupTime from "../commons/PopupTime";
import { sliceChart, validateSlice } from "../../services/chartRange";
import regression from 'regression';

export const ChartNewCaseOnlyChart = ({
  left = false,
  noLegend = false,
  leftSelectedDate = 60,
  expand = false,
}) => {
  const selectedProvince = useRecoilValue(countrySelected);
  const [byDay, setByDay] = useState();
  const [byDayKeys, setByDayKeys] = useState();
  const [meanLine, setMeanLine] = useState();

  const byDayProvince = useRecoilValue(
    currentProvinceState({ province: selectedProvince.id })
  );

  const byDayState = useRecoilValue(caseByDayState);
  const [selectedDate, setSelectedDate] = useState({
    active: leftSelectedDate,
    start: leftSelectedDate,
    end: 0,
  });

  useEffect(() => {
    setSelectedDate({
      active: leftSelectedDate,
      start: leftSelectedDate,
      end: 0,
    });
  }, [leftSelectedDate]);

  useEffect(() => {
    if (selectedProvince.id) {
      /* TO BE USED ONCE DATA IS CORRECT */

      // let fullKeys = Object.keys(byDayProvince.case_in_blockade_by_day);

      // let inBlockade = Object.values(byDayProvince.case_in_blockade_by_day);
      // let inScreening = Object.values(byDayProvince.case_in_screening_by_day);
      // let inCommunity = Object.values(byDayProvince.case_in_community_by_day);
      // let inIsolation = Object.values(byDayProvince.case_in_isolation_by_day);
      // let onArrival = Object.values(byDayProvince.case_in_screening_by_day);

      // let byDayData = inBlockade.map((day, index) => {
      //   return {
      //     total: (
      //       inBlockade[index]
      //       + inCommunity[index]
      //       + inIsolation[index]
      //       + inScreening[index]
      //       + onArrival[index]
      //     ),
      //     out_quarantine_area: (
      //       inCommunity[index]
      //       + inScreening[index]
      //     ),
      //     in_quarantine_area: (
      //       inBlockade[index]
      //       + inIsolation[index]
      //       + onArrival[index]
      //     )
      //   }
      // })
      // console.log(byDayProvince.case_in_blockade_by_day);

      // setByDay(byDayData);
      // setByDayKeys(fullKeys);

      let keys = Object.keys(byDayProvince.case_by_day);
      let values = Object.values(byDayProvince.case_by_day).map((item) => {
        return {
          total: item,
        };
      });
      setByDay(values);
      setByDayKeys(keys);
    } else {
      setByDay(Object.values(byDayState));
      setByDayKeys(Object.keys(byDayState));
    }
  }, [selectedProvince, byDayProvince, byDayState]);

  useEffect(() => {
    if (!left) {
      if (!byDay || !validateSlice(Object.values(byDay), selectedDate)) {
        setSelectedDate({ active: 60, start: 60, end: 0 });
      }
    }
  }, [selectedDate]);

  const dateOptions = [
    {
      value: 14,
      label: "14 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const options = {
    locale: "vi-VN",
    scales: !expand
      ? {
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
          },
        },
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      }
      : {
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
          },
        },
        x: {
          stacked: true,
          grid: {
            display: true,
          },
        },
      },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        callbacks: {
          beforeBody: function (context) {
            if (!selectedProvince.id) {
              let total = context[0]?.raw.y + context[1]?.raw.y;
              return "Tổng số ca nhiễm: " + total.toLocaleString("vi-VN");
            }
          },
          label: function (context) {
            if (context.dataset.label === " Trung bình 7 ngày") {
              return context.dataset.label + ": " + meanLine[context.dataIndex]?.y?.toLocaleString("vi-VN")
            } else {
              return context.dataset.label + ": " + context.parsed.y.toLocaleString("vi-VN")
            }
          }
        },
      },
      datalabels: {
        align: selectedDate !== 7 ? "left" : "top",
        anchor: "end",
        textStrokeColor: "rgba(255,255,255,1)",
        textStrokeWidth: 2,
        padding: selectedDate !== 7 ? 0 : -10,
        display: noLegend
          ? false
          : function (context) {
            let index = context.dataIndex;
            let data = context.dataset.data;
            // return (data.length - 1 - index) % 10 === 0;
            return (index === data.length - 1)
          },
        formatter: function (value, context) {
          if (context.dataset.label === " Trung bình 7 ngày") {
            return meanLine[context.dataIndex]?.y?.toLocaleString("vi-VN")
          } else {
            return value.y?.toLocaleString("vi-VN") + "\n";
          }
        },
      },

      legend: {
        display: noLegend ? false : true,
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            size: 10,
          },
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const getMeanLine = (cbdObj, label) => {
    const _cbdObj = [...cbdObj];

    let dataset = [];
    let averageData = [];

    for (let i = 0; i < cbdObj.length; i++) {
      let average = 0;

      if (i >= 6) {
        let week = _cbdObj.slice(i - 6, i + 1).map((item) => {
          return item.total;
        });
        average = Math.floor(week.reduce((a, b) => a + b) / 7);
      }

      averageData.push(average);
    }

    averageData = sliceChart(averageData, selectedDate);

    label.forEach((item, index) => {
      dataset.push({ x: item, y: averageData[index] });
    });

    return dataset;
  };

  useEffect(() => {
    if (byDay && byDayKeys) {
      let unsplitted = sliceChart(byDayKeys, selectedDate);

      let _label = unsplitted.map((day) => {
        let splitted = day.split("/");
        return parseInt(splitted[0]) + "/" + parseInt(splitted[1]);
      });

      let meanLine = getMeanLine(byDay, _label);
      setMeanLine(meanLine);
    }
  }, [byDay, byDayKeys, selectedDate])

  const getProvinceData = (data, label) => {
    return data.map((item, index) => {
      return { x: label[index], y: item.total };
    });
  };

  const caseByDay = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "#EF4444");
    gradient1.addColorStop(1, "#FECACA");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient2.addColorStop(0, "#6B7280");
    gradient2.addColorStop(1, "#E5E7EB");

    let sliced = sliceChart(byDay, selectedDate);

    let unsplitted = sliceChart(byDayKeys, selectedDate);

    let _label = unsplitted.map((day) => {
      let splitted = day.split("/");
      return parseInt(splitted[0]) + "/" + parseInt(splitted[1]);
    });

    let meanLine = getMeanLine(byDay, _label);

    let regress = regression.polynomial(meanLine.map((item, index) => {
      return [index, item.y];
    }), { order: 20, precision: 50 });

    regress = regress.points.map((point) => {
      if (point[1] < 0) return [point[0], 0];
      else return point
    })

    let outQuarantine = Object.values(sliced).map((item, index) => {
      return { x: _label[index], y: item.out_quarantine_area || 0 };
    });

    let inQuarantine = Object.values(sliced).map((item, index) => {
      return { x: _label[index], y: item.in_quarantine_area || 0 };
    });

    if (selectedProvince.id) {
      return {
        labels: _label,
        datasets: [
          {
            type: "line",
            label: " Trung bình 7 ngày",
            backgroundColor: "red",
            fill: false,
            borderColor: "red",
            pointColor: "#fff",
            borderWidth: 2,
            pointColor: "red",
            pointStrokeColor: "red",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "red",
            data: regress,
            pointHoverRadius: 4,
          },
          {
            type: "bar",
            label: " Ca nhiễm trong ngày",
            backgroundColor: "#FB923C", // Put the gradient here as a fill color
            // borderColor: "#ef4444",
            // borderWidth: 2,
            fill: "start",
            backgroundColor: "#FB923C",
            data: getProvinceData(sliced, _label),
          },
        ],
      };
    } else {
      return {
        labels: _label,
        datasets: [
          {
            type: "line",
            label: " Trung bình 7 ngày",
            backgroundColor: "red",
            fill: false,
            borderColor: "red",
            pointColor: "#fff",
            pointRadius: 0,
            borderWidth: 2,
            pointColor: "red",
            pointStrokeColor: "red",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "red",
            data: regress,
          },
          {
            type: "bar",
            label: " Ngoài cộng đồng",
            backgroundColor: "#FB923C",
            fill: "start",
            backgroundColor: "#FB923C",
            data: outQuarantine,
          },
          {
            type: "bar",
            label: " Trong khu cách ly",
            backgroundColor: "#D1D5DB",
            fill: "start",
            backgroundColor: "#D1D5DB",
            data: inQuarantine,
          },
        ],
      };
    }
  };

  return (
    <div className="flex flex-col justify-center">
      {/* {!selectedProvince.id ? */}
      <>
        {byDay && (
          <>
            {!left && (
              <div className="mb-3 flex flex-row justify-start items-center my-2">
                <div className="w-full flex flex-row justify-start">
                  <div className="flex ">
                    <ButtonSelectInput
                      title=""
                      options={dateOptions}
                      value={selectedDate.active}
                      onChange={(value) => {
                        setSelectedDate({
                          active: value,
                          start: value,
                          end: 0,
                        });
                      }}
                    />
                  </div>
                  <PopupTime
                    active={selectedDate.active === -1}
                    onSave={(range) => {
                      setSelectedDate({ active: -1, ...range });
                    }}
                  />
                </div>
              </div>
            )}
            <Bar
              data={caseByDay}
              width="10"
              height="6vh"
              plugins={[ChartDataLabels]}
              options={options}
            />
          </>
        )}
      </>
    </div>
  );
};

const ChartNewCase = ({ selectedProvince = null, expand }) => {
  return (
    <div className="flex flex-col justify-center">
      <ChartNewCaseOnlyChart
        selectedProvince={selectedProvince}
        expand={!!expand}
      />
    </div>
  );
};

export default React.memo(ChartNewCase);
