import React, { useEffect, useState } from "react";
import VnMapAllProvide from "../map/MapJson/VnProvince";
import HighchartsReact from "highcharts-react-official";
import HighMaps from "highcharts/highmaps";
import SmallSelect from "../commons/SelectInputSmall";
import { useRecoilValue } from "recoil";
import { quarantineProvinceByDay } from "../../recoil/atom-quarantine";

const MapQuarantine = ({ popup }) => {
  const [chartOptions, setChartOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(7);
  const provinceQuarantine = useRecoilValue(quarantineProvinceByDay({ day: selectedDate }));

  const dateOptions = [
    {
      value: 0,
      label: "Hôm nay",
    },
    {
      value: 7,
      label: "7 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
  ];

  useEffect(() => {
    let provinceKeys = Object.keys(provinceQuarantine).slice(1);
    let provinceValues = Object.values(provinceQuarantine).slice(1);

    let provinceArr = provinceKeys.map((key, index) => {
      return { id: key, ...provinceValues[index] };
    })

    let data = provinceArr.map((province) => {
      let grocery = Math.round(parseInt(province.grocery_and_pharmacy_percent_change_from_baseline));
      let parks = Math.round(parseInt(province.parks_percent_change_from_baseline));
      let retail = Math.round(parseInt(province.retail_and_recreation_percent_change_from_baseline));
      let transit = Math.round(parseInt(province.transit_stations_percent_change_from_baseline));
      let workplaces = Math.round(parseInt(province.workplaces_percent_change_from_baseline));

      let average = Math.round((grocery + parks + retail + transit + workplaces) / 5);


      return {
        name: province.id,
        value: average,
        grocery: grocery,
        parks: parks,
        retail: retail,
        transit: transit,
        workplaces: workplaces,
      };
    })

    const options = {
      chart: {
        style: {
          fontFamily: ''
        }
      },
      title: {
        text: "",
        style: {
          fontSize: "13px",
        },
        align: "center",
        verticalAlign: "bottom",
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              color: "#EEDD66",
            },
          },
        },
      },
      colors: [
        "#FFF7ED",
        "#FFEDD5",
        "#FDBA74",
        "#F97316",
        "#C2410C",
        "#7C2D12"
      ],
      colorAxis: {
        dataClassColor: "category",
        dataClasses: [
          {
            to: -80,
            name: '< -80%'
          },
          {
            from: -80,
            to: -60,
            name: "-80% đến -60%"
          },
          {
            from: -60,
            to: -40,
            name: "-60% đến -40%"
          },
          {
            from: -40,
            to: 20,
            name: "-40% đến -20%"
          },
          {
            from: -20,
            to: 0,
            name: "-20% đến -10%"
          },
          {
            from: -10,
            to: 0,
            name: "< -10%"
          },
        ],
      },

      credits: {
        enabled: true,
      },
      subtitle: {
        text: ``,
      },
      legend: {
        title: {
          text: "",
        },
        align: "center",
        verticalAlign: "bottom",
        floating: false,
        layout: "horizontal",
        valueDecimals: 0,
        backgroundColor: "rgba(255,255,255,0.9)",
        symbolRadius: 0,
        symbolHeight: 14,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#fafafa",
        borderColor: "#aaa",
        formatter: function () {
          return (`  
              <div class="flex">
                <div class="relative">
                  <div class="text-xs rounded right-0 bottom-full text-center">
                    <div><span class="font-bold text-red-500 text-base">${this.point.name} (${this.point.value}%)</span> </div>
                    <div class="h-px bg-gray-400 mt-1 mb-1.5"></div>
                  </div>
                  
                  <div class="mt-2">Siêu thị & Hiệu thuốc: <span class="font-bold text-red-500">${(this.point.grocery >= 0 ? "+" : "") + this.point.grocery}%</span></div>
                  <div class="mt-2">Công viên: <span class="font-bold text-red-500">${(this.point.parks >= 0 ? "+" : "") + this.point.parks}%</span></div>
                  <div class="mt-2">Mua sắm & Giải trí: <span class="font-bold text-red-500">${(this.point.retail >= 0 ? "+" : "") + this.point.retail}%</span></div>
                  <div class="mt-2">Bến xe & Nhà ga: <span class="font-bold text-red-500">${(this.point.transit >= 0 ? "+" : "") + this.point.transit}%</span></div>
                  <div class="mt-2">Nơi làm việc: <span class="font-bold text-red-500">${(this.point.workplaces >= 0 ? "+" : "") + this.point.workplaces}%</span></div>
                </div>
              </div>
            `);
        },
        // pointFormat: ` <div class="tip-chart w-100" >
        // <h3>{point.name}</h3>

        // <div class="item danso mb-2">
        // Tỉ lệ số dân đã tiêm của tỉnh <span class="badge badge-pill badge-danger">{point.value}</span></span>
        // </div>`,
        valueSuffix: "%",
      },
      series: [
        {
          mapData: VnMapAllProvide,
          data: data,


          name: "Việt Nam",
          cursor: "pointer",
          states: {
            hover: {
              color: "#948c94",
            },
          },
          dataLabels: {
            enabled: !1,
            color: "#666666",
            format: "{point.id}",
            style: {
              fontWeight: "normal",
              textOutline: 1,
            },
          },
          joinBy: ["hc-key", "name"],
          // dataLabels: {
          //   enabled: false,
          //   format: "",
          // },
        },
      ],
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "top",
        },
      },
    };

    setChartOptions(options);
    setLoading(false);

  }, [provinceQuarantine])

  return (
    <div className="">
      <div className="w-1/4  pb-5">
        <SmallSelect
          title=""
          options={dateOptions}
          value={selectedDate}
          onChange={(value) => {
            setSelectedDate(value);
          }}
        />
      </div>
      {(!loading && provinceQuarantine && chartOptions) ? (
        <div className="w-full h-full my-auto">
          <HighchartsReact
            containerProps={{ className: 'w-full flex items-stretch', style: popup ? { height: '70vh' } : { height: '65vh' } }}
            constructorType={'mapChart'}
            highcharts={HighMaps}
            options={chartOptions}
          />
        </div>
      ) : (
        <div style={{ paddingTop: "8em", textAlign: "center" }}>
          Đang tải bản đồ...
        </div>
      )}
    </div>
  );


}

export default MapQuarantine;
