import { atom } from "recoil";

const caseByDayState = atom({
  key: "caseByDay", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const caseByDayProvince = atom({
  key: "caseByDayProvince", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export default caseByDayState;
