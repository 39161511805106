import { atom, selectorFamily } from "recoil";
import api from "../services/api";

const topCountryState = atom({
  key: "topCountryState",
  default: [],
});

const countryByDayState = selectorFamily({
  key: "countryByDayState",
  get:
    ({ filter }) =>
    async ({ get }) => {
      if (filter) {
        const responseDetail = await api.get(`/covid/provinces?filter_type=case_by_time`);
        if (responseDetail.error) {
          throw responseDetail.error;
        }
        return responseDetail.data;
      }
      return '';
    },
});

const compareState = (selectorFamily({
  key: "compareState",
  get:
    ({ filter }) =>
    async ({ get }) => {
      if (filter) {
        const responseDetail = await api.get(`/covid/provinces?filter_type=${filter}`);
        if (responseDetail.error) {
          throw responseDetail.error;
        }
        return responseDetail.data;
      }
      return '';
    },
}))

export { topCountryState, countryByDayState, compareState };
