import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { quarantineProvinceByDay } from "../../recoil/atom-quarantine";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import { listProvincesState } from "../../recoil/atom-provinces";
import ProgressBarNegative from "../commons/ProgressBarNegative";

const ChartByDistancing = ({ maxHeight }) => {
  const [data, setData] = useState();
  const [initData, setInitData] = useState();

  const objDBD = useRecoilValue(quarantineProvinceByDay({ day: 0 }));
  const listProvinces = useRecoilValue(listProvincesState);

  const parseDBD = () => {
    let keys = Object.keys(objDBD).slice(1, Object.keys(objDBD).length);
    let values = Object.values(objDBD).slice(1, Object.values(objDBD).length);

    // map keys as id to values
    let unfiltered = values.map((province, index) => {
      return {
        ...province,
        id: keys[index]
      }
    })

    // filter Cao Bằng
    let filtered = unfiltered.filter(function (province) {
      if (parseInt(province.id) === 6) return false;
      else return true;
    })

    // map name
    let complete = filtered.map((province, index) => {
      let _province = { ...province };
      delete _province.province_name;

      let pr = listProvinces.find((item) => {
        return parseInt(item.id) === parseInt(province.id);
      }).name;
      return {
        ..._province,
        name: pr
      }
    });

    return complete;
  }

  const [distancingByDay, setDBD] = useState();

  useEffect(() => {
    Object.keys(objDBD)[0] && listProvinces[0] && setDBD(parseDBD());
  }, [objDBD, listProvinces])

  const [search, setSearch] = useState("");

  const filterProvince = ({ name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  const labels = [
    { label: "Tỉnh/Thành phố", objectKey: "name" },
    {
      label: "Siêu thị & Hiệu thuốc",
      objectKey: "grocery_and_pharmacy_percent_change_from_baseline",
      color: "#14B8A6",
    },
    {
      label: "Công viên",
      objectKey: "parks_percent_change_from_baseline",
      color: "#22C55E",
    },
    {
      label: "Khu dân cư",
      objectKey: "residential_percent_change_from_baseline",
      color: "#A855F7",
    },
    {
      label: "Mua sắm & Giải trí",
      objectKey: "retail_and_recreation_percent_change_from_baseline",
      color: "#3B82F6",
    },
    {
      label: "Bến xe & Nhà ga",
      objectKey: "transit_stations_percent_change_from_baseline",
      color: "#EC4899",
    },
    {
      label: "Nơi làm việc",
      objectKey: "workplaces_percent_change_from_baseline",
      color: "#F97316",
    },
  ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (distancingByDay && distancingByDay[0] && !initData) {
      setData([...distancingByDay]);
      setInitData([...distancingByDay]);
    }
  }, [distancingByDay]);

  const handleSort = (itemKey, index) => {
    let direction = sortArray[index];

    // if currently descending, sort ascending
    if (direction === 1) sortByKey(itemKey, -1, index);

    // otherwise sort descending
    else sortByKey(itemKey, 1, index);
  };

  const sortByKey = (objectKey, direction, index = -1) => {
    let sorted;

    // sort by name
    if (objectKey === "name") {
      sorted = initData.sort(function (a, b) {
        if (direction === 1) return a.name.localeCompare(b.name);
        else return -a.name.localeCompare(b.name);
      });

    //sort by objectKey
    } else {
      sorted = initData.sort(function (a, b) {
        if (parseInt(a[objectKey]) > parseInt(b[objectKey])) return direction * -1;
        else if (parseInt(a[objectKey]) < parseInt(b[objectKey])) return direction;
        else return 0;
      });
    }

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };

  return (
    <div>
      <div className=" mb-2 my-auto text-gray-500 text-sm items-center">
        {/* <i>Dữ liệu tính từ đầu dịch</i> */}
      </div>
      <div className="lg:w-1/4 w-full flex items-center border rounded-lg ">
        <input
          className="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none rounded text-sm"
          id="search"
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Tìm kiếm"
        />
      </div>
      {distancingByDay && (
        <div
          className="overflow-y-scroll no-scrollbar"
          style={{ maxHeight: maxHeight }}
        >
          <table className="table w-full border-collapse">
            <thead className="border-b border-black sticky top-0 z-10 bg-white">
              <tr className="p-2">
                {labels.map((item, index) => (
                  <th
                    className="p-2 text-md font-medium text-center cursor-pointer"
                    scope="col"
                    style={{ width: `${(100 / 7)}%` }}
                    onClick={() => handleSort(item.objectKey, index)}
                  >
                    <div className="w-full text-center flex flex-row justify-center items-center">
                      {item.label}
                      <span>
                        {sortArray[index] !== 0 ? (
                          <img
                            src={sortArray[index] === 1 ? descend : ascend}
                            className="ml-1 w-4 h-4"
                          />
                        ) : (
                          <img
                            src={noSort}
                            className="ml-1 w-4 h-4 opacity-20"
                          />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ transition: "opacity 1s ease-in-out" }}>
              {data &&
                data
                  .filter(filterProvince)
                  .map((province) => {
                    return (
                      <>
                        <tr className="border-b">
                          <td
                            className="font-bold p-2"
                            style={{ fontSize: "13px" }}
                          >
                            {province.name}
                          </td>
                          {labels.slice(1, labels.length).map((item) => {
                            return (
                              <td className="font-weight-bold p-2 text-sm font-bold text-gray-400 text-center">
                                <ProgressBarNegative
                                  rawCount={parseInt(province[item.objectKey])}
                                  bgcolor={item.color}
                                />
                              </td>
                            )
                          })}
                        </tr>
                      </>
                    );
                  })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ChartByDistancing;
