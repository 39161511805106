import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { listProvincesState } from "../../recoil/atom-provinces";
import ProgressBar from "../commons/ProgressBar";

import ascend from "../../assets/icons/sortAscending.svg";
import descend from "../../assets/icons/sortDescending.svg";
import noSort from "../../assets/icons/noSort.svg";
import dayjs from "dayjs";
import bluezoneState from "../../recoil/atom-bluezone";
import ExportFileData from "./ExportFileData";
import countrySelected from "../../recoil/atom-country";

const TableBluezone = ({ maxHeight }) => {
  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const [data, setData] = useState();
  const [initData, setInitData] = useState();
  const dataBluezone = useRecoilValue(bluezoneState());
  const [listProvinces] = useRecoilState(listProvincesState);
  const [search, setSearch] = useState("");

  const filterProvince = ({ name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  useEffect(() => {
    if (data && data[0].last_updated) {
      let split = data[0].last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
      let diff = dayjs().diff(lastUpdated, "day");
      let isThereNewCase = false;

      for (let i = 0; i < data.length; i++) {
        if (data[i].new_case > 0) isThereNewCase = true;
        break;
      }
    }
  }, []);

  const labelsNational = !currentCountry.id
    ? [
      { label: "Phạm vi", key: "province_name", bgcolor: "" },
      {
        label: "PC-Covid có số điện thoại",
        key: "bluezoner_has_phone_number",
        bgcolor: "blue",
      },
      { label: "Dân số", key: "population", bgcolor: "red" },
      { label: "Số smart phone", key: "smartphone_number", bgcolor: "green" },
      {
        label: "Tỷ lệ PC-Covid có số điện thoại/ số smart phone (%)",
        key: "ratio_bluezoner_has_phone_number_with_smartphone_number",
        bgcolor: "yellow",
        isPercent: true,
      },
      {
        label: "Tỷ lệ PC-Covid có số điện thoại/ dân số (%)",
        key: "ratio_bluezoner_has_phone_number_with_population",
        bgcolor: "gray",
        isPercent: true,
      },
    ]
    : [
      { label: "Huyện", key: "district_name", bgcolor: "" },
      { label: "Mã huyện", key: "district_name", bgcolor: "" },
      {
        label: "Số PC-Covid có số điện thoại",
        key: "bluezoner_has_phone_number",
        bgcolor: "blue",
        isPercent: true,
      },
      {
        label: "Tổng smartphone",
        key: "smartphone_number",
        bgcolor: "green",
        isPercent: true,
      },
    ];

  const [sortArray, setSort] = useState([0, 0, 0, 0, 0, 1]);
  useEffect(() => {
    if (!!currentCountry.id) {
      const dataCurrentCountry = dataBluezone.district.filter(
        (item) => item.province_code === currentCountry.id
      );
      setData(
        sortDataProvince(
          dataCurrentCountry.map((item) => {
            return {
              ...item,
              name: item.district_name,
            };
          })
        )
      );
    }
  }, [currentCountry]);

  const sortDataProvince = (total, byDay) => {
    let sortedTotal = total.sort(function (a, b) {
      if (
        parseInt(a.bluezoner_has_phone_number) >
        parseInt(b.bluezoner_has_phone_number)
      )
        return -1;
      else if (
        parseInt(a.bluezoner_has_phone_number) <
        parseInt(b.bluezoner_has_phone_number)
      )
        return 1;
      else return 0;
    });

    return sortedTotal;
  };

  useEffect(() => {
    if (!currentCountry.id) {
      let newData = dataBluezone.province.map((item) => {
        return {
          ...item,
          id: item.province_code,
          name: item.province_name,
          ratio_bluezoner_has_phone_number_with_smartphone_number: (
            item.ratio_bluezoner_has_phone_number_with_smartphone_number * 100
          ).toFixed(2),
          ratio_bluezoner_has_phone_number_with_population: (
            item.ratio_bluezoner_has_phone_number_with_population * 100
          ).toFixed(2),
        };
      });
      if (newData) {
        setData(initSortData(newData));
        setInitData(newData);
      }
    }
  }, [dataBluezone, currentCountry]);

  const initSortData = (total, byDay) => {
    let sortedTotal = total?.sort(function (a, b) {
      return b.ratio_bluezoner_has_phone_number_with_population - a.ratio_bluezoner_has_phone_number_with_population;
    });

    return sortedTotal;
  };

  const handleSort = (itemKey, index) => {
    let direction = sortArray[index];
    if (direction === 1) sortByKey(itemKey, -1, index);
    else sortByKey(itemKey, 1, index);
  };

  const sortByKey = (objectKey, direction, index = -1) => {
    let sorted;

    if (objectKey === "province_name") {
      sorted = data.sort(function (a, b) {
        if (direction === 1) return a.name.localeCompare(b.name);
        else return -a.name.localeCompare(b.name);
      });
    } else {
      sorted = data.sort(function (a, b) {
        return (
          (parseFloat(b[objectKey]) - parseFloat(a[objectKey])) * direction
        );
      });
    }

    if (index > -1) {
      let newSort = [...sortArray].map((item, idx) => {
        if (idx === index) return direction;
        else return 0;
      });
      setSort(newSort);
    }

    setData([...sorted]);
  };

  const getMax = (objectKey) => {
    let sorted = initData.sort(function (a, b) {
      return b[objectKey] - a[objectKey];
    });
    return sorted[0][objectKey] * 1.25;
  };

  return (
    <div>
      <div className="flex px-2 md:p-0 justify-between">
        <div className="lg:w-1/4 w-full flex items-center border rounded-lg mb-3">
          <input
            className=" py-2 px-4 text-gray-700 leading-tight focus:outline-none rounded text-sm"
            id="search"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Tìm kiếm"
          />
        </div>
        {true && <ExportFileData data={data} />}
      </div>
      {listProvinces && (
        <div
          className="overflow-y-scroll no-scrollbar"
          style={{ maxHeight: maxHeight }}
        >
          <table className="table w-full border-collapse">
            <thead className="border-b border-black sticky top-0 z-10 bg-white">
              <tr className="p-2">
                <th
                  className="p-2 text-md font-medium text-center cursor-pointer"
                  scope="col"
                >
                  STT
                </th>
                {/* {currentCountry.id && (
                  <th
                    className="p-2 w-32 text-md font-medium text-center cursor-pointer"
                    scope="col"
                  >
                    Mã huyện
                  </th>
                )} */}
                {labelsNational.map((item, index) => (
                  <th
                    className="p-2 text-md font-medium text-center cursor-pointer"
                    // style={{
                    //   width: `${100 / labelsNational.length}%`,
                    // }}
                    scope="col"
                    onClick={() => handleSort(item.key, index)}
                  >
                    <div className="w-full text-sm flex flex-row justify-center items-center relative">
                      <div class="px-4 w-72 lg:w-auto">{item.label}</div>
                      <span class="absolute right-0">
                        {sortArray[index] !== 0 ? (
                          <img
                            src={sortArray[index] === 1 ? descend : ascend}
                            className="ml-1 w-4 h-4"
                          />
                        ) : (
                          <img
                            src={noSort}
                            className="ml-1 w-4 h-4 opacity-20"
                          />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ transition: "opacity 1s ease-in-out" }}>
              {data &&
                data.filter(filterProvince).map((province, id) => {
                  return (
                    <tr
                      className="border-b cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        !currentCountry.id && setCurrentCountry(province);
                      }}
                    >
                      <td
                        className="font-bold p-2"
                        style={{ fontSize: "13px" }}
                      >
                        {id + 1}
                      </td>
                      {/* {currentCountry.id && (
                        <th
                          className="p-2 text-md font-medium text-center cursor-pointer"
                          scope="col"
                        >
                          {province.district_code}
                        </th>
                      )} */}
                      <td
                        className="font-bold p-2"
                        style={{ fontSize: "13px" }}
                      >
                        {province.name}
                      </td>
                      {currentCountry.id && (
                        <td
                          className="font-bold p-2"
                          style={{ fontSize: "13px" }}
                        >
                          {province.district_code}
                        </td>
                      )}
                      {labelsNational
                        .slice(currentCountry.id ? 2 : 1)
                        .map((item) => {
                          return (
                            <td className="font-weight-bold p-2 text-sm font-bold text-gray-400">
                              <ProgressBar
                                count={province[item.key]}
                                full={getMax(item.key)}
                                bgcolor={item.bgcolor}
                                cutoff={50}
                                {...item}
                              />
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TableBluezone;
