import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  overViewVaccineTime,
} from "../../recoil/atom-overviewvaccine";
import ButtonSelectInput from "../commons/ButtonSelectInput";
import ChartDataLabels from "chartjs-plugin-datalabels";
import countrySelected from "../../recoil/atom-country";
import PopupTime from "../commons/PopupTime";
import { sliceChart, validateSlice } from "../../services/chartRange";

const ChartVaccineByTime = () => {
  const [dataVaccineTime] = useRecoilState(overViewVaccineTime);
  const [selectedDate, setSelectedDate] = useState({ active: 60, start: 60, end: 0 });
  const selectedProvince = useRecoilValue(countrySelected);

  const options = {
    locale: 'vi-VN',
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 8,
        },
        min: 0,   
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        align: selectedDate !== 7 ? "left" : "top",
        anchor: "end",
        padding: selectedDate !== 7 ? 0 : -10,
        textStrokeColor: "rgba(255,255,255,1)",
        textStrokeWidth: 2,
        display: function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return index === data.length - 1;
        },
        formatter: function (value) {
          return value.toLocaleString('vi-VN') + "\n";
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const dateOptions = [

    {
      value: 7,
      label: "7 ngày",
    },
    {
      value: 30,
      label: "30 ngày",
    },
    {
      value: 60,
      label: "60 ngày",
    },
    {
      value: 0,
      label: "Mọi lúc",
    },
  ];

  const dataSet = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "#D8EEDF");
    gradient1.addColorStop(1, "#D8EEDF");

    var opacity = 0.1; //55% visible
    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    // gradient2.addColorStop(1,"transparent");
    gradient2.addColorStop(0,'rgba(0,0,0,'+opacity+')');

    let onceInjected = Object.values(dataVaccineTime.onceInjected);
    let twiceInjected = Object.values(dataVaccineTime.twiceInjected);

    if (onceInjected[0] && twiceInjected[0] && !validateSlice(onceInjected, selectedDate)) {
      setSelectedDate({ active: 60, start: 60, end: 0 })
    }

    const slicedOnce = sliceChart(onceInjected, selectedDate)

    const slicedTwice = sliceChart(twiceInjected, selectedDate)


    let unsplitted = (dataVaccineTime.onceInjected && Object.keys(dataVaccineTime.onceInjected)) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _label = unsplitted.map((day) => {
      let splitted = day.split("/");
      return parseInt(splitted[0]) + "/" + parseInt(splitted[1])
    })

    return {
      labels: _label,
      datasets: [
        {
          label: "Đủ liều",
          backgroundColor: gradient2,
          borderColor: "#374151",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          fill: "start",
          backgroundColor: gradient2,
          pointStrokeColor: "#D1D5DB",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#D1D5DB",
          data: slicedTwice,
        },
        {
          label: "Chưa đủ liều",
          backgroundColor: gradient1,
          // borderColor: "#B91C1C",
          borderColor: "#01662A",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          fill: "start",
          backgroundColor: gradient1,
          pointStrokeColor: "#EF4444",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#EF4444",
          data: slicedOnce,
        },

      ],
    };
  };

  return (
    <div className="flex flex-col justify-center">
      {dataVaccineTime && dataVaccineTime.onceInjected && (
        <>
          {selectedProvince.id ?
            <div className=" mb-3 pl-5 my-auto text-red-500 text-sm font-medium items-center"><i>Dữ liệu hiện đang được cập nhật.</i></div>
            :
            <>
              <div className="mb-3 flex flex-row justify-start items-center my-2">
                <div className="w-full flex flex-row justify-start">
                  <div className="flex ">
                    <ButtonSelectInput
                      title=""
                      options={dateOptions}
                      value={selectedDate.active}
                      onChange={(value) => {
                        setSelectedDate({ active: value, start: value, end: 0 });
                      }}
                    />
                  </div>
                  <PopupTime
                    active={selectedDate.active === -1}
                    onSave={(range) => {
                      setSelectedDate({ active: -1, ...range })
                    }}
                  />
                </div>
              </div>
              <Line
                data={dataSet}
                width="10"
                height="6vh"
                plugins={[ChartDataLabels]}
                options={options}
              />
            </>
          }
        </>
      )}
    </div>
  );
};

export default React.memo(ChartVaccineByTime);
