import React from "react";

const LoadingNews = () => {
  const arr = [0, 0, 0, 0, 0, 0];
  return (
    <div className="">
      <div className="animate-pulse flex flex-col h-full">
        <div className="flex flex-col lg:grid xl:grid-cols-2 lg:gap-6  lg:mb-0">
          {arr.map((item, index) => {
            return (
              <div
                className={"mx-5 py-2 flex flex-row  justify-start items-start"}
              >
                <div className="rounded-lg lg:w-48 w-36 h-24 bg-gray-200" />
                <div className="pl-2 flex flex-col w-full">
                  <div className="bg-gray-200 h-6 mt-1 mb-2"></div>
                  <div className="bg-gray-200 h-3 mb-1"></div>
                  <div className="bg-gray-200 h-3 mb-1"></div>
                  <div className="bg-gray-200 h-3 mb-1"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LoadingNews;
