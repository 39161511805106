import React from "react";

const Draft = () => {
  return (
    <div class="w-1/2">
      <span class="text-2xl font-bold">Hà Nội</span>
      <div class="border-b-2 my-3 w-full"></div>
      <div class="mb-3">
        <span class="text-xl font-bold text-red-500">CA NHIỄM</span>
        <div class="px-3">
          <ul class="list-disc">
            <li class="flex justify-between">
              <span class="font-bold">• Tổng ca nhiễm:</span>
              <span class="text-red-500">2000</span>
            </li>
            <li class="flex justify-between">
              <span class="">• Tỉ lệ nhiễm:</span>
              <span class="font-bold">0.21%</span>
            </li>
            <li class="flex justify-between">
              <span class="">• Ca nhiễm / 100k dân:</span>
              <span class="font-bold">2%</span>
            </li>
            <li class="flex justify-between">
              <span class="font-bold">• F0 hôm nay:</span>
              <span class="font-bold text-red-500">239</span>
            </li>
            <li class="flex justify-between">
              <span class="">• F0 cộng đồng:</span>
              <span class="font-bold ">239</span>
            </li>
            <li class="flex justify-between">
              <span class="">• F0 khu cách ly:</span>
              <span class="font-bold ">200</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="flex items-center">
          <span class="text-xl font-bold text-green-500 mr-2"> VACCINE </span>
          <span> (Trên 18 tuổi) </span>
        </div>
        <div class="px-3">
          <ul class="list-disc">
            <li class="flex justify-between">
              <span class="font-bold">• Tổng đã tiêm:</span>
              <span class="text-green-500">33%</span>
            </li>
            <li class="flex justify-between">
              <span class="">• Tiêm 1 mũi:</span>
              <span class="font-bold">31%</span>
            </li>
            <li class="flex justify-between">
              <span class="">• Tiêm 2 mũi:</span>
              <span class="font-bold">2%</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Draft;
