import React from 'react';

const PopupMap = (props) => {
    const {
        name,
        total_case,
        new_case,
        day_case,
        total_death,
        case_average_7days,
        death_average_7days,
        percentCase,
        totalInjected,
        totalOnceInjected,
        totalTwiceInjected,
        isDetail,
      } = props;
      return (
        <div className="" w-full>
          <div className="w-full text-center">
            <span className="text-lg font-bold">{name}</span>
          </div>
          <div className="border-b-2 my-1 w-full"></div>
          <div className="mb-1">
            <span className="text-lg font-bold text-red-500">CA NHIỄM</span>
            <div className="px-3">
              <ul className="list-disc">
                <li className="flex justify-between">
                  <span className="font-bold">• Tổng ca nhiễm:</span>
                  <span className="text-red-500 font-bold">
                    {total_case ? total_case.toLocaleString("vi-VN") : 0}
                  </span>
                </li>
    
                <li className="flex justify-between">
                  <span className="font-bold">
                    • Ca nhiễm mới:
                  </span>
                  <span className="font-bold text-red-500">
                    {new_case.toLocaleString("vi-VN") }
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold">• Đã tử vong:</span>
                  <span className="font-bold text-red-500">
                    {!!total_death ? total_death : 0}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold">• Ca nhiễm trung bình 7 ngày qua:</span>
                  <span className="font-bold text-red-500">{case_average_7days.toFixed(0)}</span>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold">• Đã tử vong trung bình 7 ngày qua:</span>
                  <span className="font-bold text-red-500">{death_average_7days.toFixed(0)}</span>
                </li>
                <li className="flex justify-between">
                  <span className="font-bold">• Tỷ lệ nhiễm:</span>
                  <span className="font-bold text-red-500">{percentCase}</span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <span className="text-lg font-bold text-green-500 mr-2">
                {" "}
                VACCINE{" "}
              </span>
              <span> (Trên 18 tuổi) </span>
            </div>
            <div className="px-3">
              <ul className="list-disc">
                <li className="flex justify-between">
                  <span className="font-bold">• Tổng đã tiêm:</span>
                  <span className="text-green-500 font-bold">
                    {totalInjected ? totalInjected.toLocaleString("vi-VN") : 0}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="">• Tiêm 2 mũi:</span>
                  <span className="font-bold">
                    {totalTwiceInjected
                      ? totalTwiceInjected.toLocaleString("vi-VN")
                      : 0}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="">• Tiêm 1 mũi:</span>
                  <span className="font-bold">
                    {totalOnceInjected.toLocaleString("vi-VN") }
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full text-center">
            <span className="text-gray-500">
              {isDetail ? "Bấm để xem chi tiết" : ""}
            </span>
          </div>
        </div>
      );
};

export default PopupMap;