import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import countrySelected from "../../recoil/atom-country";
import currentProvinceState from "../../recoil/atom-current-province";
import totalState from "../../recoil/atom-total";
import InfoPopup from "../commons/InformationPopup";

const TopCards = () => {
  const [total] = useRecoilState(totalState);
  const selectedProvince = useRecoilValue(countrySelected);
  const [isYesterday, setIsYesterday] = useState({
    yesterday: false,
    todayNone: false,
  });
  const province = useRecoilValue(
    currentProvinceState({ province: selectedProvince.id })
  );
  const [data, setData] = useState();

  // check if last updated is yesterday
  useEffect(() => {
    let _data = selectedProvince.id
      ? province.total_case > -1 && { ...province }
      : { ...total };

    if (_data?.recovered_by_day) {

      let split = _data?.last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0])
      let diff = dayjs().diff(lastUpdated, "day");

      if (diff > 0)
        setIsYesterday({ yesterday: true, todayNone: true });

      else if (diff === 0 && _data?.new_case === 0)
        setIsYesterday({ yesterday: false, todayNone: true });

      else
        setIsYesterday({ yesterday: false, todayNone: false });
    }

  }, [total, province, selectedProvince]);

  useEffect(() => {
    let _data = selectedProvince.id
      ? province.total_case > -1 && { ...province }
      : { ...total };

    // if last_updated for total returns yesterday
    if (isYesterday.yesterday && isYesterday.todayNone) {
      // check if current province also returns yesterday
      let split = _data.last_updated.split(" ")[0].split("/");
      let lastUpdated = dayjs(split[2] + "/" + split[1] + "/" + split[0]);
      let diff = dayjs().diff(lastUpdated, "day");

      // if no (data is today), set yesterday data
      if (diff === 0) setTodayYesterday(_data);
      else setData({ ..._data });

      // if last_updated for total return today AND today's new case is 0, set everything to yesterday
    } else if (!isYesterday.yesterday && isYesterday.todayNone) {
      setTodayYesterday(_data);

      // if last_updated is today, and there are new cases, change nothing
    } else {
      setData({ ..._data });
    }
  }, [isYesterday]);

  // set data to yesterday
  const setTodayYesterday = (rawData) => {
    let _data = { ...rawData };

    _data.new_case =
      Object.values(_data.case_by_day)[
        Object.values(_data.case_by_day).length - 2
      ] || 0;
    _data.new_death =
      Object.values(_data.death_by_day)[
        Object.values(_data.death_by_day).length - 2
      ] || 0;
    _data.new_active =
      Object.values(_data.active_by_day)[
        Object.values(_data.active_by_day).length - 2
      ] || 0;
    _data.new_recovered =
      Object.values(_data.recovered_by_day)[
        Object.values(_data.recovered_by_day).length - 2
      ] || 0;

    setData({ ..._data });
  };

  const cardLabels = [
    {
      title: "Tổng ca nhiễm",
      variableKey: "total_case",
      arrayKey: "new_case",
      averageKey: "case_by_day",
      compareKey: "compare_case_7_days_before",
      percentKey: "percent_case_/_100000",
      percentNumKey: "case_/_100000",
      color: "red",
      colorCode: "#EF4444",
    },
    {
      title: "Tổng ca tử vong",
      variableKey: "total_death",
      arrayKey: "new_death",
      compareKey: "compare_death_7_days_before",
      percentKey: "percent_death_/_sum_case",
      percentNumKey: "death_/_100000",
      color: "gray",
      colorCode: "#6B7280",
    },
    {
      title: "Tổng ca hồi phục",
      variableKey: "total_recovered",
      arrayKey: "new_recovered",
      averageKey: "recovered_by_day",
      compareKey: "compare_recovered_7_days_before",
      percentKey: "percent_recovered_/_100000",
      percentNumKey: "recovered_/_100000",
      color: "green",
      colorCode: "#10B981",
    },
    {
      title: "Tổng đang điều trị",
      variableKey: "total_active",
      arrayKey: "new_active",
      averageKey: "active_by_day",
      compareKey: "compare_active_7_days_before",
      percentKey: "percent_active_/_100000",
      percentNumKey: "active_/_100000",
      color: "yellow",
      colorCode: "#F59E0B",
    },
  ];

  const cardLabelsProvince = [
    {
      title: "Tổng ca nhiễm",
      variableKey: "total_case",
      arrayKey: "new_case",
      averageKey: "case_by_day",
      compareKey: "compare_case_7_days_before",
      percentKey: "percent_case_/_100000",
      percentNumKey: "case_/_100000",
      color: "red",
      colorCode: "#EF4444",
    },
    {
      title: "Tổng ca tử vong",
      variableKey: "total_death",
      arrayKey: "new_death",
      compareKey: "compare_death_7_days_before",
      percentKey: "percent_death_/_sum_case",
      percentNumKey: "death_/_100000",
      color: "gray",
      colorCode: "#6B7280",
    },
  ];

  const DataLine = ({ title, data, color, popupText }) => [
    <div className="flex flex-row w-full text-xs font-medium items-center mt-1">
      <InfoPopup>
        <div>{popupText}</div>
      </InfoPopup>
      <div className="w-full text-left">{title}</div>
      <div
        className={`text-right text-white font-normal bg-${color}-500 rounded`}
        style={
          color == "yellow"
            ? {
                backgroundColor: "#F59E0B",
                padding: "0.125rem 0.25rem",
              }
            : { padding: "0.125rem 0.25rem" }
        }
      >
        {data}
      </div>
    </div>,
  ];

  const Card = ({
    title,
    variableKey,
    arrayKey,
    averageKey = undefined,
    compareKey,
    percentKey,
    percentNumKey,
    color,
    colorCode,
    idx,
  }) => {
    let sevenDays;

    if (idx !== 1 && idx !== 3 && averageKey && data[averageKey]) {
      let average = Object.values(data[averageKey]);
      sevenDays = Math.floor(
        average
          .slice(average.length - 8, average.length - 1)
          .reduce((a, b) => a + b) / 7
      ).toLocaleString("vi-VN");
    }

    return (
      <div className="w-1/2 px-1 mb-3 ">
        <div
          className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-${color}-500 text-black h-full`}
          style={{ borderColor: colorCode }}
        >
          <div className="flex-1">
            <div className="flex flex-row items-center">
              <div className="text-sm font-medium mr-1">{title}</div>
              {idx === 2 && (
                <div className="pt-0.5">
                  <InfoPopup>
                    <div className="text-xs italic">
                      Dữ liệu hồi phục không còn được Bộ Y tế cập nhật. Dữ liệu
                      này chỉ sử dụng để tham khảo.
                    </div>
                  </InfoPopup>
                </div>
              )}
            </div>
            <div className={`text-2xl font-bold text-${color}-500 pt-1 pb-2`}>
              {data[variableKey]?.toLocaleString("vi-VN")}
            </div>
            {data[arrayKey] > -1000000 && (
              <div
                className={`text-xs font-bold text-${color}-500 pb-1 flex flex-row`}
              >
                {/* {data[arrayKey] >= 0 ? <ChevronUp color={color} /> : <ChevronDown color={color} />} */}
                <span>
                  {(data[arrayKey] >= 0 ? "+" : "") +
                    data[arrayKey]?.toLocaleString("vi-VN")}
                </span>
                <span className="pl-1 text-black font-normal">
                  ca hôm {isYesterday.todayNone ? "qua" : "nay"}
                </span>
              </div>
            )}

            {data[compareKey] > -1000000 && (
              <div className={`text-xs font-bold text-${color}-500 pb-2`}>
                {(data[compareKey] >= 0 ? "+" : "") +
                  data[compareKey]?.toLocaleString("vi-VN")}
                <span className="pl-1 text-black font-normal">7 ngày qua</span>
              </div>
            )}
          </div>

          <div className="w-full">
            {idx === 0 && (
              <>
                <DataLine
                  title="Ca nhiễm/ngày"
                  data={sevenDays}
                  color={color}
                  popupText="Số ca nhiễm trung bình/ngày trong 7 ngày qua của cả nước hoặc tỉnh/thành phố"
                />
                <DataLine
                  title="Tỷ lệ nhiễm"
                  data={
                    data[percentKey].toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + "%"
                  }
                  color={color}
                  popupText="Tỷ lệ nhiễm theo phần trăm của cả nước hoặc tỉnh/thành phố"
                />
                <DataLine
                  title="Ca/100,000 dân"
                  data={
                    (data[percentNumKey]?.length === 1 ? "0" : "") +
                    data[percentNumKey]?.toLocaleString("vi-VN")
                  }
                  color={color}
                  popupText="Số ca nhiễm trên 100,000 dân của cả nước hoặc tỉnh/thành phố"
                />
              </>
            )}

            {idx === 1 && (
              <>
                <DataLine
                  title="Tỷ lệ tử vong/ca nhiễm"
                  data={
                    (
                      (data[variableKey] / data.total_case) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + "%"
                  }
                  color={color}
                  popupText="Tỷ lệ tử vong theo phần trăm ca nhiễm của cả nước hoặc tỉnh/thành phố"
                />
                <DataLine
                  title="Ca/100,000 dân"
                  data={
                    (data[percentNumKey]?.length === 1 ? "0" : "") +
                    data[percentNumKey]?.toLocaleString("vi-VN")
                  }
                  color={color}
                  popupText="Số ca tử vong trên 100,000 dân của cả nước hoặc tỉnh/thành phố"
                />
              </>
            )}

            {idx === 2 && (
              <>
                <DataLine
                  title="Ca hồi phục/ngày"
                  data={sevenDays}
                  color={color}
                  popupText="Số ca hồi phục trung bình/ngày trong 7 ngày qua của cả nước hoặc tỉnh/thành phố"
                />
                <DataLine
                  title="Tỷ lệ hồi phục/ca nhiễm"
                  data={
                    (
                      (data.total_recovered / data.total_case) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + "%"
                  }
                  color={color}
                  popupText="Tỷ lệ tử vong theo phần trăm ca nhiễm của cả nước hoặc tỉnh/thành phố"
                />
                <div className="h-6"></div>
              </>
            )}

            {idx === 3 && (
              <>
                <DataLine
                  title="Tỷ lệ đang điều trị/ca nhiễm"
                  data={
                    (
                      (data.total_active / data.total_case) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + "%"
                  }
                  color={color}
                  popupText="Tỷ lệ tử vong theo phần trăm ca nhiễm của cả nước hoặc tỉnh/thành phố"
                />
                <DataLine
                  title="Ca/100,000 dân"
                  data={
                    (data[percentNumKey]?.length === 1 ? "0" : "") +
                    data[percentNumKey]?.toLocaleString("vi-VN")
                  }
                  color={color}
                  popupText="Số ca đang điều trị trên 100,000 dân của cả nước hoặc tỉnh/thành phố"
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex xl:flex-row flex-wrap  xl:flex-nowrap justify-between w-full lg:px-4 px-1">
      {data && (
        <>
          {selectedProvince.id
            ? cardLabelsProvince.map((item, index) => {
                return (
                  <Card
                    title={item.title}
                    variableKey={item.variableKey}
                    arrayKey={item.arrayKey}
                    compareKey={item.compareKey}
                    averageKey={item.averageKey}
                    percentKey={item.percentKey}
                    percentNumKey={item.percentNumKey}
                    color={item.color}
                    colorCode={item.colorCode}
                    idx={index}
                  />
                );
              })
            : cardLabels.map((item, index) => {
                return (
                  <Card
                    title={item.title}
                    variableKey={item.variableKey}
                    arrayKey={item.arrayKey}
                    compareKey={item.compareKey}
                    averageKey={item.averageKey}
                    percentKey={item.percentKey}
                    percentNumKey={item.percentNumKey}
                    color={item.color}
                    colorCode={item.colorCode}
                    idx={index}
                  />
                );
              })}
        </>
      )}
    </div>
  );
};

export default TopCards;
