import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import OverView from "../HomePage/OverView";
import Trend from "../HomePage/Trend";
import Vaccine from "../HomePage/Vaccine";
import TopCountry from "../HomePage/TopCountry";
import countrySelected from "../../recoil/atom-country";
import SearchBox from "../commons/SearchBox";
import LoadingMenuLeft from "../Loading/loading-menu-left";
import {
  isMobile,
  isTablet,
} from "react-device-detect";
import { useLocation } from "react-router-dom";
import totalState from "../../recoil/atom-total";

const MenuLeft = () => {
  const location = useLocation();
  const [total] = useRecoilState(totalState);
  const [currentCountry, setCurrentCountry] = useRecoilState(countrySelected);
  const [updatedAt, setUpdatedAt] = useState();

  useEffect(() => {
    if (total.last_updated) {
      const date  = new Date(total.last_updated);
      let dateTimeSplit = total.last_updated.split(" ");
      let completedDateTime = " ngày " + dateTimeSplit[0] ;
      setUpdatedAt(completedDateTime);
    }
  }, [total])

  return (
    <div
      className={`menu-left pb-10 ${
        location.pathname !== "/dulieu" && isMobile && !isTablet && "hidden"
      }`}
    >
      <div className="flex flex-col absolute w-full lg:w-2/5 xl:w-1/4">
        <SearchBox />
      </div>
      <React.Suspense fallback={<LoadingMenuLeft />}>
        <div className="px-3 md:mt-14 mt-14 transition-all duration-1000 ease-in-out">
          <div className="flex justify-between w-full items-center">
            <div>
              {currentCountry.id ? (
                <div className="flex items-center justify-between">
                  <span className="text-xl font-bold">
                    {currentCountry.name}
                  </span>
                </div>
              ) : (
                <span className="text-xl font-bold">Toàn quốc</span>
              )}
              <div className="text-xs text-gray-500">
                <span>
                  <span><b>Dữ liệu {updatedAt ? updatedAt : ""}</b></span> <br/> 
                  <span> Nguồn: Tổng hợp từ Bộ Y Tế </span>                 
                </span>
                {/* <span>Cập nhật 1 phút trước</span> */}
              </div>
            </div>
            {currentCountry.id && (
              <div
                className="bg-blue-500 truncate hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl cursor-pointer"
                onClick={() => setCurrentCountry({})}
              >
                Toàn quốc
              </div>
            )}
          </div>
          <div className="border-b my-2 px-3"></div>
          <Vaccine />
          <div className="w-100 border-b mb-3"></div>
          <OverView />
          <div>
            <Trend />
          </div>
          <TopCountry />
        </div>
      </React.Suspense>
    </div>
  );
};

export default MenuLeft;
