import _ from "lodash";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useRecoilState, useRecoilValue } from "recoil";
import { overViewVaccineDay } from "../../recoil/atom-overviewvaccine";

import ButtonSelectInput from "./../commons/ButtonSelectInput";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import countrySelected from "../../recoil/atom-country";
import PopupTime from "../commons/PopupTime";
import { sliceChart, validateSlice } from "../../services/chartRange";

const dateOptions = [
  {
    value: 7,
    label: "7 ngày",
  },
  {
    value: 30,
    label: "30 ngày",
  },
  {
    value: 60,
    label: "60 ngày",
  },
  {
    value: 0,
    label: "Mọi lúc",
  },
];


const ChartVaccineByDay = () => {
  const selectedProvince = useRecoilValue(countrySelected);
  const [selectedDate, setSelectedDate] = useState({ active: 60, start: 60, end: 0 });
  const [dataVaccineDay] = useRecoilState(overViewVaccineDay);
  // const selectedProvince = useRecoilValue(countrySelected);
  // const provinceVaccine = useRecoilValue(currentProvinceVaccine({ province: selectedProvince.id }));

  // const [data, setData] = useState();

  // useEffect(() => {
  //   console.log(provinceVaccine, dataVaccineDay);
  //   selectedProvince.id ? setData(provinceVaccine) : setData(dataVaccineDay);
  // }, [selectedProvince, dataVaccineDay, provinceVaccine])

  const options = {
    locale: 'vi-VN',
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 8,
        },
        min: 0,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        // barThickness: 100,
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      datalabels: {
        align: selectedDate !== 7 ? "left" : "top",
        anchor: "end",
        textStrokeColor: "rgba(255,255,255,1)",
        textStrokeWidth: 2,
        padding: selectedDate !== 7 ? 0 : -10,
        display: function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return index === data.length - 1;
        },
        formatter: function (value) {
          return value.y?.toLocaleString('vi-VN') + "\n";
        },
      },

      legend: {
        // display: noLegend ? false : true,
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            size: 10,
          },
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const getMeanLine = (cbdObj, label) => {
    const _cbdObj = [...cbdObj];

    let dataset = [];
    let averageData = [];

    for (let i = 0; i < cbdObj.length; i++) {
      let average = 0;

      if (i >= 6) {
        let week;
        if (selectedProvince.id)
          week = _cbdObj.slice(i - 6, i + 1);
        else
          week = _cbdObj.slice(i - 6, i + 1).map((item) => item.onceInjected + item.twiceInjected);
        average = Math.floor(week.reduce((a, b) => a + b) / 7);
      }

      averageData.push(average);
    }

    averageData = sliceChart(averageData, selectedDate);


    label.forEach((item, index) => {
      dataset.push({ x: item, y: averageData[index] });
    });

    return dataset;
  };

  const vacineByDay = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "#D8EEDF");
    gradient1.addColorStop(1, "#D8EEDF");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient2.addColorStop(0, "#fafafa");
    gradient2.addColorStop(1, "#fafafa");

    let vaccineByDayObject = Object.values(dataVaccineDay);

    if (vaccineByDayObject[0] && !validateSlice(vaccineByDayObject, selectedDate)) {
      setSelectedDate({ active: 60, start: 60, end: 0 });
    }

    const sliced = sliceChart(vaccineByDayObject, selectedDate);

    let unsplitted = (dataVaccineDay && Object.keys(dataVaccineDay)) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _label = unsplitted.map((day) => {
      let splitted = day.split("/");
      return parseInt(splitted[0]) + "/" + parseInt(splitted[1])
    })

    const meanLine = getMeanLine(vaccineByDayObject, _label);

    let onceInjected = Object.values(sliced).map((item, index) => {
      return { x: _label[index], y: item.onceInjected };
    });

    let twiceInjected = Object.values(sliced).map((item, index) => {
      return { x: _label[index], y: item.twiceInjected };
    });

    return {
      labels: _label,
      datasets: [
        {
          type: "line",
          label: " Trung bình 7 ngày",
          backgroundColor: "black",
          fill: false,
          borderColor: "black",
          pointColor: "#fff",
          pointRadius: 0,
          borderWidth: 1,
          pointColor: "black",
          pointStrokeColor: "black",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "black",
          data: meanLine,
        },
        {
          type: "bar",
          label: "Đủ liều",
          backgroundColor: "#01662A",
          pointColor: "#01662A",
          pointRadius: 1,
          fill: "start",
          backgroundColor: "#01662A",
          pointStrokeColor: "#01662A",
          pointHighlightFill: "#01662A",
          pointHighlightStroke: "#01662A",
          data: twiceInjected,
        },
        {
          type: "bar",
          label: "Chưa đủ liều",
          backgroundColor: "#D8EEDF",
          pointColor: "#fff",
          pointRadius: 1,
          fill: "start",
          backgroundColor: "#D8EEDF",
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          data: onceInjected,
        },
      ],
    };
  };

  return (
    <div className="flex flex-col justify-center">
      {selectedProvince.id ?
        <div className=" mb-3 pl-5 my-auto text-red-500 text-sm font-medium items-center"><i>Dữ liệu hiện đang được cập nhật.</i></div>
        :
        <>
          {dataVaccineDay && Object.values(dataVaccineDay)[0] && (
            <>
              <div className="mb-3 flex flex-row justify-start items-center my-2">
                <div className="w-full flex flex-row justify-start">
                  <div className="flex">
                    <ButtonSelectInput
                      title=""
                      options={dateOptions}
                      value={selectedDate.active}
                      onChange={(value) => {
                        setSelectedDate({ active: value, start: value, end: 0 });
                      }}
                    />
                  </div>
                  <PopupTime
                    active={selectedDate.active === -1}
                    onSave={(range) => {
                      setSelectedDate({ active: -1, ...range })
                    }}
                  />
                </div>
              </div>
              <Bar
                data={vacineByDay}
                plugins={[ChartDataLabels]}
                width="10"
                height="6vh"
                options={options}
              />
            </>
          )}
        </>
      }
    </div>
  );
};

export default React.memo(ChartVaccineByDay);
