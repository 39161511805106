import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import totalState from '../../recoil/atom-total';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
import { sliceChart, validateSlice } from '../../services/chartRange';
import ButtonSelectInput from '../commons/ButtonSelectInput';

const ChartProjection = () => {
  const total = useRecoilValue(totalState);
  const [processedData, setProcessedData] = useState();
  const [selectedDate, setSelectedDate] = useState({ active: 0, start: 0, end: 0 });

  const [selectedTab, setSelectedTab] = useState(0);

  const tabOptions = [
    {
      value: 0,
      label: "Miễn dịch"
    },
    {
      value: 1,
      label: "Vaccine"
    }
  ]

  const [labels, setLabels] = useState();

  const isoDate = (day) => {
    let split = day.split("/");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  useEffect(() => {
    if (total.prediction) {
      let actual = [...total.prediction.actual_by_time];
      let projectedFull = [...total.prediction.projected_by_time];

      // remove difference between actual and projected
      let actualFirstDay = dayjs(isoDate(actual[0].day))
      let projectedFirstDay = dayjs(isoDate(projectedFull[0].day));

      let diff = actualFirstDay.diff(projectedFirstDay, "day");
      let projected = projectedFull.slice(diff);

      setLabels(projected.map((item) => item.day));

      setProcessedData({
        actual: actual,
        projected: projected,
      })
    }
  }, [total])

  useEffect(() => {
    if (!processedData || !validateSlice(processedData.actual, selectedDate)) {
      setSelectedDate({ active: 0, start: 0, end: 0 });
    }
  }, [selectedDate])


  const options = {
    locale: 'vi-VN',
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 20,
        },
        min: 0,
        max: 100,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {

        ticks: {
          beginAtZero: true,
          maxTicksLimit: 30,
        },
        max: function (context) {
          let split = labels[labels.length - 1].split("/");
          return parseInt(split[0]) + "/" + parseInt(split[1]) + "/" + parseInt(split[2]);
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: function (context) {
            let index = context[0].dataIndex;
            return labels[index];
          },
          label: function (context) {
            return context.dataset.label + ": " + Math.floor(context.parsed.y) + "%";
          }
        }
      },
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        align: 'left',
        anchor: 'end',
        padding: 2,
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
        display: function (context) {
          let index = context.dataIndex;
          let data = context.dataset.data;
          return index === data.length - 1;
        },
        formatter: function (value) {
          return value.toLocaleString('vi-VN') + "%" + "\n";
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const dataSetVaccine = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "rgb(255,106,7)");
    gradient1.addColorStop(1, "rgb(252,242,233)");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient2.addColorStop(0, "#6B7280");
    gradient2.addColorStop(1, "#E5E7EB");

    const actual = sliceChart(processedData.actual, selectedDate);
    const projected = sliceChart(processedData.projected, selectedDate);

    let unsplitted = projected.map((item) => item.day) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _caseLabel = unsplitted.map((day) => {
      let split = day.split("/");
      return parseInt(split[0]) + "/" + parseInt(split[1]);
    })

    return {
      labels: _caseLabel,
      datasets: [
        {
          label: "Phần trăm dân số được tiêm vaccine thực tế",
          backgroundColor: "#3B82F6",
          borderColor: "#3B82F6",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          data: actual.map((item) => (item.per_vacc_only) * 100),
        },
        {
          label: "Phần trăm dân số được tiêm vaccine dự đoán",
          backgroundColor: "#93C5FD",
          borderColor: "#93C5FD",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          borderDash: [4, 3],
          data: projected.map((item) => (item.per_vacc_only) * 100),
        },
        {
          label: "Phần trăm dân số nhiễm bệnh thực tế",
          backgroundColor: "#F97316",
          borderColor: "#F97316",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          data: actual.map((item) => (item.per_past_inf_only + item.per_both_vacc_and_past_inf) * 100),
        },
        {
          label: "Phần trăm dân số nhiễm bệnh dự đoán",
          backgroundColor: "#FDBA74",
          borderColor: "#FDBA74",
          borderWidth: 1,
          pointColor: "#fff",
          pointRadius: 0,
          borderDash: [4, 3],
          data: projected.map((item) => (item.per_past_inf_only + item.per_both_vacc_and_past_inf) * 100),
        },
      ],
    };
  };

  const dataSetImmunity = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient1.addColorStop(0, "rgb(255,106,7)");
    gradient1.addColorStop(1, "rgb(252,242,233)");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
    gradient2.addColorStop(0, "#6B7280");
    gradient2.addColorStop(1, "#E5E7EB");

    const actual = sliceChart(processedData.actual, selectedDate);
    const projected = sliceChart(processedData.projected, selectedDate);

    let unsplitted = projected.map((item) => item.day) || [];
    unsplitted = sliceChart(unsplitted, selectedDate);

    let _caseLabel = unsplitted.map((day) => {
      let split = day.split("/");
      return parseInt(split[0]) + "/" + parseInt(split[1]);
    })

    let latestImmune = actual[actual.length - 1].total_immu;
    let actualColor;

    if (latestImmune <= 0.25)
      actualColor = "#EF4444";
    else if (latestImmune > 0.25 && latestImmune <= 0.50)
      actualColor = "#F59E0B";
    else if (latestImmune > 0.50 && latestImmune <= 0.75)
      actualColor = "#84CC16";
    else if (latestImmune > 0.75)
      actualColor = "#22C55E";
    else
      actualColor = "#84CC16";

  return {
    labels: _caseLabel,
    datasets: [
      {
        label: "Tỉ lệ dân số miễn dịch thực tế",
        backgroundColor: actualColor,
        borderColor: actualColor,
        borderWidth: 1,
        pointColor: "#fff",
        pointRadius: 0,
        data: actual.map((item) => (item.total_immu) * 100),
      },
      {
        label: "Tỉ lệ dân số miễn dịch dự đoán",
        backgroundColor: "#93C5FD",
        borderColor: "#93C5FD",
        borderWidth: 1,
        pointColor: "#fff",
        pointRadius: 0,
        borderDash: [4, 3],
        data: projected.map((item) => (item.total_immu) * 100),
      },
    ],
  };
};

return (
  <div className="flex flex-col justify-center">
    {processedData && (
      <>
        <ButtonSelectInput
          options={tabOptions}
          value={selectedTab}
          onChange={(value) => {
            setSelectedTab(value);
          }}
        />
        {selectedTab === 0
          ? <Line data={dataSetImmunity} width={400} height={150} plugins={[ChartDataLabels]} options={options} />
          : <Line data={dataSetVaccine} width={400} height={150} plugins={[ChartDataLabels]} options={options} />
        }
      </>
    )}
    <small class="pl-2 text-sm text-gray-500  mt-1">Mô hình dự đoán tham khảo: <a target="_blank" href="https://covid19-projections.com/path-to-herd-immunity/"><i>Path to Normality - COVID-19 Vaccine Projections</i></a> </small>
  </div>
);
};

export default ChartProjection;