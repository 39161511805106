import { selectorFamily } from "recoil";
import api from "../services/api";

const currentProvinceState = selectorFamily({
  key: "CurrentProvinceState",
  get:
    ({ province }) =>
    async ({ get }) => {
      if (province) {
        const responseDetail = await api.get(`/covid/province/${province}`);
        if (responseDetail.error) {
          throw responseDetail.error;
        }

        return {...responseDetail.data};
      }
      return {};
    },
});

// export const currentProvinceByDay = selectorFamily({
//   key: 'currentProvinceByDay',
//   get:
//   ({ province }) =>
//   async ({ get }) => {
//     if (province) {
//       const response = await api.get(`chart/case_by_day_province?province=${province}`);
//       if (response.error) {
//         throw response.error;
//       }
//       return response.data;
//     }
//     return {};
//   },
// })

export default currentProvinceState;
