import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ChartContainer from "../graph/";
import HomePage from "../HomePage";
import VaccinationIndex from "../vaccination";
import CovidNews from "../news";
import MapActiveCase from "../map/VNLeafletMap";
import Draft from "../commons/draft";
import Quarantine from "../quarantine";
import Article from "../article";
import LoadingChartScreen from "../Loading/loading-chart-screen";
import Bluezone from "../bluezone";

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="dulieu" />
      </Route>
      <Route path="/dulieu" exact>
        <HomePage />
      </Route>
      <Route path="/dulieu/map">
        <MapActiveCase />
      </Route>
      <Route path="/dulieu/index">
        <Draft />
      </Route>
      <Route path="/dulieu/graph">
        <ChartContainer />
      </Route>
      <Route path="/dulieu/vaccine">
        <VaccinationIndex />
      </Route>
      <Route path="/dulieu/distancing">
        <React.Suspense fallback={<LoadingChartScreen />}>
          <Quarantine />
        </React.Suspense>
      </Route>
      <Route path="/dulieu/pc-covid">
        <React.Suspense fallback={<LoadingChartScreen />}>
          <Bluezone />
        </React.Suspense>
      </Route>
      <Route path="/dulieu/news">
        <React.Suspense fallback={<LoadingChartScreen />}>
          <CovidNews />
        </React.Suspense>
      </Route>
      <Route path="/article">
        <Article />
      </Route>
    </Switch>
  );
};

export default AppRouter;
