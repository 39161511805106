import React from "react";

const WrapChart = ({
  title = "",
  customStyle = {},
  className = "",
  children,
  popup,
  expaned = <div></div>,
}) => {
  return (
    <div
      className={
        "lg:p-5 p-2 w-full bg-white lg:rounded-2xl shadow-lg m-0 " + className
      }
      style={customStyle}
    >
      {title && (
        <div className="flex justify-between mb-3">
          <div className="flex flex-row items-center">
            <div className="text-lg font-medium text-left mr-1">{title}</div>
            {popup}
          </div>
          <div className="">{expaned}</div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default WrapChart;
