const InfoPopup = ({ children }) => {
  return (
    <div className="relative">
      <div className="dataline-info">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
        </svg>
      </div>
      <div className="w-60 py-2 px-3 rounded dataline-popup border border-gray-200 absolute shadow-md bg-white top-5 left-0 z-50">
        {children}
      </div>
    </div>
  )
}

export default InfoPopup;