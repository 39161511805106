import { selectorFamily } from "recoil";
import api from "../services/api";

const articleState = selectorFamily({
  key: "ArticleState",
  get:
    ({ province = 1 }) =>
    async ({ get }) => {
      if (province) {
        const responseDetail = await api.post("https://crawler.vnalert.vn/v1/covid-dashboard/articles/", {province: parseInt(province), number: 60});
        if (responseDetail.error) {
          throw responseDetail.error;
        }

        return responseDetail.data.data;
      }
      return {};
    },
});

export default articleState;