import React, { useEffect, useState } from "react";
import _ from "lodash";
import ratioData from "../../recoil/atom-ratio";
import { useRecoilState } from "recoil";
import {
  overViewTotalVaccine,
} from "../../recoil/atom-overviewvaccine";

const ChartMapTiemChung = ({ selectedProvince }) => {
  const [totalVaccine] = useRecoilState(overViewTotalVaccine);
  const [dataRatio] = useRecoilState(ratioData);
  const [data, setData] = useState([]);
  const [dataTotal, setTotal] = useState([
    {
      totalPopulation: 0,
      totalInjected: 0,
      totalOnceInjected: 0,
      totalTwiceInjected: 0,
      totalVaccineAllocatedReality: 0,
      popOverEighteen: 0,
    },
  ]);
  useEffect(() => {
    if (!selectedProvince) {
      setTotal({
        totalPopOverEighteen: _.sumBy(dataRatio, "popOverEighteen"),
        totalPopulation: _.sumBy(dataRatio, "population"),
        totalInjected: _.sumBy(dataRatio, "totalInjected"),
        totalOnceInjected: _.sumBy(dataRatio, "totalOnceInjected"),
        totalTwiceInjected: _.sumBy(dataRatio, "totalTwiceInjected"),
        totalVaccineAllocatedReality: _.sumBy(
          dataRatio,
          "totalVaccineAllocatedReality"
        ),
      });
    } else {
      setData(dataRatio.find((item) => item.id === parseInt(selectedProvince)));
    }
  }, [selectedProvince, dataRatio]);
  return (
    <div className="flex xl:flex-row flex-wrap  xl:flex-nowrap justify-between w-full lg:px-4 px-1">
      <div className="w-1/2 px-1 mb-3 ">
        <div
          className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-red-500 text-black h-full`}
        >
          <div className="text-sm font-medium">Tổng người đã tiêm </div>

          {data && dataRatio ? (
            <>
              <div className="text-2xl font-bold text-red-500 pb-1">
                {!selectedProvince
                  ? dataRatio &&
                    parseInt(totalVaccine?.total_injected).toLocaleString(
                      "vi-VN"
                    )
                  : data &&
                    parseInt(data.totalInjected).toLocaleString("vi-VN")}
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl font-bold text-red-500 pb-1">
                Đang tải..
              </div>
            </>
          )}
          {data ? (
            <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
              {!selectedProvince ? (
                <>
                  {(
                    (parseInt(totalVaccine.total_injected) /
                      parseInt(totalVaccine?.national_population)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </>
              ) : (
                <>
                  {(
                    (parseInt(data.totalInjected) /
                      parseInt(data.popOverEighteen)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </>
              )}
              % dân số {">"}= 18 tuổi
            </div>
          ) : (
            <div className="bg-white text-black rounded-full text-xs font-medium px-2 py-1">
              Đang tải..
            </div>
          )}
        </div>
      </div>
      <div className="w-1/2 px-1 mb-3 ">
        <div
          className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-gray-500 text-black h-full`}
        >
          <div className="text-sm font-medium">Tiêm 1 mũi </div>

          {data && dataRatio ? (
            <>
              <div className="text-2xl font-bold text-gray-500 pb-1">
                {!selectedProvince
                  ? dataRatio &&
                    parseInt(totalVaccine?.total_first_injected).toLocaleString(
                      "vi-VN"
                    )
                  : data &&
                    parseInt(data.totalOnceInjected).toLocaleString("vi-VN")}
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl font-bold text-gray-500 pb-1">
                Đang tải..
              </div>
            </>
          )}
          {data ? (
            <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
              {!selectedProvince
                ? (
                    (parseInt(totalVaccine?.total_first_injected) /
                      parseInt(totalVaccine?.national_population)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : (
                    (parseInt(data.totalOnceInjected) /
                      parseInt(data.popOverEighteen)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              % dân số {">"}= 18 tuổi
            </div>
          ) : (
            <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
              Đang tải..
            </div>
          )}
        </div>
      </div>
      <div className="w-1/2 px-1 mb-3 ">
        <div
          className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-green-500 text-black h-full`}
        >
          <div className="text-sm font-medium">Tiêm 2 mũi </div>

          {data && dataRatio ? (
            <>
              <div className="text-2xl font-bold text-green-500 pb-1">
                {!selectedProvince
                  ? dataRatio &&
                    parseInt(
                      totalVaccine?.total_secord_injected
                    ).toLocaleString("vi-VN")
                  : data &&
                    parseInt(data.totalTwiceInjected).toLocaleString("vi-VN")}
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl font-bold text-green-500 pb-1">
                Đang tải..
              </div>
            </>
          )}
          {/* {((100 / data.totalPopulation) * dataTotal.totalTwiceInjected)
                .toFixed(2) */}

          {data ? (
            <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
              {!selectedProvince
                ? (
                    (parseInt(totalVaccine?.total_secord_injected) /
                      parseInt(totalVaccine?.national_population)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : (
                    (parseInt(data.totalTwiceInjected) /
                      parseInt(data.popOverEighteen)) *
                    100
                  ).toLocaleString("vi-VN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              % dân số {">"}= 18 tuổi
            </div>
          ) : (
            <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
              Đang tải..
            </div>
          )}
        </div>
      </div>
      {!selectedProvince && (
        <div className="w-1/2 px-1 mb-3 ">
          <div
            className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-yellow-500 text-black h-full`}
          >
            <div className="text-sm font-medium">Số liều Vaccine đã về</div>

            {data && dataRatio ? (
              <>
                <div className="text-2xl font-bold text-yellow-500 pb-1">
                  {!selectedProvince
                    ? data &&
                      parseInt(totalVaccine?.total_vaccine).toLocaleString(
                        "vi-VN"
                      )
                    : data &&
                      parseInt(totalVaccine?.total_vaccine).toLocaleString(
                        "vi-VN"
                      )}
                </div>
              </>
            ) : (
              <>
                <div className="text-2xl font-bold text-yellow-500 pb-1">
                  Đang tải..
                </div>
              </>
            )}

            {data ? (
              <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
                {!selectedProvince
                  ? (
                      (parseInt(totalVaccine?.total_vaccine) / 150000000) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (
                      (parseInt(data.totalVaccineAllocatedReality) /
                        data.popOverEighteen) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                % mục tiêu
              </div>
            ) : (
              <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
                Đang tải..
              </div>
            )}
          </div>
        </div>
      )}

      {selectedProvince && (
        <div className="w-1/2 px-1 mb-3 ">
          <div
            className={`flex flex-col justify-start items-start bg-white p-3 w-full rounded-lg shadow-lg border-t-8 border-yellow-500 text-black h-full`}
          >
            <div className="text-sm font-medium">Số liều Vaccine đã về </div>

            {data && dataRatio ? (
              <>
                <div className="text-2xl font-bold text-yellow-500 pb-1">
                  {!selectedProvince
                    ? data &&
                      parseInt(
                        dataTotal?.totalVaccineAllocatedReality
                      ).toLocaleString("vi-VN")
                    : data &&
                      parseInt(
                        data.totalVaccineAllocatedReality
                      ).toLocaleString("vi-VN")}
                </div>
              </>
            ) : (
              <>
                <div className="text-2xl font-bold text-yellow-500 pb-1">
                  Đang tải..
                </div>
              </>
            )}

            {data ? (
              <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
                {!selectedProvince
                  ? (
                      (parseInt(totalVaccine?.total_vaccine) / 150000000) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (
                      (parseInt(data.totalVaccineAllocatedReality) /
                        data.popOverEighteen) *
                      100
                    ).toLocaleString("vi-VN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                % mục tiêu
              </div>
            ) : (
              <div className="bg-gray-100 text-black rounded-full text-xs font-medium px-2 py-1">
                Đang tải..
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartMapTiemChung;
